export enum DialogResultActions {
    Create,
    Edit,
    Delete

}

export class DialogResult<T> {

    constructor(action: DialogResultActions, entity: T) {
        this.action = action;
        this.entity = entity;
    }

    action: DialogResultActions;
    entity: T;
}