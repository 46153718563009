import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PastAppointmentDto } from 'src/app/services/api.services';
import { PastAppointmentsDisplayDialogComponent } from './past-appointments-display-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class PastAppointmentsDisplayDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(pastAppointments: PastAppointmentDto[]) {
        const dialogRef = this.dialogRef.open(PastAppointmentsDisplayDialogComponent, {
            width: '800px',
            data: pastAppointments,
        });

        return dialogRef.afterClosed();
    }
}
