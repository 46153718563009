import { Component, OnInit } from '@angular/core';
import { ImportClientLeadsFromOrderReferencesCommand, ImportOrderType, ClientLeadsService } from 'src/app/services/api.services';
import * as moment from 'moment';
import { EnumListService } from 'src/app/services/enum-list.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-import-references-dialog',
    templateUrl: './import-references-dialog.component.html',
    styleUrls: ['./import-references-dialog.component.scss'],
})
export class ImportReferencesDialogComponent implements OnInit {
    importCommand = new ImportClientLeadsFromOrderReferencesCommand();
    dateRange: moment.Moment[];
    importOrderTypes = this.enumListService.orderImportType;
    loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ImportReferencesDialogComponent>,
        private enumListService: EnumListService,
        private clientLeadsService: ClientLeadsService,
        private notifyService: NotifyService
    ) {
        this.importCommand.importedOrderType = ImportOrderType.New;
    }

    ngOnInit() {}

    importReferences() {
        this.loading = true;

        if (!this.dateRange || this.dateRange.length <= 1) {
            this.notifyService.fail('CLIENTLEAD.IMPORTDIALOG.SELECTDATES', true);
            this.loading = false;
            return;
        }

        if (!this.importCommand.importedOrderType) {
            this.notifyService.fail('CLIENTLEAD.IMPORTDIALOG.SELECTIMPORTTYPE', true);
            this.loading = false;
            return;
        }

        this.importCommand.fromDate = this.dateRange[0];
        this.importCommand.toDate = this.dateRange[1];

        this.clientLeadsService.importClientLeadsFromOrderReferences(this.importCommand).subscribe(
            (result) => {
                this.loading = false;
                this.notifyService.success('OPERATIONSUCCEEDED', true);
                return this.dialogRef.close(true);
            },
            (err) => {
                this.loading = false;
                this.notifyService.fail('OPERATIONFAILED', true);
            }
        );
    }
}
