import { Observable, Subject } from 'rxjs';
import { DisplayColumn } from './displayColumn';
import { PagingDataSource } from 'src/app/models/interfaces/dataSourceInterface';

export class SearchDialogResult<T> {
    isSelectedClicked: boolean;
    isAddClicked: boolean;
    isEditClicked: boolean;
    isCancelled: boolean;

    isCustomActionClicked: boolean;
    customActionClicked: string;

    selectedEntities: T[] = [];
    entityToEdit: T;

    get selectedEntity(): T {
        return this.selectedEntities[0];
    }
}

export class LocalSearchTableContainerDialog<EntityModel> {
    showActionColumns = true;
    title = '';
    dialogWidth: string = '800px';

    selectIcon = 'add';
    editIcon = 'edit';
    showSelectionButton: boolean = true; // this is the + icon button, by default it is shown
    showAddButton: boolean;
    showEditButton: boolean;
    showDoneButton: boolean = true;
    filterPredicate: (data, filterValue: string) => boolean;

    constructor(
        public entities: Observable<any>,
        public entityName: string,
        public displayProperties: DisplayColumn[],
        public excludeList: Array<any> = [],
        public closeOnSelect: boolean = false
    ) {}

    entitySelectedObservable: Subject<SearchDialogResult<EntityModel>> = new Subject<SearchDialogResult<EntityModel>>();
}

export class ServerSearchTableContainerDialog<EntityModel, QueryType> {
    dialogWidth: string = '800px';

    selectIcon = 'add';
    editIcon = 'edit';

    persistedPagingKey: string;
    showSelectionButton: boolean = true; // this is the + icon button, by default it is shown
    showAddButton: boolean;
    showEditButton: boolean;

    entitySelectedObservable: Subject<SearchDialogResult<EntityModel>> = new Subject<SearchDialogResult<EntityModel>>();

    selectHasOtherFunction: boolean = false;

    constructor(
        public entityName: string,
        public query: QueryType,
        public server: PagingDataSource<EntityModel, QueryType>,
        public closeOnSelect: boolean,
        public displayProperties: DisplayColumn[],
        public excludeList: Array<any>
    ) {}
}

export class OrderItemsServerSearchTableContainerDialog<EntityModel, QueryType> {
    constructor(
        public entityName: string,
        public query: QueryType,
        public server: PagingDataSource<EntityModel, QueryType>,
        public closeOnSelect: boolean,
        public displayProperties: DisplayColumn[],
        public entitySelectedObservables: Subject<any>,
        public excludeList: Array<any>,
        public isPickup: boolean
    ) {}
}
