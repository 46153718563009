import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../models/myErrorStateMatcher';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit {

  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() group: FormGroup;

  matcher = new MyErrorStateMatcher();

  constructor() { }


  ngOnInit() {
  }

}
