import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PastAppointmentDto } from 'src/app/services/api.services';
import { CommonComponent } from 'src/app/models/formComponent';

@Component({
    selector: 'app-past-appointments-display-dialog',
    templateUrl: './past-appointments-display-dialog.component.html',
    styleUrls: ['./past-appointments-display-dialog.component.scss'],
})
export class PastAppointmentsDisplayDialogComponent extends CommonComponent implements OnInit {
    displayedColumns: string[] = ['orderNo', 'orderDate', 'repName', 'directorName', 'tmName'];

    constructor(@Inject(MAT_DIALOG_DATA) public data: PastAppointmentDto[], private dialogRef: MatDialogRef<PastAppointmentsDisplayDialogComponent>) {
        super();
    }

    ngOnInit() {}
}
