import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../models/myErrorStateMatcher';

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements OnInit {
    @Input() phonePlaceholder: string;
    @Input() phoneControlName: string;
    @Input() extControlName: string;
    @Input() group: FormGroup;

    matcher = new MyErrorStateMatcher();
    mask = '000-000-0000';

    constructor() { }

    ngOnInit() { }

    isFieldValid(field: string) {
        return !this.group.get(field).valid && this.group.get(field).touched;
    }
}
