import { Component, OnInit, Input, NgZone } from '@angular/core';
import { AddressDto } from 'src/app/services/api.services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/models/myErrorStateMatcher';
import { CountriesService } from 'src/app/services/countries.service';

@Component({
    selector: 'app-address-dto-form',
    templateUrl: './address-dto-form.component.html',
    styleUrls: ['./address-dto-form.component.scss'],
})
export class AddressDtoFormComponent implements OnInit {
    matcher = new MyErrorStateMatcher();
    @Input() parentGroup: FormGroup;
    @Input() address: AddressDto;
    @Input() disableForm: boolean;

    group: FormGroup;

    countries: string[];

    constructor(private formBuilder: FormBuilder, private countriesService: CountriesService, public zone: NgZone) {
        this.countries = countriesService.Countries;
    }

    ngOnInit() {
        if (this.address === null) {
            this.address = new AddressDto();
            this.address.province = 'Quebec';
            this.address.country = 'Canada';
        }

        this.group = this.formBuilder.group({
            id: [this.address.id],
            street: [this.address.street ? this.address.street.toUpperCase() : null, [Validators.required]],
            city: [this.address.city ? this.address.city.toUpperCase() : null, [Validators.required]],
            postalCode: [
                this.address.postalCode ? this.address.postalCode.toUpperCase() : null,
                [Validators.required, Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)],
            ],
            province: [this.address.province ? this.address.province.toUpperCase() : null, [Validators.required]],
            country: [this.address.country ? this.countries.find((c) => c == this.address.country) : null, [Validators.required]],
        });

        this.parentGroup.addControl('address', this.group);

        if (this.disableForm) this.group.disable();
    }
    toUpperCase(conformedValue: string): string {
        return conformedValue.toUpperCase();
    }

    isFieldValid(field: string) {
        return !this.group.get(field).valid && this.group.get(field).touched;
    }

    setAddress(address: AddressDto) {
        this.zone.run(() => {
            this.group.get('street').setValue(address.street);
            this.group.get('city').setValue(address.city);
            this.group.get('postalCode').setValue(address.postalCode);
            this.group.get('province').setValue(address.province);
            this.group.get('country').setValue(address.country);
        });
    }
}
