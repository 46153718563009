import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AddressDtoFormComponent } from './address-dto-form.component';
import { GooglePlacesModule } from '../directives/google-places/google-places.module';
import { AlphaNumericModule } from '../directives/alphanumeric/alphanumeric.module';

@NgModule({
    declarations: [AddressDtoFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        GooglePlacesModule,
        AlphaNumericModule,
    ],
    exports: [AddressDtoFormComponent]
})
export class AddressDtoFormModule { }
