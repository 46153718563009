import { Injectable } from '@angular/core';
import { SalesRepDto, SalesRepsService, SalesDirectorDto } from './api.services';
import { Observable } from 'rxjs';
import { shareReplay, flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SalesRepManagerService {
    private salesReps: Observable<any>;
    private salesDirectors: Observable<any>;

    constructor(private salesRepsService: SalesRepsService) {}

    salesRepList(isDispatch: boolean): Observable<SalesRepDto[]> {
        if (!this.salesReps) {
            this.salesReps = this.loadSalesReps(isDispatch).pipe(shareReplay(1));
        }

        return this.salesReps;
    }

    private loadSalesReps(isDispatch: boolean): Observable<any> {
        return this.salesRepsService.salesReps(isDispatch);
    }

    salesDirectorList(): Observable<SalesDirectorDto[]> {
        if (!this.salesDirectors) {
            this.salesDirectors = this.loadSalesDirectors().pipe(shareReplay(1));
        }

        return this.salesDirectors;
    }

    private loadSalesDirectors(): Observable<any> {
        return this.salesRepsService.salesDirectors();
    }
}
