import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentDetailsComponent } from './appointment-details.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [AppointmentDetailsComponent],
    imports: [CommonModule, MaterialModule, PipesModule, TranslateModule.forChild()],
    exports: [AppointmentDetailsComponent],
})
export class AppointmentDetailsModule {}
