import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaginationModel } from 'src/app/services/api.services';
import { Subscription, fromEvent } from 'rxjs';
import { PagingPersistService } from 'src/app/services/paging-persistent.service';
import { DataSource } from '@angular/cdk/table';

@Component({
    selector: 'app-server-paging-table-container',
    templateUrl: './server-paging-table-container.component.html',
    styleUrls: ['./server-paging-table-container.component.scss'],
})
export class ServerPagingTableContainerComponent<EntityModel> implements OnInit, OnDestroy {
    @Input() customInputs: TemplateRef<any>;
    @Input() mobileList: TemplateRef<any>;
    @Output() reload = new EventEmitter<any>();

    _dataSource: DataSource<EntityModel>;
    get dataSource(): DataSource<EntityModel> {
        return this._dataSource;
    }

    @Input('dataSource')
    set dataSource(value: DataSource<EntityModel>) {
        this._dataSource = value;
    }

    @Input() entityName: string;
    @Input() entityDisplayName: string;
    @Input() isLoaded: boolean;
    @Input() isFailed: boolean;
    @Input() showPaginator: boolean = true;
    @Input() showSearchAndRefresh: boolean = true;
    @Input() length: number;
    @Input() wrapTable = true;
    @Input() defaultSortColumn: string;
    @Input() matSort: MatSort;
    @Input() filterPredicate: (data, filterValue: string) => boolean;
    @Input() sortingLogic: (item, property) => any;
    @Output() retryReload = new EventEmitter();
    @Input() persistedPagingKey: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('input') input: ElementRef;
    paginationModel: PaginationModel;
    inMobileMode: boolean = false;

    subs: Subscription = new Subscription()

    constructor(private pagingPersistService: PagingPersistService) { }

    refresh() {
        this.isFailed = false;
        this.retryReload.emit();
    }

    ngAfterViewInit() {
        this.getScreenSize();
        if (this.persistedPagingKey) {
            setTimeout(() => {
                if (this.showPaginator) {
                    this.paginator.pageIndex = this.paginationModel.page;
                    this.paginator.pageSize = this.paginationModel.pageSize;
                }

                if (this.showSearchAndRefresh) {
                    this.input.nativeElement.value = this.paginationModel.searchString;
                }

                if (this.matSort) {
                    this.matSort.active = this.paginationModel.sortOrder;
                    this.matSort.direction = this.paginationModel.ascSortDirection ? 'asc' : 'desc';
                    this.subs.add(
                        this.matSort.sortChange.pipe(tap((sort) => {
                            this._persistPageIndex();
                        })).subscribe()
                    );
                }
            });
        }

        if (this.showSearchAndRefresh) {
            this.subs.add(
                fromEvent(this.input.nativeElement, 'keyup')
                    .pipe(
                        debounceTime(300),
                        distinctUntilChanged(),
                        tap(() => {
                            this.paginator.pageIndex = 0;
                            this._persistPageIndex();
                            this.reload.emit();
                        })
                    )
                    .subscribe()
            );
        }

        if (this.showPaginator) {
            this.paginator.pageSize = this.paginationModel.pageSize;
            this.subs.add(this.paginator.page
                .pipe(
                    tap(() => {
                        this._persistPageIndex();
                        this.reload.emit();
                    })
                )
                .subscribe()
            );
        }
    }

    ngOnInit() {
        if (!this.entityDisplayName || this.entityDisplayName.length === 0) {
            this.entityDisplayName = this.entityName;
        }

        this._loadStartingPagingValues();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe()
    }

    generatePaginationModel(): PaginationModel {
        if (!this.paginator) {
            this._loadStartingPagingValues();
        }

        this.paginationModel.page = this.paginator ? this.paginator.pageIndex : 0;
        this.paginationModel.pageSize = this.paginator ? this.paginator.pageSize : this.paginationModel.pageSize;
        this.paginationModel.searchString = this.input ? this.input.nativeElement.value : '';
        if (this.matSort) {
            this.paginationModel.ascSortDirection = this.matSort.direction === 'asc';
            this.paginationModel.sortOrder = this.matSort.active;
        } else {
            this.paginationModel.ascSortDirection = false;
            this.paginationModel.sortOrder = '';
        }

        return this.paginationModel;
    }

    private _loadStartingPagingValues() {
        if (this.persistedPagingKey && this.pagingPersistService.hasValue(this.persistedPagingKey)) {
            this.paginationModel = this.pagingPersistService.getPagingValue(this.persistedPagingKey);
        } else {
            this.paginationModel = new PaginationModel({ ascSortDirection: false, page: 0, pageSize: 10, searchString: '', sortOrder: null });
        }
    }

    private _persistPageIndex() {
        this.pagingPersistService.setPagingInfo(this.persistedPagingKey, this.generatePaginationModel());
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        const width = window.innerWidth;

        setTimeout(() => {
            if (width < 769) {
                this.inMobileMode = true;
            } else {
                this.inMobileMode = false;
            }
        });
    }
}
