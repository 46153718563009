import { BackgroundJobDto } from 'src/app/services/api.services';

export class ProgesDialogInfo {
    itemsProcessed: number;
    totalItemsToProcessed: number;
    statusMessage: string;
    isComplete: boolean;

    get percent(): number {
        return this.itemsProcessed === 0 || this.totalItemsToProcessed === 0 ? 0 : (this.itemsProcessed / this.totalItemsToProcessed) * 100;
    }

    get isInitialized(): boolean {
        return this.totalItemsToProcessed > 0;
    }

    constructor(itemsProcessed: number, totalItemsToProcessed: number) {
        this.itemsProcessed = itemsProcessed;
        this.totalItemsToProcessed = totalItemsToProcessed;
    }

    static createFromBackgroundJobDto(backgroundJobDto: BackgroundJobDto) {
        const progressDialogInfo = new ProgesDialogInfo(backgroundJobDto.itemsProcessed, backgroundJobDto.totalItemsToProcessed);
        progressDialogInfo.statusMessage = backgroundJobDto.statusMessage;
        progressDialogInfo.isComplete = backgroundJobDto.isComplete;
        return progressDialogInfo;
    }
}
