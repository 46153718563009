import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServerSearchTableContainerDialog, SearchDialogResult } from '../models/searchTableContainer';
import { MatSort } from '@angular/material/sort';
import { ServerPaginationListComponent } from 'src/app/models/serverPaginationComponent';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { NotifyService } from 'src/app/services/notify.service';
import { PagingDataSource } from 'src/app/models/interfaces/dataSourceInterface';
import { PaginationModel } from 'src/app/services/api.services';

@Component({
    selector: 'app-server-search-table',
    templateUrl: './server-search-table.component.html',
    styleUrls: ['./server-search-table.component.scss'],
})
export class ServerSearchTableComponent<IdType, ModelType, QueryType> extends ServerPaginationListComponent<IdType, ModelType, QueryType> {
    private _result: SearchDialogResult<ModelType>;

    readonly dateFormat = 'M/d/yy, H:mm';

    displayedColumns: string[];
    @ViewChild(MatSort) sort: MatSort;
    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: ServerSearchTableContainerDialog<ModelType, QueryType>,
        public dialogRef: MatDialogRef<ServerSearchTableComponent<IdType, ModelType, QueryType>>,
        notifyService: NotifyService,
        confirmDialogService: ConfirmDialogService
    ) {
        super(null, settings.entityName, notifyService, confirmDialogService);

        this._result = new SearchDialogResult<ModelType>();

        this.displayedColumns = this.settings.displayProperties.map((displayProperty) =>
            displayProperty.isInTranslation ? `selectedTranslation.${displayProperty.propertyName}` : displayProperty.propertyName
        );
        this.displayedColumns.push('action');

        this.query = settings.query;
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel());
    }

    loadDataSource(): PagingDataSource<ModelType, QueryType> {
        return this.settings.server;
    }

    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel);
        this.dataSource.loadEntities(query);
    }

    deleteEntity(): Observable<void> {
        throw new Error('Method not implemented.');
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.load();
        });
        this.tableContainer.matSort = this.sort;
    }

    select(entity: any) {
        this._result.isEditClicked = false;
        this._result.isSelectedClicked = true;
        this._result.selectedEntities.push(entity);
        if (this.settings.closeOnSelect || this.settings.selectHasOtherFunction) {
            this.settings.entitySelectedObservable.next(this._result);
        }

        if (this.settings.closeOnSelect) {
            this.dialogRef.close();
        }
    }

    edit(entity: any) {
        this._result.isSelectedClicked = false;
        this._result.isEditClicked = true;
        this._result.entityToEdit = entity;
        this.settings.entitySelectedObservable.next(this._result);
        this.dialogRef.close();
    }

    addAndCloseDialog() {
        this._result.isAddClicked = true;
        this.settings.entitySelectedObservable.next(this._result);
        this.dialogRef.close();
    }

    done() {
        this.settings.entitySelectedObservable.next(this._result);
        this.dialogRef.close();
    }
}
