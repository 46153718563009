import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { CommonComponent } from 'src/app/models/formComponent';
import { ServiceTicketsService, ServiceTicketNoteDto, CreateServiceTicketNoteCommand, ServiceTicketNoteType, FileParameter } from 'src/app/services/api.services';
import { NotifyService } from 'src/app/services/notify.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumListService } from 'src/app/services/enum-list.service';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-service-ticket-note-form-dialog',
    templateUrl: './service-ticket-note-form-dialog.component.html',
    styleUrls: ['./service-ticket-note-form-dialog.component.scss'],
})
export class ServiceTicketNoteFormDialogComponent extends CommonComponent implements OnInit, AfterViewInit {
    formGroup: FormGroup;
    model: ServiceTicketNoteDto;

    files: FileParameter[] = [];
    @ViewChild('fileInput') fileInput;

    isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: NoteFormDialogSettings<ServiceTicketNoteDto>,
        private dialogRef: MatDialogRef<ServiceTicketNoteFormDialogComponent>,
        public enumListService: EnumListService,
        private notifyService: NotifyService,
        private formBuilder: FormBuilder,
        private serviceTicketService: ServiceTicketsService
    ) {
        super();
    }

    ngOnInit() {
        this._setupformControl();
    }

    ngAfterViewInit(): void {
        if (!this.model.id) {
            return;
        }
    }

    onFilesAdded() {
        const files: File[] = this.fileInput.nativeElement.files;
        if (files.length > 0) {
            for (let index = 0; index < files.length; index++) {
                const element = files[index];
                this.files[index] = {
                    data: element,
                    fileName: element.name,
                };
            }
        }
    }

    private _createNewModel() {
        this.model = new ServiceTicketNoteDto();
        this.model.noteType = ServiceTicketNoteType.Comment;
        this.model.id = this.settings.entityId;
    }

    onSubmitClick(): void {
        this._generateFromForm();

        if (this.formGroup.valid) {
            this.isLoading = true;

            if (!this.settings.isEdit) {
                this.serviceTicketService
                    .createServiceTicketNote(CreateServiceTicketNoteCommand.fromJS(this.formGroup.value))
                    .pipe(
                        mergeMap((x) => {
                            if (this.files.length > 0) {
                                return this.serviceTicketService.attachFileToNote(x, this.files).pipe(mergeMap((_) => of(x)));
                            }
                            return of(x);
                        }),
                        mergeMap((noteId) => this.serviceTicketService.serviceTicketNote(noteId))
                    )
                    .subscribe(
                        (noteEntity) => {
                            this.dialogRef.close(new DialogResult(DialogResultActions.Create, noteEntity));
                        },
                        (error) => {
                            this._handleFailure(error);
                        }
                    );
            }
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    private _handleFailure(error: any) {
        console.log(error);
        this.isLoading = false;
        this.notifyService.fail('OPERATIONFAILED', true);
    }

    private _generateFromForm() {
        const oldNoteType = this.model.noteType;

        const serviceTicketNote = new ServiceTicketNoteDto();
        serviceTicketNote.init(this.formGroup.value);
        this.model = Object.assign(this.model, serviceTicketNote);
        this.model.noteType = ServiceTicketNoteType.Comment;
        this.model.id = this.settings.entityId;

        if (this.model.id) {
            this.model.noteType = oldNoteType;
        }
    }

    private _setupformControl() {
        if (!this.settings.isEdit) {
            this._createNewModel();
        } else {
            this.model = this.settings.model;
        }

        this.formGroup = this.formBuilder.group({
            serviceTicketId: [this.model.id],
            description: [this.model.description],
            noteType: [this.model.noteType],
            formFile: [''],
        });
    }
}
