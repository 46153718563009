import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PastAppointmentsDisplayDialogComponent } from './past-appointments-display-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PastAppointmentsDisplayDialogComponent],
    imports: [CommonModule, MaterialModule, PipesModule, TranslateModule.forRoot()],
    exports: [PastAppointmentsDisplayDialogComponent],
})
export class PastAppointmentsDisplayDialogModule {}
