import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { ServiceTicketFormDialogComponent } from './service-ticket-form-dialog.component';
import { PipesModule } from '../../pipes/pipes.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [ServiceTicketFormDialogComponent],
    imports: [CommonFormsModule, MaterialModule, CommonModule, PipesModule, OwlNativeDateTimeModule, OwlDateTimeModule],
    exports: [ServiceTicketFormDialogComponent],
})
export class ServiceTicketFormDialogModule {}
