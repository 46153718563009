import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonComponent } from 'src/app/models/formComponent';
import { CategoriesService, CategoryDto, CategoryReorderDto, CategoryToReorderDto, ReorderCategoriesCommand } from 'src/app/services/api.services';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-category-reorder-dialog',
    templateUrl: './category-reorder-dialog.component.html',
    styleUrls: ['./category-reorder-dialog.component.scss'],
})
export class CategoryReorderDialogComponent extends CommonComponent implements OnInit {
    displayedColumns: string[] = ['selected', 'menuName', 'ProductsInCategory', 'CategoryOrderIndex'];
    selection = new SelectionModel<CategoryReorderDto>(true, []);
    objectToCopy: CategoryReorderDto;
    loading: boolean = false;

    get onlyOneSelected(): boolean {
        return this.selection.selected.length == 1;
    }

    constructor(
        private dialogRef: MatDialogRef<CategoryReorderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Array<CategoryReorderDto>,
        private translateService: TranslateService,
        private categoriesService: CategoriesService,
        private notifyService: NotifyService
    ) {
        super();
    }

    ngOnInit() {
        if (this.data?.length == 0) {
            this.notifyService.success('CATEGORY.REORDERMENU.NOCONTENT');
            this.dialogRef.close();
        } else {
            this.data.sort((a, b) => a.menuName.localeCompare(b.menuName));
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.data.forEach((row) => this.selection.select(row));
    }

    updateCategoryOrder() {
        this.loading = true;
        var command = new ReorderCategoriesCommand();

        command.categoriesWithNewOrderIndex = this.selection.selected.map((x) => x as CategoryToReorderDto);
        this.categoriesService.updateMenuOrder(command).subscribe(
            (result) => {
                this.loading = false;
                this.notifyService.success('CATEGORY.REORDERMENU.SUCCESS');
                this.dialogRef.close();
            },
            (error) => {
                this.loading = false;
                this.notifyService.fail('CATEGORY.REORDERMENU.FAIL');
            }
        );
    }

    copyRow() {
        if (!this.onlyOneSelected) return;
        this.objectToCopy = this.selection.selected[0];

        if (!this.displayedColumns.includes('Action')) this.displayedColumns.push('Action');
    }

    pasteToRow(row: CategoryReorderDto) {
        row.categoryOrderIndex = this.objectToCopy.categoryOrderIndex;
        this.selectRow(row);
    }

    selectRow(row: CategoryReorderDto) {
        this.selection.select(row);
    }
}
