import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgCropperDialogComponent } from './img-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { InputsModule } from 'src/app/shared/inputs/inputs.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [FormsModule, CommonModule, ImageCropperModule, MaterialModule, InputsModule, TranslateModule.forChild()],
    declarations: [ImgCropperDialogComponent],
})
export class ImgCropperDialogModule {}
