import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { CommonComponent } from 'src/app/models/formComponent';
import { AppointmentDto, AppointmentType, ClientsService } from 'src/app/services/api.services';
import { EnumListService } from 'src/app/services/enum-list.service';
import { FormContainerService } from 'src/app/services/form-container.service';
import { NotifyService } from 'src/app/services/notify.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-appointment-type-dialog',
    templateUrl: './appointment-type-dialog.component.html',
    styleUrls: ['./appointment-type-dialog.component.scss'],
})
export class AppointmentTypeDialogComponent extends CommonComponent implements OnInit {
    appointmentTypes = this.enumListService.appointmentTypes;
    formGroup: FormGroup;

    loading: boolean = false;
    disableImportButton: boolean = true;

    _canCreateAppointment: boolean;
    _canCreateNewAppointment: boolean;
    _canCreateRenewalAppointment: boolean;
    _canCreateWinbackAppointment: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: AppointmentDto,
        public permissionService: NgxPermissionsService,
        private clientsService: ClientsService,
        private formContainerService: FormContainerService,
        private enumListService: EnumListService,
        private formBuilder: FormBuilder,
        private notifyService: NotifyService,
        private dialogRef: MatDialogRef<AppointmentTypeDialogComponent>
    ) {
        super();

        this._canCreateAppointment = this.permissionService.getPermission('CreateAppointment') !== undefined;
        this._canCreateNewAppointment = this.permissionService.getPermission('CreateNewAppointment') !== undefined;
        this._canCreateRenewalAppointment = this.permissionService.getPermission('CreateRenewalAppointment') !== undefined;
        this._canCreateWinbackAppointment = this.permissionService.getPermission('CreateWinbackAppointment') !== undefined;
    }

    ngOnInit() {
        this._setupFormControl();
    }

    private _setupFormControl() {
        this.formGroup = this.formBuilder.group({
            appointmentType: [null, [Validators.required]],
            clientNo: [this.settings.clientNo],
        });

        this.appointmentTypeControl.valueChanges.subscribe((appointmentType: AppointmentType) => {
            this.loading = false;
            switch (appointmentType) {
                case AppointmentType.New:
                    this.clientNoControl.clearValidators();
                    this.clientNoControl.updateValueAndValidity();
                    break;
                case AppointmentType.Renew:
                case AppointmentType.Winback:
                    this.clientNoControl.setValidators([Validators.required]);
                    this.clientNoControl.updateValueAndValidity();
                    break;
                default:
                    break;
            }
        });

        if (this._canCreateAppointment) this.appointmentTypeControl.setValue(AppointmentType.New);
        else if (this._canCreateNewAppointment) this.appointmentTypeControl.setValue(AppointmentType.New);
        else if (this._canCreateRenewalAppointment) this.appointmentTypeControl.setValue(AppointmentType.Renew);
        else if (this._canCreateWinbackAppointment) this.appointmentTypeControl.setValue(AppointmentType.Winback);
    }

    onSubmit() {
        this.loading = true;
        if (this.formGroup.valid) {
            if (this.appointmentTypeControl.value == AppointmentType.New) {
                this.dialogRef.close(new DialogResult(DialogResultActions.Create, null));
                this.formContainerService.router.navigate(['appointments/create']);
            } else {
                let _this = this;
                this.clientsService.clientFromClientNo(this.clientNoControl.value).subscribe({
                    next: (clientAndLastRep) => {
                        _this.dialogRef.close(new DialogResult(DialogResultActions.Create, null));
                        _this.formContainerService.router.navigate(['appointments/create', _this.appointmentTypeControl.value, clientAndLastRep.client.clientNumber]);
                    },
                    error: (err) => {
                        _this.loading = false;
                        _this.notifyService.fail('APPOINTMENT.IMPORTCLIENTINFO.FAIL', true);
                    }
                });
            }
        } else {
            this.loading = false;
            this.validateAllFormFields(this.formGroup);
        }
    }

    canViewAppointmentType(appointmentType): boolean {
        if (
            this._canCreateAppointment ||
            (appointmentType == AppointmentType.New && this._canCreateNewAppointment) ||
            (appointmentType == AppointmentType.Renew && this._canCreateRenewalAppointment) ||
            (appointmentType == AppointmentType.Winback && this._canCreateWinbackAppointment)
        )
            return true;

        return false;
    }

    get appointmentTypeControl(): AbstractControl | null {
        return this.formGroup.get('appointmentType');
    }

    get clientNoControl(): AbstractControl | null {
        return this.formGroup.get('clientNo');
    }

    get isWinback(): boolean {
        return this.appointmentTypeControl.value === AppointmentType.Winback;
    }

    get isRenew(): boolean {
        return this.appointmentTypeControl.value === AppointmentType.Renew;
    }

    get isNew(): boolean {
        return this.appointmentTypeControl.value === AppointmentType.New;
    }

    get showClientImport() {
        return this.isRenew || this.isWinback;
    }
}
