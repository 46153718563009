export class FileUploadSetting {
    title: string;
    url: string;
    data: any;
    accept: string;

    /**
     *
     */
    constructor(title: string, url: string, data: any, accept?: string) {
        this.title = title;
        this.url = url;
        this.data = data;
        this.accept = accept;
    }
}
