import { NgModule } from '@angular/core';
import { AddToDncDialogComponent } from './add-to-dnc-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [AddToDncDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    exports: [AddToDncDialogComponent],
})
export class AddToDncDialogModule {}
