import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-clientportal',
  templateUrl: './client-portal-navbar.component.html'
})
export class ClientPortalNavbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }

  openNavbar() {
    console.log('click');
  }
}
