import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../models/myErrorStateMatcher';

@Component({
  selector: 'app-percent',
  templateUrl: './percent.component.html',
  styleUrls: ['./percent.component.scss']
})
export class PercentComponent implements OnInit {

  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() disabled = false;
  @Input() group: FormGroup;

  matcher = new MyErrorStateMatcher();

  constructor() { }

  ngOnInit() {
  }

}
