import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, OnDestroy, Inject } from '@angular/core';
import { FormDialogSettings } from 'src/app/models/dialogs/formDialogSettings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogCapableFormComponent } from 'src/app/models/formComponent';
import { DialogResult } from 'src/app/models/dialogResult';
import { LanguageService } from 'src/app/services/language.service';
import { FormContainerService } from 'src/app/services/form-container.service';

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent<EntityType, ComponentType> implements OnInit, OnDestroy {
    dialogTitle: string;
    component: DialogCapableFormComponent<any, EntityType>;

    @ViewChild('formContainer', { read: ViewContainerRef }) container;
    componentRef: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: FormDialogSettings<EntityType, ComponentType>,
        private resolver: ComponentFactoryResolver,
        public languageService: LanguageService,
        private formContainerService: FormContainerService,
        private dialogRef: MatDialogRef<FormDialogComponent<EntityType, ComponentType>>
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.createComponent();
        });
    }

    onSubmitClick() {
        this.component.onSubmitFromDialog().subscribe((created) => {
            if (created) {
                this.dialogRef.close(created);
            } else {
                this.dialogRef.close();
            }
        });
    }

    createComponent() {
        this.formContainerService.isDialogMode = true;
        this.container.clear();
        const factory = this.resolver.resolveComponentFactory(this.settings.componentType);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.isInDialogMode = true;
        this.component = this.componentRef.instance as DialogCapableFormComponent<any, EntityType>;
        this.dialogTitle = this.component.formComponentSettings.entityName;
        this.formContainerService.isDialogMode = false;
    }

    ngOnDestroy() {
        this.componentRef.destroy();
    }
}
