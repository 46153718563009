import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageMediaDto } from 'src/app/services/api.services';

@Component({
    selector: 'app-display-img',
    templateUrl: './display-img.component.html',
    styleUrls: ['./display-img.component.scss'],
})
export class DisplayImgComponent implements OnInit {
    @Output() changeImage = new EventEmitter();
    @Input() imageUrl: string;

    @Input() imageTitle: string;

    constructor() {}

    ngOnInit(): void {}

    isSvg() {
        return this.imageUrl.includes('svg');
    }
}
