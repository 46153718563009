import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {
    regexStr = '^[a-zA-Z0-9_ ]*$';
    @Input() isAlphaNumeric: boolean;

    constructor(private element: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event) {
        setTimeout(() => {
            this.element.nativeElement.value = this.element.nativeElement.value
                .replace(/[ÀÁÂÃÄÅ]/g,"A")
                .replace(/[àáâãäå]/g,"a")
                .replace(/[ÈÉÊË]/g,"E")
                .replace(/[éèêë]/g,"e")
                .replace(/[ÏÎ]/g,"I")
                .replace(/[ïî]/g,"i")
                .replace(/[Ô]/g,"O")
                .replace(/[ô]/g,"o")
                .replace(/[ÜÛ]/g,"U")
                .replace(/[üû]/g,"u")
                .replace(/[Ç]/g,"c")
                .replace(/[ç]/g,"c");
            event.preventDefault();
        }, 100)
    }
}