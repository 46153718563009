import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { ChangeServiceTicketStatusDialogComponent } from './change-service-ticket-status-dialog.component';

@NgModule({
    declarations: [ChangeServiceTicketStatusDialogComponent],
    imports: [CommonFormsModule, MaterialModule, CommonModule],
    exports: [ChangeServiceTicketStatusDialogComponent],
})
export class ChangeServiceTicketStatusDialogModule {}
