import { NgModule } from '@angular/core';
import { BlanketOrderModificationPreviewDialogComponent } from './blanket-order-modification-preview-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BlanketOrderModificationPreviewDialogComponent
  ],
  imports: [
    CommonModule, MaterialModule, TranslateModule
  ],
  exports: [
    BlanketOrderModificationPreviewDialogComponent
  ]
})
export class BlanketOrderModificationPreviewDialogModule { }
