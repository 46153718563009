import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationService {

  configuration: any;
  storageKey = 'userConfiguration';
  sidebarMinimized: boolean;
  sidebarHidden: boolean;
  language: string;

  constructor() {
    this.configuration = JSON.parse(localStorage.getItem(this.storageKey));

    if (!this.configuration) {
      this.configuration = {
        defaultPageSize: 10,
        sidebarMinimized: false,
        sidebarHidden: false,
        language: 'fr'
      };
    }
  }

  setConfigValue(key, value) {
    this.configuration[key] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(this.configuration));
  }

  retrieveConfigValue(key) {
    return this.configuration[key];
  }

}
