import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ProgesDialogInfo } from './progress-dialog-info';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent implements OnInit {

  @Input('title') title: string;

  progress: ProgesDialogInfo;

  constructor(public dialogRef: MatDialogRef<ProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { progress: Observable<ProgesDialogInfo>}) { 
      this.dialogRef.disableClose = true;
      const subscription = data.progress.subscribe(progress => {
        this.progress = progress;

        if (this.progress.isComplete) {
          subscription.unsubscribe();
        }
      })

    }


  ngOnInit() {
  }

}
