import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

export class MediaUploadProgress {
  progressPercent: number;
  uploadedUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaUploadService {

  constructor(private http: HttpClient) { }

  uploadFile(file: File): Observable<MediaUploadProgress> {
    const formData = new FormData();
    formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', `/api/medias/upload`, formData, {
      reportProgress: true,
    });

    const subject = new ReplaySubject<MediaUploadProgress>();

    this.http.request(uploadReq).subscribe(event => {
      const progress = new MediaUploadProgress();
      if (event.type === HttpEventType.UploadProgress) {
        progress.progressPercent = Math.round(100 * event.loaded / event.total);
        subject.next(progress);
      }
      else if (event.type === HttpEventType.Response) {
        progress.uploadedUrl = event.body.toString();
        subject.next(progress);
        subject.complete();
      }
    });
    return subject.asObservable();
  }
}
