import { NgModule } from '@angular/core';
import { AppointmentManagementFormComponent } from './appointment-management-form.component';
import { CommonAppointmentFormModule } from 'src/app/shared/common-appointment-form/common-appointment-form.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AppointmentDispatchModule } from '../appointment-dispatch/appointment-dispatch.module';
import { AlphaNumericModule } from 'src/app/shared/directives/alphanumeric/alphanumeric.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [AppointmentManagementFormComponent],
    imports: [CommonAppointmentFormModule, CommonFormsModule, AppointmentDispatchModule, AlphaNumericModule, OwlDateTimeModule, OwlNativeDateTimeModule],
})
export class AppointmentManagementFormModule {}
