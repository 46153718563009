import { Component } from '@angular/core';
import { ClientLeadsService, RemoveOrderReferencesCommand } from 'src/app/services/api.services';
import { MatDialogRef } from '@angular/material/dialog';
import { NotifyService } from 'src/app/services/notify.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-find-order-references-dialog',
    templateUrl: './find-order-references-dialog.component.html',
    styleUrls: ['./find-order-references-dialog.component.scss'],
})
export class FindOrderReferencesDialogComponent {
    orderNo: number = 0;
    command: RemoveOrderReferencesCommand = new RemoveOrderReferencesCommand();
    loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FindOrderReferencesDialogComponent>,
        private clientLeadsService: ClientLeadsService,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private translateService: TranslateService
    ) {}

    pullClientLeads() {
        this.loading = true;

        this.clientLeadsService.getOrderReferencesCount(this.orderNo).subscribe(
            (refCount) => {
                this.loading = false;

                if (refCount > 0) {
                    this.popUpConfirmDialog(refCount);
                } else {
                    this.notifyService.fail('CLIENTLEAD.ORDERREFERENCESDIALOG.NORESULT', true);
                }
                return this.dialogRef.close(true);
            },
            (err) => {
                this.loading = false;
                this.notifyService.fail('OPERATIONFAILED', true);
            }
        );
    }

    private popUpConfirmDialog(refCount: number) {
        this.confirmDialogService
            .showDialogWithLoader(this.translateService.instant('CLIENTLEAD.ORDERREFERENCESDIALOG.MESSAGE', { refCount: refCount }), this.removeOrderReferences(this.orderNo))
            .subscribe((x) => {
                if (x.success) {
                    this.notifyService.success('OPERATIONSUCCEEDED', true);
                } else {
                    this.notifyService.fail('OPERATIONFAILED', true);
                }
            });
    }

    private removeOrderReferences(orderNo: number): Observable<any> {
        this.command.orderNo = orderNo;
        return this.clientLeadsService.removeOrderReferences(this.command);
    }
}
