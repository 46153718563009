import { Component, OnInit, Input, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';
import { Language } from 'src/app/models/language';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageService, ImageMediaDto, CreateImageCommand } from 'src/app/services/api.services';
import { MediaUploadService } from 'src/app/services/media-upload.service';
import { Subject, Observable, empty, of } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { map, flatMap, mergeMap } from 'rxjs/operators';

enum CropStatus {
    notSelected = 0,
    notCropped = 1,
    cropped = 2,
}

export class ImageInfo {
    path: string;
    rawByte64: string;
}

export class ImageResult {
    imageFile: File;
    constructor(public rawImage: string) { }
}

@Component({
    selector: 'app-img-cropper-dialog',
    templateUrl: './img-cropper-dialog.component.html',
    styleUrls: ['./img-cropper-dialog.component.scss'],
})
export class ImgCropperDialogComponent implements OnInit {
    imageChangedEvent: any = '';
    imageResult: ImageResult;
    cropStatus: CropStatus;
    selectedLanguage: Language;
    imageInfo: any;
    displayCaptions: boolean;

    width: string;
    height: string;
    resizeToWdith: number;
    isPersistEdit: boolean;
    aspectRation: number;
    isUpload: boolean;

    imageToUpload: any;
    files: File[];

    isBeingUploaded: boolean;
    validation: any;
    validationError: boolean = false;

    @ViewChild('cropper') imgCropper: ImageCropperComponent;
    @ViewChild('file') file: ElementRef;
    constructor(
        public languageService: LanguageService,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ImageCropperComponent>,
        private imageService: ImageService,
        private notifyService: NotifyService,
        private mediaUploadService: MediaUploadService
    ) {
        this.isUpload = data.isUpload;
        this.imageInfo = data.imageInfo;
        this.resizeToWdith = data.width;
        this.aspectRation = data.width / data.height;
        this.width = `${data.width}px`;
        this.height = `${data.height}px`;
        this.validation = data.validation;
    }

    ngOnInit(): void {
        this.cropStatus = CropStatus.notSelected;
    }

    uploadImage() {
        this.isBeingUploaded = true;

        this.mediaUploadService
            .uploadFile(this.imageToUpload)
            .pipe(
                mergeMap((result) => {
                    if (result.uploadedUrl) {
                        return this._createMediaEntity(result.uploadedUrl);
                    } else {
                        console.log(result.progressPercent);
                        return of();
                    }
                })
            )
            .subscribe({
                next: (result) => {
                    this.isBeingUploaded = false;
                    if (result) {
                        this.dialogRef.close(result);
                    }
                },
                error: (err) => {
                    this.isBeingUploaded = false;
                    this.notifyService.fail('Error uploading image');
                    console.log(err);
                }
            });
    }

    private _createMediaEntity(uploadedUrl: string): Observable<ImageMediaDto> {
        const command = new CreateImageCommand();
        command.fileType = this.imageToUpload.type;
        command.uploadedUrl = uploadedUrl;
        command.fileSize = this.imageToUpload.size;
        command.filename = this.imageToUpload.name;
        return this.imageService.create(command);
    }

    selectImage() {
        this.file.nativeElement.click();
    }

    fileChangeEvent(event: any): void {
        let _this = this;
        this.validateImage(event, function (success, error) {
            if (success) {
                _this.files = _this.file.nativeElement.files;
                _this.imageChangedEvent = event;
                _this.cropStatus = CropStatus.notCropped;
            } else if (error) _this.validationError = error;
        });
    }

    imageCropped(event: ImageCroppedEvent) {
        const blobToBase64 = (blob: Blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        };

        blobToBase64(event.blob).then((base64: string) => {
            this.imageResult = new ImageResult(base64);
            const fileName = this.files[0].name.split('.')[0];
            this.imageToUpload = this._dataURLtoBlob(base64);
            this.imageToUpload.name = `${fileName}.png`;
        });
    }

    imageLoaded() {
        this.isPersistEdit = false;
    }

    loadImageFailed() {
        this.notifyService.fail('Error loading image');
    }

    private validateImage(event, callback) {
        if (!this.validation) callback(true);

        let _this = this;
        for (const file of event.target.files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    const height = img.naturalHeight;
                    const width = img.naturalWidth;

                    let imageAspect = width >= _this.validation.minWidth && height >= _this.validation.minHeight;
                    if (!imageAspect) {
                        callback(false, `The image must be at least ${_this.validation.minWidth}X${_this.validation.minHeight}`);
                        return;
                    }

                    callback(true);
                };
            };
        }
    }

    private _dataURLtoBlob(dataurl: string) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}
