import { NgModule } from '@angular/core';
import { SimpleEntityCreateFormDialogComponent } from './simple-entity-create-form-dialog.component';
import { InputsModule } from '../inputs/inputs.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    imports: [CommonFormsModule, InputsModule],
    exports: [SimpleEntityCreateFormDialogComponent],
    declarations: [SimpleEntityCreateFormDialogComponent],
})
export class SimpleEntityCreateFormDialogModule {}
