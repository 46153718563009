import { NgModule } from '@angular/core';
import { CallbackNoteFormDialogComponent } from './callback-note-form-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    imports: [CommonFormsModule, MaterialModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    declarations: [CallbackNoteFormDialogComponent],
    exports: [CallbackNoteFormDialogComponent],
})
export class CallbackNoteFormDialogModule {}
