import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CallbackNoteFormDialogComponent } from './callback-note-form-dialog.component';
import { Observable } from 'rxjs';
import { CallbackClientLeadNoteDto, ClientLeadNoteDto, LeadNoteType } from 'src/app/services/api.services';
import { DialogResult } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';

@Injectable({
    providedIn: 'root',
})
export class CallbackNoteFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: NoteFormDialogSettings<CallbackClientLeadNoteDto>): Observable<DialogResult<CallbackClientLeadNoteDto>> {
        const dialogRef = this.dialog.open(CallbackNoteFormDialogComponent, {
            width: '800px',
            data: setting,
        });

        return dialogRef.afterClosed();
    }

    showCallbackDialog(settings: NoteFormDialogSettings<CallbackClientLeadNoteDto>): Observable<DialogResult<CallbackClientLeadNoteDto>> {
        const dialogRef = this.dialog.open(CallbackNoteFormDialogComponent, {
            width: '800px',
            data: settings,
        });

        return dialogRef.afterClosed();
    }

    showCallbackFollowUpDialog(callbackClientLeadId: number): Observable<DialogResult<CallbackClientLeadNoteDto>> {
        return this.showDialog(this._generateCallbackNoteSettings(callbackClientLeadId, LeadNoteType.FollowUp));
    }

    showCallbackNotInterestedDialog(callbackClientLeadId: number): Observable<DialogResult<CallbackClientLeadNoteDto>> {
        return this.showDialog(this._generateCallbackNoteSettings(callbackClientLeadId, LeadNoteType.FollowUp));
    }

    showCallbackUnbookableDialog(callbackClientLeadId: number): Observable<DialogResult<CallbackClientLeadNoteDto>> {
        return this.showDialog(this._generateCallbackNoteSettings(callbackClientLeadId, LeadNoteType.FollowUp));
    }

    private _generateCallbackNoteSettings(callbackClientLeadId: number, requiredNoteType: LeadNoteType): NoteFormDialogSettings<CallbackClientLeadNoteDto> {
        const settings = new NoteFormDialogSettings<CallbackClientLeadNoteDto>();
        settings.isCallback = true;
        settings.allowClientLeadIdSelection = false;
        settings.entityId = callbackClientLeadId;
        settings.allowNoteTypeSelection = false;
        settings.requiredNoteTypeSelection = requiredNoteType;
        settings.isEdit = false;
        return settings;
    }
}
