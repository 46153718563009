import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServerPagingTableContainerModule } from '../../server-pagination-table-container/server-paging-table-container.module';
import { MaterialModule } from 'src/app/material.module';
import { ServerSearchTableComponent } from './server-search-table.component';

@NgModule({
    imports: [CommonModule, MaterialModule, ServerPagingTableContainerModule, TranslateModule.forChild()],
    declarations: [ServerSearchTableComponent],
    exports: [ServerSearchTableComponent],
})
export class ServerSearchTableModule {}
