import { NgModule } from '@angular/core';
import { AppointmentDispatchConfirmDialogComponent } from './appointment-dispatch-confirm-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppointmentDetailsModule } from 'src/app/shared/appointment-details/appointment-details.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AppointmentManagementFormModule } from 'src/app/admin-pages/appointment-management/appointment-management-form/appointment-management-form.module';
import { AppointmentDispatchModule } from 'src/app/admin-pages/appointment-management/appointment-dispatch/appointment-dispatch.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [AppointmentDispatchConfirmDialogComponent],
    imports: [
        CommonFormsModule,
        MaterialModule,
        PipesModule,
        TranslateModule.forRoot(),
        AppointmentDetailsModule,
        AppointmentManagementFormModule,
        AppointmentDispatchModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    exports: [AppointmentDispatchConfirmDialogComponent],
})
export class AppointmentDispatchConfirmDialogModule {}
