import { Type } from "@angular/core";

export class FormDialogSettings<EntityType, ComponentType> {
    isEdit = true;

    constructor(public entityType: Type<EntityType>, 
        public componentType: Type<ComponentType>, 
        public useLanguage: boolean = false,
        public width: string = '800px') {

    }
}