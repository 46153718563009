import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { CommonComponent } from 'src/app/models/formComponent';
import { AppointmentDto, ErpIntegrationMgmtService, PushSalesOrdersToOptimoRouteCommand } from 'src/app/services/api.services';
import { NotifyService } from 'src/app/services/notify.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, map, of, tap } from 'rxjs';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'app-push-to-optimoroute-dialog',
    templateUrl: './push-to-optimoroute-dialog.component.html',
    styleUrls: ['./push-to-optimoroute-dialog.component.scss'],
})
export class PushToOptimorouteDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup;

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: AppointmentDto,
        public permissionService: NgxPermissionsService,
        private erpIntegrationMgmtService: ErpIntegrationMgmtService,
        private formBuilder: FormBuilder,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private dialogRef: MatDialogRef<PushToOptimorouteDialogComponent>
    ) {
        super();
    }

    ngOnInit() {
        this._setupFormControl();
    }

    private _setupFormControl() {
        this.formGroup = this.formBuilder.group({
            syncStartDate: [null, [Validators.required]],
        });
    }

    public get minDate() {
        var minDate = new Date();
        minDate.setHours(0, 0, 0, 0);

        return minDate;
    }

    onSubmit() {
        this.loading = true;
        console.log(this.formGroup.value);
        if (this.formGroup.valid) {
            this.erpIntegrationMgmtService.pushSalesOrdersToOptimoRoute(
                PushSalesOrdersToOptimoRouteCommand.fromJS({
                    syncStartDate: this.formGroup.value.syncStartDate,
                })
            ).pipe(
                tap(() => {
                    this.loading = false;
                    this.notifyService.success('Les commandes ont été envoyées à OptimoRoute');
                    this.dialogRef.close(new DialogResult(DialogResultActions.Create, null));
                }),
                catchError((error) => {
                    this.loading = false;
                    console.error('Import error:', error);
                    this.confirmDialogService.showDialog(
                        `Error while importing orders into Optimo. Error: ${error.message || JSON.stringify(error)}`,
                        'Error',
                        undefined,
                        "Fermer",
                        false
                    ).pipe(
                        map(() => false) // Ensures subsequent success handling doesn't trigger
                    );
                    return of();
                })
            ).subscribe();
        } else {
            this.loading = false;
            this.validateAllFormFields(this.formGroup);
        }
    }
}
