import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AccountService } from 'src/app/services/api.services';

@Component({
    selector: 'app-client-portal-layout',
    templateUrl: './client-portal-layout.component.html',
    styleUrls: ['./client-portal-layout.component.scss'],
})
export class ClientPortalLayoutComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;

    constructor(private router: Router, private element: ElementRef, private accountService: AccountService) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;

        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this._router = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.sidebarClose();
        });

        const body = document.getElementsByTagName('body')[0];
        body.classList.add('off-canvas-sidebar');
    }

    navigateToSelections() {}

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    logout() {
        this.accountService.logout();
        this.router.navigate(['/login']);
    }

    getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }
}
