import { Component, OnInit, Inject, ViewChild, ComponentFactoryResolver, ViewContainerRef, Type } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INotesComponent } from 'src/app/shared/interfaces/INotesDialogComponent';

@Component({
    selector: 'app-common-notes-dialog',
    templateUrl: './common-notes-dialog.component.html',
    styleUrls: ['./common-notes-dialog.component.scss'],
})
export class NotesDialogComponent<T extends INotesComponent<TEntity>, TEntity> implements OnInit {
    @ViewChild('notesContent', {
        static: true,
        read: ViewContainerRef,
    })
    noteList: ViewContainerRef;

    componentInstance: T;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            entityId: number;
            component: Type<T>;
        },
        @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver
    ) { }

    ngOnInit() {
        const factory = this.factoryResolver.resolveComponentFactory<T>(this.data.component);
        const component = factory.create(this.noteList.injector);
        this.componentInstance = component.instance;
        this.componentInstance.entityId = this.data.entityId;
        this.componentInstance.allowModify = false;
        this.componentInstance.hideFixedButton = true;
        this.noteList.insert(component.hostView);
    }
}
