import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlanketOrderInitiateReturnDialogComponent } from './blanket-order-initiate-return-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';



@NgModule({
  declarations: [
    BlanketOrderInitiateReturnDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CommonFormsModule
  ],
  exports: [
    BlanketOrderInitiateReturnDialogComponent
  ]
})
export class BlanketOrderInitiateReturnDialogModule { }
