export interface BlanketOrder {
    blanketOrderNumber?: string
    status?: string
    customer?: string
    numberOfDeliveries?: number
    quantityTotal?: number
    orderTotal?: number
    taxTotal?: number
    salesOrders?: SalesOrder[]
    date?: Date
    inventoryItemsTotal?: SalesOrderInventoryItem[]
}

export interface SalesOrder {
    salesOrderNumber?: string
    inventoryItems?: SalesOrderInventoryItem[]
    status?: SalesOrderStatus
    type?: SalesOrderType
    requestDate?: Date

    // Fields that are calculated
    percentageOfTotalOrder?: number
    totalAmount?: number
    associatedBlanketOrder?: BlanketOrder
}

export interface SalesOrderInventoryItem {
    id?: string
    itemNo?: string
    displayName?: string
    quantity?: number
    unitPrice?: number
    uom?: string // unit of measure
    itemType?: string
    itemDescription?: string

    isInStaging?: boolean // If true, the item is in staging in the UI, not in the database

    // Only for child sale orders' line items
    associatedBlanketInventoryItem?: SalesOrderInventoryItem
    returnOrReplacement?: string
    returnReason?: string
}

export enum InventoryItemReturnOrReplacement {
    Return = 'RETOUR',
    Replacement = 'REMPLACEMENT'
}

export enum SalesOrderStatus {
    OnHold = 'On Hold',
    CreditHold = 'Credit Hold',
    Open = 'Open',
    Shipping = 'Shipping',
    Completed = 'Completed',
    WaitingForRoute = 'Attente De Route',
    Route = 'Route',
}

export enum SalesOrderType {
    BL = 'BL',
    SO = 'SO',
    IM = 'IM'
}

export enum InventoryType {
    NonInventoryGoods = 'Non-Inventory Goods',
    InventoryGoods = 'Goods for Inventory',
}