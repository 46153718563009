import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare const $: any;

interface INotify {
    success(message: string);
    fail(message: string);
}

@Injectable()
export class NotifyService implements INotify {
    constructor(private translateService: TranslateService, private _snackBar: MatSnackBar) {}

    success(message: string, translate = true, duration: number = 3000) {
        this._snackBar.open(translate ? this.translateService.instant(message) : message, null, {
            duration: duration,
            verticalPosition: 'top',
            panelClass: ['snackbar-success'],
        });
    }

    fail(message: string, translate = true, error: string = null, duration: number = 3000) {
        this._snackBar.open(translate ? this.translateService.instant(message) : message, null, {
            duration: duration,
            verticalPosition: 'top',
            panelClass: ['snackbar-danger'],
        });

        if (error !== null) {
            console.log(error);
        }
    }
}
