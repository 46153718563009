import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { LocalSearchTableComponent } from './local-search-table/local-search-table.component';
import { LocalSearchTableContainerDialog, ServerSearchTableContainerDialog, OrderItemsServerSearchTableContainerDialog, SearchDialogResult } from './models/searchTableContainer';
import { ServerSearchTableComponent } from './server-search-table/server-search-table.component';
import { OrderItemsServerSearchTableComponent } from './order-items-server-search-table/order-items-server-search-table.component';


@Injectable({
  providedIn: 'root'
})
export class SearchDialogService {

  constructor(private dialog: MatDialog) { }

  showLocalDialog<EntityModel>(settings: LocalSearchTableContainerDialog<EntityModel>): Observable<SearchDialogResult<EntityModel>> {
    const dialogRef = this.dialog.open(LocalSearchTableComponent, {
      width: settings.dialogWidth,
      data: settings
    });

    return settings.entitySelectedObservable;
  }

  showServerDialog<EntityModel, QueryModel>(settings: ServerSearchTableContainerDialog<EntityModel, QueryModel>): Observable<SearchDialogResult<EntityModel>> {

    const dialogRef = this.dialog.open(ServerSearchTableComponent, {
      width: settings.dialogWidth,
      data: settings
    });

    return settings.entitySelectedObservable;
  }

  showOrderItemsServerDialog<EntityModel, QueryModel>(data: OrderItemsServerSearchTableContainerDialog<EntityModel, QueryModel>): Observable<EntityModel[]> {
    const entitySelectedObservables = new Subject<EntityModel[]>();
    data.entitySelectedObservables = entitySelectedObservables;

    const dialogRef = this.dialog.open(OrderItemsServerSearchTableComponent, {
      width: '800px',
      data: data
    });

    if (data.closeOnSelect)
      entitySelectedObservables.subscribe(result => dialogRef.close());

    return entitySelectedObservables;
  }
  
}
