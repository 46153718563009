import { NgModule } from '@angular/core';
import { CommonAppointmentFormModule } from 'src/app/shared/common-appointment-form/common-appointment-form.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AppointmentDispatchComponent } from '../appointment-dispatch/appointment-dispatch.component';
import { AppointmentLocationTypeIconComponent } from '../appointment-location-type-icon/appointment-location-type-icon.component';
import { AppointmentLocationTypeIconModule } from '../appointment-location-type-icon/appointment-location-type-icon.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [AppointmentDispatchComponent],
    imports: [CommonAppointmentFormModule, CommonFormsModule, AppointmentLocationTypeIconModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    exports: [AppointmentDispatchComponent],
})
export class AppointmentDispatchModule {}
