import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { CircuitOrderBatchMoveDialogComponent } from './circuit-order-batch-move-dialog.component';



@NgModule({
  declarations: [
    CircuitOrderBatchMoveDialogComponent
  ],
  imports: [
    CommonFormsModule, MaterialModule
  ],
  exports: [
    CircuitOrderBatchMoveDialogComponent
  ]
})
export class CircuitOrderBatchMoveDialogModule { }
