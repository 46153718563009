import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixedFormControlsComponent } from './fixed-form-controls.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from 'src/app/material.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MaterialModule, NgxPermissionsModule, MatTooltipModule, TranslateModule],
    declarations: [FixedFormControlsComponent],
    exports: [FixedFormControlsComponent],
})
export class FixedFormControlsModule {}
