import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { CancelServiceTicketCommand, ChangeServiceTicketStatusCommand, ServiceTicketLookupDto, ServiceTicketsService, ServiceTicketStatus } from 'src/app/services/api.services';
import { EnumListService, EnumWrap } from 'src/app/services/enum-list.service';
import { NotifyService } from 'src/app/services/notify.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { CommonComponent } from 'src/app/models/formComponent';

@Component({
    selector: 'app-change-service-ticket-status-dialog',
    templateUrl: './change-service-ticket-status-dialog.component.html',
    styleUrls: ['./change-service-ticket-status-dialog.component.scss'],
})
export class ChangeServiceTicketStatusDialogComponent extends CommonComponent implements OnInit {
    statuses: EnumWrap[];
    command: ChangeServiceTicketStatusCommand | CancelServiceTicketCommand;
    formGroup: FormGroup;
    isLoading: boolean = false;
    onlyCancel: boolean;
    titleKey = 'SERVICETICKETS.CHANGESTATUS';

    constructor(
        private dialogRef: MatDialogRef<ChangeServiceTicketStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServiceTicketLookupDto,
        private enumListService: EnumListService,
        private formBuilder: FormBuilder,
        public serviceTicketsService: ServiceTicketsService,
        private notifyService: NotifyService,
        private permissionService: NgxPermissionsService
    ) {
        super();
        this.statuses = this.enumListService.serviceTicketStatuses;
        this.command = new ChangeServiceTicketStatusCommand();
        this.command.id = data.id;
        this.command.status = data.status;

        this.setupFormControl();
    }

    ngOnInit(): void {
        if (!this.permissionService.getPermission('ChangeServiceTicketsStatus') && this.permissionService.getPermission('CancelServiceTicket')) {
            this.onlyCancel = true;
            this.titleKey = 'SERVICETICKETS.CANCEL';
            this.command = this.command as CancelServiceTicketCommand;
        }
    }

    setupFormControl() {
        this.formGroup = this.formBuilder.group({
            id: [this.command.id, [Validators.required]],
            comment: [this.command.comment],
            status: [this.command.status, [Validators.required]],
        });
        this._setupCommentValidator(this.command.status);

        this.formGroup.get('status').valueChanges.subscribe((status) => this._setupCommentValidator(status));
    }

    private _setupCommentValidator(status) {
        const requiredStatusses = [ServiceTicketStatus.Resolved, ServiceTicketStatus.Canceled, ServiceTicketStatus.Expired, ServiceTicketStatus.WontFix];
        if (requiredStatusses.some((x) => x == status)) this.formGroup.get('comment').setValidators(Validators.required);
        else this.formGroup.get('comment').clearValidators();

        this.formGroup.get('comment').updateValueAndValidity();
    }

    changeStatus() {
        this.isLoading = true;
        if (this.formGroup.invalid) {
            this.isLoading = false;
            this.validateAllFormFields(this.formGroup);
            return;
        }
        this.command.init(this.formGroup.value);
        const observable = this.onlyCancel ? this.serviceTicketsService.cancelTicket(this.command) : this.serviceTicketsService.changeStatus(this.command);
        observable.subscribe(
            () => {
                this.notifyService.success('Status successfully changed');
                this.dialogRef.close(new DialogResult(DialogResultActions.Edit, this.command.status));
            },
            () => {
                this.notifyService.fail("Couldn't change status");
                this.dialogRef.close();
            },
            () => (this.isLoading = false)
        );
    }
}
