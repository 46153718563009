import { Component, OnInit, Inject } from '@angular/core';
import { ServerPaginationListComponent } from 'src/app/models/serverPaginationComponent';
import { OrderItemsServerSearchTableContainerDialog } from '../models/searchTableContainer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PagingDataSource } from 'src/app/models/interfaces/dataSourceInterface';
import { Observable } from 'rxjs';
import { PaginationModel, ProductLookupDto } from 'src/app/services/api.services';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-order-items-server-search-table',
    templateUrl: './order-items-server-search-table.component.html',
    styleUrls: ['./order-items-server-search-table.component.scss'],
})
export class OrderItemsServerSearchTableComponent<IdType, ModelType, QueryType> extends ServerPaginationListComponent<IdType, ModelType, QueryType> implements OnInit {
    displayedColumns: string[];
    additions: ProductLookupDto[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: OrderItemsServerSearchTableContainerDialog<ModelType, QueryType>,
        private dialogRef: MatDialogRef<OrderItemsServerSearchTableComponent<IdType, ModelType, QueryType>>,
        notifyService: NotifyService,
        confirmDialogService: ConfirmDialogService
    ) {
        super(null, settings.entityName, notifyService, confirmDialogService);

        this.displayedColumns = this.settings.displayProperties.map((displayProperty) =>
            displayProperty.isInTranslation ? `selectedTranslation.${displayProperty.propertyName}` : displayProperty.propertyName
        );
        this.displayedColumns.push('action');

        this.query = settings.query;
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel());
    }

    loadDataSource(): PagingDataSource<ModelType, QueryType> {
        return this.settings.server;
    }

    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel);
        this.dataSource.loadEntities(query);
    }

    deleteEntity(): Observable<void> {
        throw new Error('Method not implemented.');
    }

    select(entity: any) {
        this.additions = [...this.additions, entity];
    }

    complete() {
        this.settings.entitySelectedObservables.next(this.additions);
        this.dialogRef.close();
    }

    ngOnInit() {
        this.load();
    }

    shouldBeDisabled(product: ProductLookupDto): boolean {
        return this.settings.excludeList.some((excludedProduct) => product.productNo == excludedProduct.product.productNo);
    }
}
