import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-add-phone-numbers-dialog',
  templateUrl: './add-phone-numbers-dialog.component.html',
  styleUrls: ['./add-phone-numbers-dialog.component.scss']
})
export class AddPhoneNumbersDialogComponent {

  phoneNumbers: string[] = []

  @ViewChild('numbersInput') numbersInput: ElementRef

  constructor(
    private dialogRef: MatDialogRef<AddPhoneNumbersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.phoneNumbers?.length > 0) {
      this.phoneNumbers = data.phoneNumbers
    }
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.phoneNumbers.indexOf(value) === -1) {
      this.phoneNumbers.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(number: string): void {
    const index = this.phoneNumbers.indexOf(number);

    if (index >= 0) {
      this.phoneNumbers.splice(index, 1);
    }
  }

  onInput(value: string): void {
    // Check if we have 10 digits
    if (value.length === 10) {
      // Add the phone number to the list
      this.phoneNumbers.push(value);

      // Clear the input field
      this.numbersInput.nativeElement.value = '';
    }
  }

  save() {
    this.dialogRef.close(this.phoneNumbers)
  }

  cancel() {
    this.dialogRef.close()
  }
}
