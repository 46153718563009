import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonComponent } from 'src/app/models/formComponent';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductReorderMenuPlacementDto, CategoryDto, ProductsService, ReorderProductsCommand } from 'src/app/services/api.services';
import { CategoriesCacheService } from 'src/app/services/categories-cache.service';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-product-reorder-dialog',
    templateUrl: './product-reorder-dialog.component.html',
    styleUrls: ['./product-reorder-dialog.component.scss'],
})
export class ProductReorderDialogComponent extends CommonComponent implements OnInit {
    displayedColumns: string[] = ['selected', 'menuName', 'categoryName', 'productOrder'];
    selection = new SelectionModel<ProductReorderMenuPlacementDto>(true, []);
    categories: CategoryDto[] = [];
    loading: boolean = false;
    objectToCopy: ProductReorderMenuPlacementDto;

    get onlyOneSelected(): boolean {
        return this.selection.selected.length == 1;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ProductReorderDialogComponent>,
        private categoriesCacheService: CategoriesCacheService,
        private productsService: ProductsService,
        private notifyService: NotifyService,
        private translateService: TranslateService
    ) {
        super();

        this.categoriesCacheService.categoriesList().subscribe((categories) => {
            this.categories = categories;
        });
    }

    ngOnInit() {}

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.productReorderMenuPlacements.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.data.productReorderMenuPlacements.forEach((row) => this.selection.select(row));
    }

    updateProductOrder() {
        this.loading = true;

        const command = new ReorderProductsCommand();
        command.productReorderMenuPlacements = this.selection.selected;
        this.productsService.reorderProducts(command).subscribe(
            (result) => {
                this.loading = false;
                this.notifyService.success('PRODUCT.REORDERSUCCESS');
                this.dialogRef.close();
            },
            (error) => {
                this.loading = false;
                this.notifyService.fail('PRODUCT.REORDERFAIL');
            }
        );
    }

    copyRow() {
        if (!this.onlyOneSelected) return;
        this.objectToCopy = this.selection.selected[0];

        if (!this.displayedColumns.includes('Action')) this.displayedColumns.push('Action');
    }

    pasteToRow(row: ProductReorderMenuPlacementDto) {
        row.categoryId = this.objectToCopy.categoryId;
        row.menuCategoryId = this.objectToCopy.menuCategoryId;
        row.productOrderIndex = this.objectToCopy.productOrderIndex;
        this.selectRow(row);
    }

    selectRow(row: any) {
        this.selection.select(row);
    }
}
