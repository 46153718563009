import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BlanketOrder } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data';
import { DialogResult } from 'src/app/models/dialogResult';
import { BlanketOrderModificationPreviewDialogComponent } from './blanket-order-modification-preview-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class BlanketOrderModificationPreviewDialogService {
    constructor(private dialog: MatDialog) { }

    showDialog(
        originalBlanketOrder: BlanketOrder,
        modifiedBlanketOrder: BlanketOrder
    ): Observable<DialogResult<boolean>> {
        const dialogRef = this.dialog.open(BlanketOrderModificationPreviewDialogComponent, {
            width: '60vw',
            maxHeight: '80vh',
            autoFocus: "false",
            data: {
                originalBlanketOrder: originalBlanketOrder,
                modifiedBlanketOrder: modifiedBlanketOrder,
            },
        });

        return dialogRef.afterClosed();
    }
}