import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPhoneNumbersDialogComponent } from './add-phone-numbers-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ],
  declarations: [
    AddPhoneNumbersDialogComponent
  ],
  exports: [AddPhoneNumbersDialogComponent]
})
export class AddPhoneNumbersDialogModule { }
