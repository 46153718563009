import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonComponent } from 'src/app/models/formComponent';
import { NotifyService } from 'src/app/services/notify.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumListService } from 'src/app/services/enum-list.service';
import { ReplaySubject } from 'rxjs';
import { HttpEventType, HttpRequest, HttpClient } from '@angular/common/http';
import { MediaUploadProgress } from 'src/app/services/media-upload.service';
import { FileUploadSetting } from './file-upload-setting';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup;
    files: File[];
    isLoading: boolean;
    submitted: boolean;

    @ViewChild('file') file;
    onFilesAdded() {
        this.files = this.file.nativeElement.files;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: FileUploadSetting,
        private dialogRef: MatDialogRef<FileUploadDialogComponent>,
        public enumListService: EnumListService,
        private notifyService: NotifyService,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this._setupformControl();
    }

    onSubmitClick(): void {
        this.submitted = true;
        this._generateFromForm();

        if (this.formGroup.valid) {
            this.isLoading = true;
            const file = this.files[0];
            const formData = new FormData();
            formData.append(file.name, file);

            const uploadReq = new HttpRequest('POST', this.settings.url, formData, {
                reportProgress: true,
            });

            const subject = new ReplaySubject<MediaUploadProgress>();

            this.http.request(uploadReq).subscribe(
                (event) => {
                    this.submitted = false;
                    const progress = new MediaUploadProgress();
                    if (event.type === HttpEventType.UploadProgress) {
                        progress.progressPercent = Math.round((100 * event.loaded) / event.total);
                        subject.next(progress);
                    } else if (event.type === HttpEventType.Response) {
                        this.isLoading = false;
                        subject.next(progress);
                        subject.complete();
                        this.notifyService.success(this.translate.instant('FILEUPLOAD.PROCESSING'));
                        this.dialogRef.close();
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.submitted = false;
                    this.notifyService.fail(this.translate.instant('FILEUPLOAD.FAILED'));
                }
            );
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    private _generateFromForm() {}

    private _setupformControl() {
        this.formGroup = this.formBuilder.group({
            formFile: ['', [Validators.required]],
        });
    }
}
