import { Component, OnInit } from '@angular/core';
import { AccountService } from './services/api.services';
import { AccountManageService } from './services/accountManage.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    loading: boolean = false;

    constructor(private accountService: AccountService, private accountManagerService: AccountManageService, translate: TranslateService, private router: Router) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('fr');
        moment.locale('fr');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        //  translate.use('fr');

        this.router.events.subscribe((event: any) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngOnInit() {
        this.accountService.isCookieValid().subscribe(
            (user) => {
                this.accountManagerService.refreshUser(user);
            },
            (err) => {
                if (err.status === 401) {
                    this.accountService.logout();
                }
            }
        );
    }
}
