import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-button-list-dialog',
  templateUrl: './button-list-dialog.component.html',
  styleUrls: ['./button-list-dialog.component.scss']
})
export class ButtonListDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef : MatDialogRef<ButtonListDialogComponent>) { }

  ngOnInit() {
  }

  closeModalAndClickButtonModal(buttonClicked: Subject<void>) {
    this.dialogRef.close();
    buttonClicked.next();
  }

}
