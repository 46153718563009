import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ServiceTicketNoteDto, LeadNoteType } from 'src/app/services/api.services';
import { DialogResult } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { ServiceTicketNoteFormDialogComponent } from './service-ticket-note-form-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ServiceTicketNoteFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: NoteFormDialogSettings<ServiceTicketNoteDto>): Observable<DialogResult<ServiceTicketNoteDto>> {
        const dialogRef = this.dialog.open(ServiceTicketNoteFormDialogComponent, {
            width: '800px',
            data: setting,
        });

        return dialogRef.afterClosed();
    }
}
