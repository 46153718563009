import { Type } from '@angular/core';
import { ClientNextDeliveryTaskNoteType, LeadNoteType } from 'src/app/services/api.services';
import { Moment } from 'moment';

export class NoteFormDialogSettings<TDto> {
    model: TDto;
    isCallback: boolean = false;
    isQuickFollowup: boolean = false;
    allowNoteTypeSelection = true;
    requiredNoteTypeSelection: LeadNoteType | ClientNextDeliveryTaskNoteType;
    allowClientLeadIdSelection = false;
    entityId: any;
    isEdit: boolean;
    startOnDateTime: Moment;
    allowDelete = false;
    showClientLead = false;
    modelType: Type<TDto>;
    payload: any;
}
