import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AppointmentDispatchedRecordDto, SalesRepAppointmentDto, AppointmentDispatchType, SalesRepDto } from 'src/app/services/api.services';
import { MatTableDataSource } from '@angular/material/table';
import { EnumListService } from 'src/app/services/enum-list.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-appointment-dispatch',
    templateUrl: './appointment-dispatch.component.html',
    styleUrls: ['./appointment-dispatch.component.scss'],
})
export class AppointmentDispatchComponent implements OnInit {
    showDispatchLog: boolean;
    dataSource: MatTableDataSource<AppointmentDispatchedRecordDto> = new MatTableDataSource([]);
    displayedColumns: string[] = ['dispatchType', 'performedByEmployeeName', 'confirmedSalesRepName', 'performedOnDateTime', 'isRemoteOrder'];
    doesSelectedRepHaveAccessToTablet: boolean;
    appointmentLocationType = new FormControl(null, Validators.required);

    @Input('appointmentDispatchedRecords') set appointmentDispatchedRecords(records: AppointmentDispatchedRecordDto[]) {
        this.dataSource = new MatTableDataSource(records != undefined ? records.sort((d1, d2) => d2.performedOnDateTime.diff(d1.performedOnDateTime)) : []);
    }

    @Input() dispatchSalesReps: SalesRepDto[];

    @Input() canDispatchToRep: boolean;
    @Input() isDispatched = false;

    @Output() dispatchUpdateClick = new EventEmitter<{ appointmentDispatchType: AppointmentDispatchType; isRemoteOrder: boolean | null }>();

    constructor(private enumListService: EnumListService, private permissionsService: NgxPermissionsService) {
        const rawUser = localStorage.getItem('user');

        if (rawUser !== null) {
            const user = JSON.parse(rawUser);
            if (user.assignedRoles) {
                this.permissionsService.loadPermissions(user.assignedPolicies);
            }
        }

        this.showDispatchLog = this.permissionsService.getPermission('ViewDispatchLog') ? true : false;
    }

    ngOnInit() {}

    getDispatchDisplayName(value: string): string {
        return this.enumListService.findDisplayName(this.enumListService.appointmentDispatchType, value);
    }

    onDispatchAppointmentClick() {
        if (this.locationTypeIsSet()) {
            this.dispatchUpdateClick.emit({ appointmentDispatchType: AppointmentDispatchType.Dispatch, isRemoteOrder: this.appointmentLocationType.value == 'virtual' });
        } else if (!this.locationTypeIsSet()) {
            this.appointmentLocationType.markAsTouched();
        }
    }

    onUpdatedDispatchedAppointmentClick() {
        if (this.locationTypeIsSet()) {
            this.dispatchUpdateClick.emit({ appointmentDispatchType: AppointmentDispatchType.UpdateDispatched, isRemoteOrder: this.appointmentLocationType.value == 'virtual' });
        } else if (!this.locationTypeIsSet()) {
            this.appointmentLocationType.markAsTouched();
        }
    }

    onRecallDispatchedAppointmentClick() {
        this.dispatchUpdateClick.emit({ appointmentDispatchType: AppointmentDispatchType.RecallDispatched, isRemoteOrder: null });
    }

    locationTypeIsSet() {
        return this.appointmentLocationType.value != null;
    }
}
