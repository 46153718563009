import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientPortalLayoutComponent } from './client-portal-layout.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [CommonModule, RouterModule, MaterialModule],
    declarations: [ClientPortalLayoutComponent],
    exports: [ClientPortalLayoutComponent],
})
export class ClientPortalModule {}
