import { Injectable } from '@angular/core';
import { ImportReferencesDialogComponent } from './import-references-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogResult } from 'src/app/models/dialogResult';

@Injectable({
    providedIn: 'root',
})
export class ImportReferencesDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(): Observable<DialogResult<boolean>> {
        const dialogRef = this.dialog.open(ImportReferencesDialogComponent, {
            width: '800px',
        });

        return dialogRef.afterClosed();
    }
}
