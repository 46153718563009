import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonComponent } from 'src/app/models/formComponent';
import { CategoryDto } from 'src/app/services/api.services';
import { NotifyService } from 'src/app/services/notify.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-category-selector-dialog',
    templateUrl: './category-selector-dialog.component.html',
    styleUrls: ['./category-selector-dialog.component.scss'],
})
export class CategorySelectorDialogComponent extends CommonComponent implements OnInit {
    categorySelectorFormControl = new FormControl();
    options: CategoryDto[];
    filteredOptions: Observable<CategoryDto[]>;
    selectedCategory: CategoryDto;

    constructor(
        public dialogRef: MatDialogRef<CategorySelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Array<CategoryDto>,
        private translateService: TranslateService,
        private notifyService: NotifyService
    ) {
        super();
        this.options = data;
    }

    ngOnInit() {
        this.filteredOptions = this.categorySelectorFormControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );
    }

    categorySelected(category: CategoryDto) {
        this.selectedCategory = category;
    }

    displayProperty(value: CategoryDto) {
        if (value) {
            return value.name;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private _filter(value: any): CategoryDto[] {
        const filterValue = (value.name || value).toLowerCase();

        return this.options.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
}
