import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NoteWrapper } from 'src/app/admin-pages/client-leads/client-lead-form/client-lead-notes/client-lead-notes.component';
import { DialogResultActions } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { CallbackClientLeadNoteDto, CallbackService, ClientLeadNoteDto, LeadNoteType } from 'src/app/services/api.services';
import { EnumListService } from 'src/app/services/enum-list.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { CallbackNoteFormDialogService } from 'src/app/shared/dialog/callback-note-form-dialog/callback-note-form-dialog.service';
import { FixedFormControlsService } from 'src/app/shared/fixed-form-controls/fixed-form-controls.service';
import { of } from 'rxjs';
import { finalize, flatMap, map } from 'rxjs/operators';
import { DataSharingService } from '../data-sharing.service';

@Component({
    selector: 'app-callback-notes',
    templateUrl: './callback-notes.component.html',
    styleUrls: ['./callback-notes.component.scss'],
})
export class CallbackNotesComponent implements OnInit, OnDestroy {
    fixedFormSub: any;
    callbackClientLeadNotes: CallbackClientLeadNoteDto[];

    isLoading = true;

    ratings = [1, 2, 3, 4, 5];

    noteWrapperTypes = {};

    @Input() callbackClientLeadId: number;
    @Input() hideFixedButton: boolean;
    @Input() allowModify: boolean = true;

    constructor(
        private fixedFormControlsService: FixedFormControlsService,
        private noteFormDialogService: CallbackNoteFormDialogService,
        private route: ActivatedRoute,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private callbackService: CallbackService,
        private dataSharingService: DataSharingService,
        private translateService: TranslateService,
        private enumListService: EnumListService
    ) {}

    getTitle(note: CallbackClientLeadNoteDto) {
        let retValue = note.subject;
        let displayName;

        if (!retValue && note.notInterestedReason) {
            displayName = this.enumListService.findDisplayName(this.enumListService.callbackNotInterestedReasons, note.notInterestedReason);
            retValue = this.translateService.instant(displayName);
        } else if (!retValue && note.callbackUnbookableReason) {
            displayName = this.enumListService.findDisplayName(this.enumListService.callbackUnbookableReasons, note.callbackUnbookableReason);
            retValue = this.translateService.instant(displayName);
        }

        return retValue;
    }

    ngOnInit() {
        if (!this.hideFixedButton) {
            const fixedAddButton = this.fixedFormControlsService.setupSingleAddButton();
            this.fixedFormSub = fixedAddButton.buttonClickedObservable.subscribe(() => {
                this.addNote();
            });
        }

        if (!this.callbackClientLeadId) {
            this.callbackClientLeadId = this.route.parent.snapshot.data['callbackClientLead'].id;
        }

        this.noteWrapperTypes[LeadNoteType.Comment] = new NoteWrapper('comment', 'success', 'left');
        this.noteWrapperTypes[LeadNoteType.FollowUp] = new NoteWrapper('alarm_on', 'danger', 'right');
        this.noteWrapperTypes[LeadNoteType.NotInterested] = new NoteWrapper('not_interested', 'warning', 'left');
        this.noteWrapperTypes[LeadNoteType.Unbookable] = new NoteWrapper('unbookable', 'danger', 'left');

        this.callbackService.callbackClientLeadNotes(this.callbackClientLeadId).subscribe(
            (callbackNotes) => {
                this.callbackClientLeadNotes = callbackNotes;
                this.isLoading = false;
            },
            (error) => {
                console.log(error);
                this.isLoading = false;
            }
        );
    }

    addNote() {
        const dialogSettings = new NoteFormDialogSettings<CallbackClientLeadNoteDto>();
        dialogSettings.entityId = this.callbackClientLeadId;
        dialogSettings.isCallback = true;

        this.noteFormDialogService
            .showCallbackDialog(dialogSettings)
            .pipe(finalize(() => this.dataSharingService.raiseMessage('lastModifiedCallback', this.callbackClientLeadId.toString())))
            .subscribe(
                (callbackClientLeadNote) => {
                    if (!callbackClientLeadNote) {
                        return;
                    }

                    if (callbackClientLeadNote.action === DialogResultActions.Create) {
                        this.callbackClientLeadNotes.unshift(callbackClientLeadNote.entity);
                    }
                },
                (error) => {
                    this.notifyService.fail('Failed to add note to this callback lead');
                    console.log(error);
                }
            );
    }

    editNote(callbackClientLeadNoteViewModel: CallbackClientLeadNoteDto) {
        const dialogSettings = new NoteFormDialogSettings<CallbackClientLeadNoteDto>();
        dialogSettings.isCallback = true;
        dialogSettings.entityId = this.callbackClientLeadId;
        dialogSettings.isEdit = true;
        dialogSettings.model = CallbackClientLeadNoteDto.fromJS(callbackClientLeadNoteViewModel);
        dialogSettings.allowNoteTypeSelection = false;
        this.noteFormDialogService
            .showDialog(dialogSettings)
            .pipe(finalize(() => this.dataSharingService.raiseMessage('lastModifiedCallback', this.callbackClientLeadId.toString())))
            .subscribe(
                (dialogResult) => {
                    if (!dialogResult) {
                        return;
                    }

                    const callbackClientLeadNoteIndex = this.callbackClientLeadNotes.findIndex((t) => t.id === callbackClientLeadNoteViewModel.id);
                    if (dialogResult.action === DialogResultActions.Edit) {
                        this.callbackClientLeadNotes[callbackClientLeadNoteIndex] = dialogResult.entity;
                    } else if (dialogResult.action === DialogResultActions.Delete) {
                        this.callbackClientLeadNotes.splice(callbackClientLeadNoteIndex, 1);
                    }
                },
                (error) => {
                    this.notifyService.fail('Failed to edit note');
                    console.log(error);
                }
            );
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe();
        }
    }

    deleteNote(callbackClientLeadNoteViewModel: CallbackClientLeadNoteDto) {
        this.confirmDialogService
            .showDialog('CONFIRMDELETE', 'DELETE')
            .pipe(
                finalize(() => this.dataSharingService.raiseMessage('lastModifiedCallback', this.callbackClientLeadId.toString())),
                flatMap((result) => {
                    if (result) {
                        return this.callbackService.deleteCallbackClientLeadNote(callbackClientLeadNoteViewModel.id).pipe(
                            map(
                                (p) => {
                                    this.callbackClientLeadNotes.splice(
                                        this.callbackClientLeadNotes.findIndex((c) => c.id === callbackClientLeadNoteViewModel.id),
                                        1
                                    );
                                    this.notifyService.success('Note has been deleted from client lead');
                                },
                                (error) => {
                                    this.notifyService.fail('Failed to delete note from client lead');
                                }
                            )
                        );
                    } else {
                        return of();
                    }
                })
            )
            .subscribe(() => {});
    }
}
