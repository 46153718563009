import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServerPagingTableContainerComponent } from './server-paging-table-container.component';
import { MaterialModule } from '../../material.module';
import { FixedFormControlsModule } from '../fixed-form-controls/fixed-form-controls.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './PaginatorI18n';

@NgModule({
    imports: [CommonModule, MaterialModule, FixedFormControlsModule, PipesModule, TranslateModule],
    providers: [
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translate: TranslateService) => {
                const service = new PaginatorI18n();
                service.injectTranslateService(translate);
                return service;
            },
        },
    ],
    declarations: [ServerPagingTableContainerComponent],
    exports: [ServerPagingTableContainerComponent],
})
export class ServerPagingTableContainerModule {}
