import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CallbackNotesDialogComponent } from './callback-notes-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CallbackNotesDialogService {
  constructor(private dialog: MatDialog) { }

  showDialog(callbackClientLeadId: number) {
    const dialogRef = this.dialog.open(CallbackNotesDialogComponent, {
      width: '1500px',
      data: {
        callbackClientLeadId: callbackClientLeadId
      }
    });
    
    return dialogRef.afterClosed();
  }
}
