import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CurrencyComponent } from './currency/currency.component';
import { PercentComponent } from './percent/percent.component';
import { HoursComponent } from './hours/hours.component';
import { QuantityComponent } from './quantity/quantity.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        PhoneNumberComponent,
        CurrencyComponent,
        PercentComponent,
        HoursComponent,
        QuantityComponent
    ],
    exports: [
        PhoneNumberComponent,
        CurrencyComponent,
        PercentComponent,
        HoursComponent,
        QuantityComponent
    ],
    providers: [provideNgxMask()]
})
export class InputsModule { }
