import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BlanketOrder, InventoryItemReturnOrReplacement, SalesOrderInventoryItem, SalesOrderStatus } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data';

@Component({
  selector: 'app-blanket-order-initiate-return-dialog',
  templateUrl: './blanket-order-initiate-return-dialog.component.html',
  styleUrl: './blanket-order-initiate-return-dialog.component.scss'
})
export class BlanketOrderInitiateReturnDialogComponent implements OnInit {
  selectedReason: SelectedReason | '' = ''
  otherReasonText: string = ''
  returnQty: number = 0
  returnOption: 'replace' | 'reduce' = 'replace'

  blanketOrder: BlanketOrder
  blanketLineItem: SalesOrderInventoryItem

  deliveredQty: number = 0

  form!: FormGroup

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<BlanketOrderInitiateReturnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      blanketOrder: BlanketOrder,
      blanketLineItem: SalesOrderInventoryItem
    }
  ) {
    this.blanketOrder = data.blanketOrder
    this.blanketLineItem = data.blanketLineItem
  }

  ngOnInit(): void {
    // Order matters here. We need to set up the delivered qty first before setting up the form
    this.setUpDeliveredQty()
    this.setUpForm()
  }

  setUpDeliveredQty() {
    // Find the delivered qty by going through the blanket order's sales orders,
    // and find the sales orders that are in-delivery
    // then sum up the delivered qty of the line item
    const totalDeliveredQty = this.blanketOrder.salesOrders
      .filter(so => so.status !== SalesOrderStatus.OnHold)
      .map(so => so.inventoryItems
        .find(i => i.itemNo === this.blanketLineItem.itemNo
          && i.returnOrReplacement !== InventoryItemReturnOrReplacement.Return)?.quantity || 0
      )
      .reduce((acc, val) => acc + val, 0)

    console.log('totalDeliveredQty: ', totalDeliveredQty)

    const totalReturnedQty = this.blanketOrder.salesOrders
      .map(so => so.inventoryItems
        .find(i => i.itemNo === this.blanketLineItem.itemNo
          && i.returnOrReplacement === InventoryItemReturnOrReplacement.Return)?.quantity || 0
      )
      .reduce((acc, val) => acc + val, 0)

    console.log('totalReturnedQty: ', totalReturnedQty)

    this.deliveredQty = totalDeliveredQty - totalReturnedQty
  }

  setUpForm() {
    this.form = this.fb.group({
      selectReasonStep: this.fb.group({
        reason: ['', Validators.required],
        other: ['']
      }),
      selectOptionStep: this.fb.group({
        option: ['replace', Validators.required]
      }),
      selectQtyStep: this.fb.group({
        qty: [0, [Validators.required, Validators.min(0), Validators.max(this.deliveredQty)]]
      }),
      confirmStep: this.fb.group({})
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  initiateReturn() {
    if (!this.form.valid) {
      return
    }

    const selectReasonStep = this.form.get('selectReasonStep');
    const selectOptionStep = this.form.get('selectOptionStep');
    const selectQtyStep = this.form.get('selectQtyStep');

    const selectedReason = selectReasonStep?.get('reason')?.value;
    const otherReasonText = selectReasonStep?.get('other')?.value;
    const returnQty = selectQtyStep?.get('qty')?.value;
    const returnOption = selectOptionStep?.get('option')?.value;

    if (!returnQty) {
      this.cancel()
    }

    this.dialogRef.close({
      blanketOrderNumber: this.blanketOrder.blanketOrderNumber,
      itemNo: this.blanketLineItem.itemNo,
      displayName: this.blanketLineItem.displayName,
      selectedReason: this.convertReturnReasonTextToERPValue(selectedReason),
      otherReasonText: selectedReason === SelectedReason.Other ? otherReasonText : undefined,
      returnQty: returnQty,
      returnOption: returnOption
    } as ReturnInformation);
  }

  convertReturnReasonTextToERPValue(reason: SelectedReason | ''): string {
    switch (reason) {
      case SelectedReason.Thawed:
        return 'Dégelé';
      case SelectedReason.FrozenGrocery:
        return 'Épicerie Gelée';
      case SelectedReason.ProductError:
        return 'Err. Produit';
      case SelectedReason.SupplierError:
        return 'Err. Fournisseur';
      case SelectedReason.Taste:
        return 'Goût';
      case SelectedReason.Expired:
        return 'Expiré';
      case SelectedReason.NSV:
        return 'N.S.V.';
      case SelectedReason.Packaging:
        return 'Emballage';
      case SelectedReason.Odor:
        return 'Odeur';
      case SelectedReason.TooFatty:
        return 'Trop Gras';
      case SelectedReason.Other:
        return 'Autre';
      default:
        return '';
    }
  }

  getReturnReasonText(reason: SelectedReason | '') {
    switch (reason) {
      case SelectedReason.Thawed:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.THAWED');
      case SelectedReason.FrozenGrocery:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.FROZENGROCERY');
      case SelectedReason.ProductError:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.PRODUCTERROR');
      case SelectedReason.SupplierError:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.SUPPLIERERROR');
      case SelectedReason.Taste:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.TASTE');
      case SelectedReason.Expired:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.EXPIRED');
      case SelectedReason.NSV:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.NSV');
      case SelectedReason.Packaging:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.PACKAGING');
      case SelectedReason.Odor:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.ODOR');
      case SelectedReason.TooFatty:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.TOOFATTY');
      case SelectedReason.Other:
        return this.translate.instant('INIT_RETURN_DIALOG.REASON_OPTIONS.OTHER');
      default:
        return '';
    }
  }

  getReturnOptionText(option: 'replace' | 'reduce') {
    if (option === 'replace') {
      return this.translate.instant('INIT_RETURN_DIALOG.REPLACE')
    } else if (option === 'reduce') {
      return this.translate.instant('INIT_RETURN_DIALOG.REDUCE')
    }

    return ''
  }
}

export enum SelectedReason {
  Thawed = 'Thawed', // Dégelé
  FrozenGrocery = 'FrozenGrocery', // Épicerie Gelée
  ProductError = 'ProductError', // Err. Produit
  SupplierError = 'SupplierError', // Err. Fournisseur
  Taste = 'Taste', // Goût
  Expired = 'Expired', // Expiré
  NSV = 'NSV', // N.S.V.
  Packaging = 'Packaging', // Embelage
  Odor = 'Odor', // Odeur
  TooFatty = 'TooFatty', // Trop Gras
  Other = 'Other' // Additional option for other reasons
}

export interface ReturnInformation {
  blanketOrderNumber: string
  itemNo: string
  displayName: string
  selectedReason: string
  otherReasonText?: string
  returnQty: number
  returnOption: 'replace' | 'reduce'
}