import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScheduledJobsDto, ScheduledJobsService, UpdateScheduledJobCommand } from 'src/app/services/api.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonComponent } from 'src/app/models/formComponent';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-client-next-delivery-task-automation-dialog',
    templateUrl: './client-next-delivery-task-automation-dialog.component.html',
    styleUrls: ['./client-next-delivery-task-automation-dialog.component.scss'],
})
export class ClientNextDeliveryTaskAutomationDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup;
    enable: string;
    loading: boolean = false;
    private _jobName: string = 'ImportWeeklyBatchOfNextDeliveryTasks';

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: any,
        private dialogRef: MatDialogRef<ClientNextDeliveryTaskAutomationDialogComponent>,
        private scheduledJobsService: ScheduledJobsService,
        private formBuilder: FormBuilder,
        private notifyService: NotifyService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.scheduledJobsService.scheduledJob(this._jobName).subscribe(
            (scheduleJob) => {
                this._setupFormControl(scheduleJob);
            },
            () => {},
            () => (this.loading = false)
        );
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const command = new UpdateScheduledJobCommand({
                name: this._jobName,
                enable: this.formGroup.get('enable').value,
            });
            this.loading = true;
            this.formGroup.disable();
            this.scheduledJobsService.update(command).subscribe(
                () => {
                    this.dialogRef.close(new DialogResult(DialogResultActions.Edit, null));
                    this.loading = false;
                },
                (err) => {
                    this.formGroup.enable();
                    this.notifyService.fail('Error when saving automatic sending');
                    this.loading = false;
                    this.dialogRef.close();
                }
            );
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    private _setupFormControl(scheduledJob: ScheduledJobsDto) {
        this.formGroup = this.formBuilder.group({
            enable: [scheduledJob?.enable, [Validators.required]],
        });
    }
}
