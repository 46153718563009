import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../models/myErrorStateMatcher';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() disabled = false;
  @Input() hint: string;

  matcher = new MyErrorStateMatcher();


  constructor() { }

  ngOnInit() {
    if (this.disabled) {
      this.group.get(this.controlName).disable();
    }
  }

}
