import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientLeadDetailsComponent } from './client-lead-details/client-lead-details.component';
import { ClientLeadNotesComponent } from './client-lead-notes/client-lead-notes.component';
import { ClientLeadFormComponent } from './client-lead-form.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AddressFormModule } from 'src/app/shared/address-form/address-form.module';
import { TableContainerModule } from 'src/app/shared/table-container/table-container.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    imports: [CommonFormsModule, AddressFormModule, TableContainerModule, RouterModule, FormsModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    declarations: [ClientLeadDetailsComponent, ClientLeadNotesComponent, ClientLeadFormComponent],
    exports: [ClientLeadDetailsComponent, ClientLeadFormComponent, ClientLeadNotesComponent],
})
export class ClientLeadFormModule { }
