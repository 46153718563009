import { NgModule } from '@angular/core';
import { ClientNextDeliveryTaskAutomationDialogComponent } from './client-next-delivery-task-automation-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [ClientNextDeliveryTaskAutomationDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    exports: [ClientNextDeliveryTaskAutomationDialogComponent],
})
export class ClientNextDeliveryTaskAutomationDialogModule {}
