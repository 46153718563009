import { Router } from '@angular/router';
import { Observable, Subscription, empty, of } from 'rxjs';
import { NgZone, ViewChild, OnInit, Directive } from '@angular/core';
import { TableContainerComponent } from '../shared/table-container/table-container.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { NotifyService } from '../services/notify.service';
import { FixedFormControlsService, ControlMode, FixedButton } from '../shared/fixed-form-controls/fixed-form-controls.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { flatMap, map } from 'rxjs/operators';

@Directive()
export abstract class SimpleListComponent<T, Model> {
    models: Array<Model>;
    isLoaded = false;
    isFailed = false;
    dataSource: MatTableDataSource<Model>;
    retryReloadSub: Subscription;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('tableContainer') tableContainer: TableContainerComponent<Model>;

    constructor(public entityName: string, public notifyService: NotifyService, protected confirmDialogService: ConfirmDialogService) {}

    abstract loadEntities(): Observable<Array<Model>>;

    load() {
        this.isLoaded = false;
        this.isFailed = false;
        this.loadEntities().subscribe(
            (models: Model[]) => {
                this.models = models;
                if (this.tableContainer) {
                    this.tableContainer.dataSource = new MatTableDataSource(models);
                    this.tableContainer.dataSource.sort = this.sort;
                } else {
                    this.dataSource = new MatTableDataSource(models);
                }
                this.isLoaded = true;
                this.isFailed = false;
            },
            (result) => {
                this.isLoaded = true;
                this.isFailed = true;
                this.notifyService.fail(`Failed to load ${this.tableContainer.entityDisplayName}, server error`);

                if (!this.retryReloadSub) {
                    this.retryReloadSub = this.tableContainer.retryReload.subscribe(() => {
                        this.load();
                    });
                }
            }
        );
    }
}

@Directive()
export abstract class ListComponent<T, Model> extends SimpleListComponent<T, Model> implements OnInit {
    fixedFormSub: Subscription;
    fixedAddButton: FixedButton;
    public ngZone: NgZone;

    constructor(
        public router: Router,
        entityName: string,
        notifyService: NotifyService,
        confirmDialogService: ConfirmDialogService,
        public fixedFormControlsService: FixedFormControlsService
    ) {
        super(entityName, notifyService, confirmDialogService);
        this.fixedAddButton = this.fixedFormControlsService.setupSingleAddButton();
        this.fixedFormSub = this.fixedAddButton.buttonClickedObservable.subscribe(() => this.create());
    }

    abstract deleteEntity(id: T): Observable<void>;

    ngOnInit() {}

    create() {
        this.router.navigate([this.entityName + '/create']);
    }

    edit(id: T) {
        this.router.navigate([this.entityName + '/edit/', id]);
    }

    delete(id: T) {
        this.confirmDialogService
            .showDialog('You will not be able to revert this')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        return this.deleteEntity(id).pipe(map(() => true));
                    }
                    return of(false);
                })
            )
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }
                    const entityToRemoveIndex = this.models.findIndex((model: any) => model.id === id);
                    this.models.splice(entityToRemoveIndex, 1);
                    this.tableContainer.dataSource.data = this.models;
                    this.notifyService.success(`${this.entityName} has been deleted`);
                },
                (err) => {
                    this.notifyService.fail(`Failed to delete ${this.entityName}`);
                }
            );
    }
}
