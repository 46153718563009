import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { AppointmentLocationTypeIconComponent } from './appointment-location-type-icon.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [AppointmentLocationTypeIconComponent],
    imports: [CommonFormsModule],
    exports: [AppointmentLocationTypeIconComponent],
})
export class AppointmentLocationTypeIconModule {}
