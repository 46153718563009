import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayImgComponent } from './display-img.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [DisplayImgComponent],
    imports: [CommonModule, CommonFormsModule],
    exports: [DisplayImgComponent],
})
export class DisplayImgModule {}
