import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixedFormControlsModule } from 'src/app/shared/fixed-form-controls/fixed-form-controls.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { InputsModule } from 'src/app/shared/inputs/inputs.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    exports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, FixedFormControlsModule, InputsModule, NgxPermissionsModule, TranslateModule],
    declarations: [],
})
export class CommonFormsModule { }
