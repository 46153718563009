import { FormComponentId, FormComponentSettings } from './formComponent';
import {
    AppointmentDto,
    SalesRepDto,
    AppointmentsService,
    AppointmentType,
    CreateLegacyAppointmentCommand,
    AddressDto,
    ClientsService,
    EditLegacyAppointmentCommand,
    CalculateAppointmentRatingCommand,
    CallBackType,
} from 'src/app/services/api.services';
import { OnDestroy, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormContainerService } from 'src/app/services/form-container.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AppValidators } from './appValidators';
import { debounceTime, flatMap } from 'rxjs/operators';
import { SalesRepManagerService } from 'src/app/services/sales-rep-manager-service.service';

export class HitRepConstants {
    public static HIT_REP_ASSIGNED_SALES_REP_ID = 801;
    public static HIT_REP_ROLE_TEXT = 'HIT';
}

@Directive()
export abstract class BaseAppointmentFormComponent<ModelType extends AppointmentDto> extends FormComponentId<string, ModelType> implements OnDestroy {
    salesReps: SalesRepDto[];
    disableImportButton: boolean = true;
    disableForm: boolean = false;
    rating: number = 0;

    isTelemarketer: boolean = false;
    isHit: boolean = false;

    isCallback = false;
    apptCallbackType?: CallBackType;

    isClientLead = false;

    constructor(
        formComponentSettings: FormComponentSettings,
        protected appointmentsService: AppointmentsService,
        public route: ActivatedRoute,
        formContainerService: FormContainerService,
        public permissionService: NgxPermissionsService,
        private clientsService: ClientsService,
        salesRepManagerService: SalesRepManagerService
    ) {
        super(formComponentSettings, formContainerService);

        const rawUser = localStorage.getItem('user');

        if (rawUser !== null) {
            const user = JSON.parse(rawUser);
            if (user.assignedRoles) {
                this.permissionService.loadPermissions(user.assignedPolicies);
                this.isTelemarketer = user.assignedRoles.includes('Telemarketer');
                this.isHit = user.assignedRoles.includes(HitRepConstants.HIT_REP_ROLE_TEXT);
            }
        }

        salesRepManagerService.salesRepList(false).subscribe((reps) => {
            this.salesReps = reps;
        });
    }

    commonInit() {
        const appointment = this.route.snapshot.data['appointment'];

        if (appointment) {
            this.model = appointment;
            this.model.callNo = appointment.callNo;
            this.routeNo = appointment.id ? appointment.id : appointment.callNo;
        }

        this.setupFormControl();

        this.model.address.province = 'Quebec';
        this.model.address.country = 'Canada';

        if (this.isHit && !this.isCallback) {
            this.model.appointmentType = AppointmentType.New;
        } else if (this.isCallback && this.apptCallbackType != null) {
            this.model.appointmentType = this.apptCallbackType == CallBackType.Winback ? AppointmentType.Winback : AppointmentType.Renew;
        }
    }

    abstract createNewModel(): ModelType;
    abstract editAppointment(command: any): Observable<void>;
    abstract createAppointment(command: any): Observable<string>;

    editEntity(): Observable<void> {
        const appointmentDto = this._generateFromForm();
        const command = new EditLegacyAppointmentCommand();
        command.appointment = appointmentDto;
        return this.editAppointment(command);
    }

    createEntity(): Observable<string> {
        const appointmentDto = this._generateFromForm();
        const command = new CreateLegacyAppointmentCommand();
        command.appointment = appointmentDto;
        return this.createAppointment(command);
    }

    setupFormControl() {
        if (!this.model) {
            this.model = this.createNewModel();
            this.model.buysMeatAtButcher = false;
            this.model.buysMeatAtSupermarket = false;
            this.model.visa = false;
            this.model.mastercard = false;
            this.model.americanExpress = false;
            this.model.usesOtherCreditCard = false;
            this.model.hasNoEmail = false;
            this.model.visitsCostco = false;
            this.model.visitsIga = false;
            this.model.visitsLoblaws = false;
            this.model.visitsMaxi = false;
            this.model.visitsMetro = false;
            this.model.visitsSuperC = false;
            this.model.visitsWalmart = false;
            this.model.visitsOtherSupermarket = false;
            this.model.appelEntrant = false;
            this.model.numberOfPets = null;
        }

        if (!this.model.address) {
            this.model.address = new AddressDto();
        }

        this.formControl = this.formBuilder.group(
            {
                id: [this.model.id],
                callNo: [this.model.callNo],
                gender: [this.model.gender, Validators.required],
                visitsIga: [this.model.visitsIga, []],
                visitsMetro: [this.model.visitsMetro, []],
                visitsMaxi: [this.model.visitsMaxi, []],
                visitsSuperC: [this.model.visitsSuperC, []],
                visitsLoblaws: [this.model.visitsLoblaws, []],
                visitsCostco: [this.model.visitsCostco, []],
                visitsWalmart: [this.model.visitsWalmart, []],
                visitsOtherSupermarket: [this.model.visitsOtherSupermarket, []],
                otherSupermarket: [this.model.otherSupermarket, []],

                onlineIga: [this.model.onlineIga, []],
                onlineMetro: [this.model.onlineMetro, []],
                onlineMaxi: [this.model.onlineMaxi, []],
                onlineWalmart: [this.model.onlineWalmart, []],
                onlineGoodfood: [this.model.onlineGoodfood, []],
                onlineGroceryOther: [this.model.onlineGroceryOther, []],

                ageRange: [this.model.ageRange, [Validators.required]],
                buysMeatAtButcher: [this.model.buysMeatAtButcher, []],
                buysMeatAtSupermarket: [this.model.buysMeatAtSupermarket, []],
                buysMeatAtExotic: [this.model.buysMeatAtExotic, []],
                buysMeatAtOther: [this.model.buysMeatAtOther, []],
                buysMeatAtHalal: [this.model.buysMeatAtHalal, []],
                budgetRange: [this.model.budgetRange, Validators.required],
                numberOfAdults: [this.model.numberOfAdults, Validators.required],
                numberOfKids: [this.model.numberOfKids, Validators.required],
                numberOfPets: [this.model.numberOfPets, Validators.required],
                petTypeDog: [this.model.petTypeDog, []],
                petTypeCat: [this.model.petTypeCat, []],
                petTypeFish: [this.model.petTypeFish, []],
                petTypeBird: [this.model.petTypeBird, []],
                petTypeRodent: [this.model.petTypeRodent, []],
                petTypeReptile: [this.model.petTypeReptile, []],
                petTypeRabbit: [this.model.petTypeRabbit, []],
                petTypeOtherOrExotic: [this.model.petTypeOtherOrExotic, []],
                hasFreezer: [this.model.hasFreezer, Validators.required],
                hasSpace: [this.model.hasSpace, []],
                freezerSize: [this.model.freezerSize, []],
                freezerStockAmount: [this.model.freezerStockAmount, []],
                email: [{ value: this.model.email ? this.model.email.toUpperCase() : null, disabled: this.model.hasNoEmail }, [Validators.required, Validators.email]],
                hasNoEmail: [this.model.hasNoEmail, []],
                languageType: [this.model.languageType, Validators.required],
                firstName: [this.model.firstName ? this.model.firstName.toUpperCase() : null, Validators.required],
                lastName: [this.model.lastName ? this.model.lastName.toUpperCase() : null, Validators.required],
                occupation: [this.model.occupation ? this.model.occupation.toUpperCase() : null, Validators.required],
                conjointFirstName: [this.model.conjointFirstName ? this.model.conjointFirstName.toUpperCase() : null, [this.isConjointFirstNameRequired(this)]],
                conjointLastName: [this.model.conjointLastName ? this.model.conjointLastName.toUpperCase() : null, [this.isConjointLastNameRequired(this)]],
                conjointOccupation: [this.model.conjointOccupation ? this.model.conjointOccupation.toUpperCase() : null, []],
                homePhoneNumber: [this.model.homePhoneNumber, [Validators.required, AppValidators.phone]],
                cellPhoneNumber: [this.model.cellPhoneNumber, [AppValidators.phone]],
                workPhoneNumber: [this.model.workPhoneNumber, [AppValidators.phone]],
                extension: [this.model.extension, []],
                visa: [this.model.visa, []],
                mastercard: [this.model.mastercard, []],
                americanExpress: [this.model.americanExpress, []],
                usesOtherCreditCard: [this.model.usesOtherCreditCard, []],
                otherCreditCard: [this.model.otherCreditCard, []],
                crossStreet: [this.model.crossStreet, Validators.required],
                livingArrangement: [this.model.livingArrangement, Validators.required],
                appointmentDateTime: [this.model.appointmentDateTime?.toDate(), [Validators.required]],
                originalSalesRepId: [
                    {
                        value: this.isHit ? HitRepConstants.HIT_REP_ASSIGNED_SALES_REP_ID : this.model.originalSalesRep ? this.model.originalSalesRep.id : null,
                        disabled: this.isHit,
                    },
                    Validators.required,
                ],
                referredById: [this.model.referredBy ? this.model.referredBy.id : null, []],
                clientNo: [this.model.clientNo, []],
                comments: [this.model.comments ? this.model.comments.toUpperCase() : null, []],
                appointmentType: [this.model.appointmentType, [Validators.required]],
                clientLeadId: [this.model.clientLeadId, []],
                callbackClientLeadId: [this.model.callbackClientLeadId, []],
                isVirtual: [this.model.isVirtual == true ? 'virtual' : this.model.isVirtual == false ? 'physical' : null, [Validators.required]],
                appelEntrant: [this.model.appelEntrant, []],
            },
            {
                validator: Validators.compose([
                    this._supermarketCheckboxValidator(this),
                    this._buysMeatValidator(this),
                    this._creditCardCheckboxValidator(this),
                    this._petSelectionValidator(this),
                    this._petSelectionLimitValidator(this),
                ]),
            }
        );

        if (!this.model.numberOfPets) {
            this._disablePetTypes();
        }

        this.formControl.get('numberOfPets').valueChanges.subscribe((value) => {
            if (!value) {
                this._disablePetTypes();
                this._resetPetTypes();
            } else {
                this._enablePetTypes();
            }
        });

        if (this.disableForm) {
            this.formControl.disable();
        }

        if (this.isTelemarketer || this.isHit || this.isCallback) {
            this.formControl.get('appointmentType').setValidators(null);
        } else {
            this.formControl.get('appointmentType').setValidators([Validators.required]);
        }

        this.formControl.get('appointmentType').valueChanges.subscribe(() => {
            this.formControl.get('appointmentDateTime').updateValueAndValidity();
        });

        if (!this.formControl.get('visitsOtherSupermarket').value) this.formControl.get('otherSupermarket').setValue(null);

        this.formControl.get('visitsOtherSupermarket').valueChanges.subscribe((visitsOtherSupermarket: boolean) => {
            if (visitsOtherSupermarket) {
                this.formControl.get('otherSupermarket').setValidators([Validators.required]);
            } else {
                this.formControl.get('otherSupermarket').setValidators(null);
                this.formControl.get('otherSupermarket').setValue(null);
            }

            this.formControl.get('otherSupermarket').updateValueAndValidity();
        });

        this.formControl.get('usesOtherCreditCard').valueChanges.subscribe((usesOtherCreditCard: boolean) => {
            if (usesOtherCreditCard) {
                this.formControl.get('otherCreditCard').setValidators([Validators.required]);
            } else {
                this.formControl.get('otherCreditCard').clearValidators();
                this.formControl.get('otherCreditCard').setValue(null);
            }

            this.formControl.get('otherCreditCard').updateValueAndValidity();
        });

        this.onHasFreezerChange(this.formControl.get('hasFreezer').value);

        this.formControl.get('hasFreezer').valueChanges.subscribe((hasFreezer: boolean) => {
            this.onHasFreezerChange(hasFreezer);
        });

        this.formControl.get('hasNoEmail').valueChanges.subscribe((hasNoEmail: boolean) => {
            if (hasNoEmail) {
                this._disableInput('email');
            } else {
                this._enableInput('email', [Validators.required, Validators.email]);
            }
        });

        this.formControl
            .get('clientNo')
            .valueChanges.pipe(debounceTime(300))
            .subscribe((clientNo: number) => {
                if (this.formControl.get('clientNo').valid) this.disableImportButton = false;
                else this.disableImportButton = true;
            });

        if (this.permissionService.getPermission('ViewAppointmentRating')) {
            this.formControl.valueChanges
                .pipe(debounceTime(300))
                .pipe(
                    flatMap((result) => {
                        return this._updateAppointmentRating();
                    })
                )
                .subscribe((rating) => {
                    this.rating = rating;
                });
        }
    }

    private onHasFreezerChange(hasFreezer: boolean) {
        if (hasFreezer) {
            this._enableInput('freezerSize', [Validators.required]);
            this._enableInput('freezerStockAmount', [Validators.required]);
            this._disableInput('hasSpace');
        } else {
            this._disableInput('freezerSize');
            this._disableInput('freezerStockAmount');
            this._enableInput('hasSpace', [Validators.required]);
        }
    }

    protected _generateFromForm(): ModelType {
        const appointment = this.createNewModel();
        appointment.init(this.formControl.value);
        this.model = Object.assign(this.model, appointment);

        if (this.isHit && !this.isCallback) {
            appointment.appointmentType = AppointmentType.New;
        } else if (this.isCallback && this.apptCallbackType != null) {
            appointment.appointmentType = this.apptCallbackType == CallBackType.Winback ? AppointmentType.Winback : AppointmentType.Renew;
        }

        if (this.formControl.get('originalSalesRepId').value) {
            appointment.originalSalesRep = new SalesRepDto({
                id: this.formControl.get('originalSalesRepId').value,
            });
        }

        if (this.formControl.get('referredById').value) {
            appointment.referredBy = new SalesRepDto({
                id: this.formControl.get('referredById').value,
            });
        }

        appointment.isVirtual = this.formControl.get('isVirtual').value == 'virtual' ? true : false;

        return appointment;
    }

    private _disablePetTypes() {
        this.enumListService.petTypes.forEach((type) => {
            this.formControl.get(type.value).disable();
        });
    }

    private _resetPetTypes() {
        this.enumListService.petTypes.forEach((type) => {
            this.formControl.get(type.value).setValue(false);
        });
    }

    private _enablePetTypes() {
        this.enumListService.petTypes.forEach((type) => {
            this.formControl.get(type.value).enable();
        });
    }

    private _disableInput(key: string) {
        this.formControl.get(key).setValue(null);
        this.formControl.get(key).disable();
        this.formControl.get(key).clearValidators();
        this.formControl.get(key).updateValueAndValidity();
    }

    private _enableInput(key: string, validators) {
        this.formControl.get(key).enable();
        this.formControl.get(key).setValidators(validators);
        this.formControl.get(key).updateValueAndValidity();
    }

    private _supermarketCheckboxValidator(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (formControl: AbstractControl): { [key: string]: any } => {
            if (ref && !ref.formSubmitAttempt) {
                return null;
            }

            if (
                this.formControl.get('visitsIga').value !== true &&
                this.formControl.get('visitsMetro').value !== true &&
                this.formControl.get('visitsMaxi').value !== true &&
                this.formControl.get('visitsSuperC').value !== true &&
                this.formControl.get('visitsLoblaws').value !== true &&
                this.formControl.get('visitsCostco').value !== true &&
                this.formControl.get('visitsWalmart').value !== true &&
                this.formControl.get('visitsOtherSupermarket').value !== true
            ) {
                return {
                    needsAtLeastOneSupermarketChecked: true,
                };
            }
        };
    }

    private _buysMeatValidator(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (formControl: AbstractControl): { needsAtLeastOnePurchaseChecked: boolean; halalError: boolean } | null => {
            if (ref && !ref.formSubmitAttempt) {
                return null;
            }

            if (this.formControl.get('buysMeatAtButcher').value !== true && this.formControl.get('buysMeatAtSupermarket').value !== true) {
                return { needsAtLeastOnePurchaseChecked: true, halalError: false };
            }

            if (this.formControl.get('buysMeatAtHalal').value == true) {
                return { needsAtLeastOnePurchaseChecked: false, halalError: true };
            }
        };
    }

    private _creditCardCheckboxValidator(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (formControl: AbstractControl): { needsAtLeastOneCreditCardChecked: boolean } | null => {
            if (ref && !ref.formSubmitAttempt) {
                return null;
            }

            if (
                this.formControl.get('visa').value !== true &&
                this.formControl.get('mastercard').value !== true &&
                this.formControl.get('americanExpress').value !== true &&
                this.formControl.get('usesOtherCreditCard').value !== true
            ) {
                return {
                    needsAtLeastOneCreditCardChecked: true,
                };
            }
        };
    }

    private _petSelectionValidator(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (formControl: AbstractControl): { needAtLeastOnePetTypeSelected: boolean } | null => {
            if (ref && !ref.formSubmitAttempt) {
                return null;
            }

            const numberOfPets = formControl.value['numberOfPets'];
            if (!numberOfPets) {
                return null;
            }

            const petTypesSelected = Object.keys(formControl.value)
                .filter((key) => key.startsWith('petType'))
                .some((key) => formControl.value[key]);

            return petTypesSelected ? null : { needAtLeastOnePetTypeSelected: true };
        };
    }

    private _petSelectionLimitValidator(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (formControl: AbstractControl): { petTypesCannotExceedNumberOfPets: boolean } | null => {
            if (ref && !ref.formSubmitAttempt) {
                return null;
            }

            const numberOfPetTypesSelected = Object.keys(formControl.value).filter((key) => key.startsWith('petType') && formControl.value[key]).length;

            return numberOfPetTypesSelected > formControl.value['numberOfPets'] ? { petTypesCannotExceedNumberOfPets: true } : null;
        };
    }

    isConjointFirstNameRequired(ref: BaseAppointmentFormComponent<ModelType>): ValidatorFn {
        return (control: AbstractControl): any => {
            return ref._validateConjointField(control, 'conjointLastName');
        };
    }

    isConjointLastNameRequired(ref: BaseAppointmentFormComponent<ModelType>) {
        return (control: AbstractControl): any => {
            return ref._validateConjointField(control, 'conjointFirstName');
        };
    }

    private _validateConjointField(control: AbstractControl, otherField: string) {
        if (!control.parent) {
            return null;
        }
        const otherControl = control.parent.get(otherField);
        if (otherControl.value !== null && otherControl.value.length > 0 && (control.value === null || control.value.length == 0)) {
            return {
                requiredError: true,
            };
        } else if ((otherControl.value === null || otherControl.value.length === 0) && control.value !== null && control.value.length > 0) {
            otherControl.updateValueAndValidity();
        }

        return null;
    }

    importClientInfo(clientNo: number, notify = true) {
        this.clientsService.clientFromClientNo(clientNo).subscribe({
            next: (clientAndLastRep) => {
                // split full name, last name is last array value, remove it and join the remainder to use as first name
                let full = clientAndLastRep.client.name.split(' ');
                let last = full[full.length - 1];
                full.splice(full.length - 1, 1);
                let first = full.join(' ');

                this.formControl.patchValue({ firstName: first });
                this.formControl.patchValue({ lastName: last });
                this.formControl.patchValue({ languageType: clientAndLastRep.client.languageType });
                this.formControl.patchValue({ gender: clientAndLastRep.client.title });
                this.formControl.patchValue({ homePhoneNumber: clientAndLastRep.client.phoneNumber });
                this.formControl.patchValue({ workPhoneNumber: clientAndLastRep.client.workPhoneNumber });
                this.formControl.patchValue({ cellPhoneNumber: clientAndLastRep.client.cellPhoneNumber });
                this.formControl.patchValue({ extension: clientAndLastRep.client.phoneExtensionNumber });

                if (clientAndLastRep.client.hasNoEmail) {
                    this.formControl.patchValue({ hasNoEmail: true });
                } else {
                    this.formControl.patchValue({ hasNoEmail: false });
                    this.formControl.patchValue({ email: clientAndLastRep.client.personalEmail });
                }

                if (clientAndLastRep.client.address) {
                    this.formControl.get('address').patchValue({ street: clientAndLastRep.client.address.street });
                    this.formControl.get('address').patchValue({ city: clientAndLastRep.client.address.city });
                    this.formControl.get('address').patchValue({ postalCode: clientAndLastRep.client.address.postalCode });
                    clientAndLastRep.client.address.province
                        ? this.formControl.get('address').patchValue({ province: clientAndLastRep.client.address.province })
                        : this.formControl.get('address').patchValue({ province: 'Quebec' });

                    clientAndLastRep.client.address.country
                        ? this.formControl.get('address').patchValue({ country: clientAndLastRep.client.address.country })
                        : this.formControl.get('address').patchValue({ country: 'Canada' });
                }

                if (clientAndLastRep.client.conjoint) {
                    // split full name, last name is last array value, remove it and join the remainder to use as first name
                    let conjointFull = clientAndLastRep.client.conjoint.name.split(' ');
                    let conjointLast = conjointFull[conjointFull.length - 1];
                    conjointFull.splice(conjointFull.length - 1, 1);
                    let conjointFirst = conjointFull.join(' ');

                    this.formControl.patchValue({ conjointFirstName: conjointFirst });
                    this.formControl.patchValue({ conjointLastName: conjointLast });
                }

                // if (clientAndLastRep.lastRep != null) {
                // 	this.formControl.get('originalSalesRepId').setValue(clientAndLastRep.lastRep.id);
                // }

                if (notify) {
                    this.notifyService.success('APPOINTMENT.IMPORTCLIENTINFO.SUCCESS', true);
                }
            },
            error: (err) => {
                this.notifyService.fail('APPOINTMENT.IMPORTCLIENTINFO.FAIL', true);
            }
        });
    }

    isRenew(): boolean {
        return this.formControl.get('appointmentType').value === AppointmentType.Renew;
    }

    isNew(): boolean {
        return this.formControl.get('appointmentType').value === AppointmentType.New;
    }

    _updateAppointmentRating() {
        var command = new CalculateAppointmentRatingCommand();

        command.buysMeatAtButcher = this.formControl.get('buysMeatAtButcher').value;
        command.budgetRange = this.formControl.get('budgetRange').value;
        command.hasFreezer = this.formControl.get('hasFreezer').value;
        command.hasSpace = this.formControl.get('hasSpace').value;
        command.freezerSize = this.formControl.get('freezerSize').value;
        command.freezerStockAmount = this.formControl.get('freezerStockAmount').value;
        command.visa = this.formControl.get('visa').value;
        command.mastercard = this.formControl.get('mastercard').value;
        command.americanExpress = this.formControl.get('americanExpress').value;
        command.numberOfAdults = this.formControl.get('numberOfAdults').value;
        command.numberOfKids = this.formControl.get('numberOfKids').value;
        command.firstName = this.formControl.get('firstName').value;
        command.lastName = this.formControl.get('lastName').value;
        command.conjointFirstName = this.formControl.get('conjointFirstName').value;
        command.conjointLastName = this.formControl.get('conjointLastName').value;
        command.livingArrangement = this.formControl.get('livingArrangement').value;

        command.referredBy = this.formControl.get('referredById').value ? new SalesRepDto({ id: this.formControl.get('referredById').value }) : null;

        return this.appointmentsService.calculateRating(command);
    }
}
