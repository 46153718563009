import { Component, Inject, OnInit, Type } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { CommonComponent } from 'src/app/models/formComponent';
import {
    AddClientNextDeliveryTaskNoteCommand,
    ClientNextDeliveryTaskNoteDto,
    ClientNextDeliveryTaskNoteType,
    ClientNextDeliveryTaskService,
    EditClientNextDeliveryTaskNoteCommand,
    LeadNoteType,
} from 'src/app/services/api.services';
import { EnumListService } from 'src/app/services/enum-list.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-client-next-delivery-note-form-dialog',
    templateUrl: './client-next-delivery-note-form-dialog.component.html',
    styleUrls: ['./client-next-delivery-note-form-dialog.component.scss'],
})
export class ClientNextDeliveryNoteFormDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup;
    model: ClientNextDeliveryTaskNoteDto;

    isBeingSaved = false;

    disabledOptions = [ClientNextDeliveryTaskNoteType.StandBy];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: NoteFormDialogSettings<ClientNextDeliveryTaskNoteDto>,
        private dialogRef: MatDialogRef<ClientNextDeliveryNoteFormDialogComponent>,
        private notifyService: NotifyService,
        private formBuilder: FormBuilder,
        private clientNextDeliveryTaskService: ClientNextDeliveryTaskService,
        public enumListService: EnumListService
    ) {
        super();
    }

    ngOnInit(): void {
        this._setupFormControl();
    }

    onSubmitClick(): void {
        this._generateFromForm();

        if (this.formGroup.valid) {
            this.isBeingSaved = true;

            if (this.settings.isEdit) {
                this.clientNextDeliveryTaskService.editNote(this.model.id, EditClientNextDeliveryTaskNoteCommand.fromJS(this.model)).subscribe(
                    (entity) => {
                        this.dialogRef.close(new DialogResult(DialogResultActions.Edit, entity));
                    },
                    (error) => {
                        this._handleFailure(error);
                    }
                );
            } else {
                this.clientNextDeliveryTaskService.addNote(this.settings.entityId, AddClientNextDeliveryTaskNoteCommand.fromJS(this.model)).subscribe(
                    (entity) => {
                        this.dialogRef.close(new DialogResult(DialogResultActions.Create, entity));
                    },
                    (error) => {
                        this._handleFailure(error);
                    }
                );
            }
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    checkIfDisabled(type: ClientNextDeliveryTaskNoteType) {
        return this.disabledOptions.includes(type);
    }

    private _handleFailure(error: any) {
        console.log(error);
        this.isBeingSaved = false;
        this.notifyService.fail('OPERATIONFAILED', true);
    }

    private _generateFromForm() {
        const oldNoteType = this.model.noteType;

        const callbackClientLeadNote = new this.settings.modelType();
        callbackClientLeadNote.init(this.formGroup.value);
        this.model = Object.assign(this.model, callbackClientLeadNote);

        if (!this.settings.allowNoteTypeSelection) {
            this.model.noteType = this.settings.requiredNoteTypeSelection as ClientNextDeliveryTaskNoteType;
        }
        if (this.model.id) {
            this.model.noteType = oldNoteType;
        }
    }

    private _setupFormControl() {
        if (!this.settings.isEdit) {
            this.model = new this.settings.modelType();
            this.model.noteType = ClientNextDeliveryTaskNoteType.Comment;
        } else {
            this.model = this.settings.model;
        }

        this.formGroup = this.formBuilder.group({
            id: [this.model.id],
            subject: [this.model.subject, [Validators.required]],
            description: [this.model.description],
            noteType: [
                this.settings.allowNoteTypeSelection
                    ? this.model.noteType
                    : {
                        value: this.settings.isEdit ? this.model.noteType : this.settings.requiredNoteTypeSelection,
                        disabled: true,
                    },
                [Validators.required],
            ],
        });

        this.formGroup.get('noteType').valueChanges.subscribe((note) => {
            this._addSpecificFormControlsForType();
        });

        this._addSpecificFormControlsForType();
    }

    private _addSpecificFormControlsForType() {
        const type = this.formGroup.get('noteType').value;

        if (type != ClientNextDeliveryTaskNoteType.FollowUp) {
            this.formGroup.removeControl('followUpDateTime');
        }

        switch (type) {
            case ClientNextDeliveryTaskNoteType.Comment:
                this.formGroup.addControl('description', this.formBuilder.control([this.model.followUpDateTime]));
                break;
            case ClientNextDeliveryTaskNoteType.FollowUp:
                this.formGroup.addControl('followUpDateTime', this.formBuilder.control([this.model.followUpDateTime, [Validators.required]]));
                break;
            case ClientNextDeliveryTaskNoteType.StandBy:
                this.formGroup.removeControl('description');
                break;
        }
    }
}
