import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentQualificationDto } from 'src/app/services/api.services';
import { AppointmentQualificationDialogComponent } from './appointment-qualification-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class AppointmentQualificationDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(details: AppointmentQualificationDto) {
        const dialogRef = this.dialogRef.open(AppointmentQualificationDialogComponent, {
            width: '800px',
            data: details,
        });

        return dialogRef.afterClosed();
    }
}
