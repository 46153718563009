import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryDto } from 'src/app/services/api.services';
import { CategorySelectorDialogComponent } from './category-selector-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class CategorySelectorDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(categorySelectorData: Array<CategoryDto>) {
        const dialogRef = this.dialogRef.open(CategorySelectorDialogComponent, {
            width: '800px',
            data: categorySelectorData,
        });

        return dialogRef.afterClosed();
    }
}
