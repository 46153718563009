import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoteFormDialogComponent } from './note-form-dialog.component';
import { Observable } from 'rxjs';
import { AppointmentDto, CallbackClientLeadNoteDto, ClientLeadNoteDto, LeadNoteType } from 'src/app/services/api.services';
import { DialogResult } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';

@Injectable({
    providedIn: 'root',
})
export class NoteFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: NoteFormDialogSettings<ClientLeadNoteDto>, width : string = '800px'): Observable<DialogResult<ClientLeadNoteDto>> {
        const dialogRef = this.dialog.open(NoteFormDialogComponent, {
            width: width,
            data: setting,
        });

        return dialogRef.afterClosed();
    }

    showFollowUpDialog(clientLeadId: number): Observable<DialogResult<ClientLeadNoteDto>> {
        return this.showDialog(this._generateNoteSettings(clientLeadId, LeadNoteType.FollowUp));
    }

    showQuickFollowUpDialog(clientLeadId: number): Observable<DialogResult<ClientLeadNoteDto>> {
        return this.showDialog(this._generateNoteSettings(clientLeadId, LeadNoteType.FollowUp, null, true), '400px');
    }

    showNotInterestedDialog(clientLeadId: number | undefined, appointmentDto: AppointmentDto): Observable<DialogResult<ClientLeadNoteDto>> {
        return this.showDialog(this._generateNoteSettings(clientLeadId, LeadNoteType.NotInterested, appointmentDto));
    }

    showUnbookableDialog(clientLeadId: number): Observable<DialogResult<ClientLeadNoteDto>> {
        return this.showDialog(this._generateNoteSettings(clientLeadId, LeadNoteType.Unbookable));
    }

    private _generateNoteSettings(clientLeadId: number, requiredNoteType: LeadNoteType, payload?: any, quickFollowup = false): NoteFormDialogSettings<ClientLeadNoteDto> {
        const settings = new NoteFormDialogSettings<ClientLeadNoteDto>();
        settings.allowClientLeadIdSelection = false;
        settings.isQuickFollowup = quickFollowup;
        settings.entityId = clientLeadId;
        settings.allowNoteTypeSelection = false;
        settings.requiredNoteTypeSelection = requiredNoteType;
        settings.isEdit = false;
        settings.payload = payload;
        return settings;
    }
}
