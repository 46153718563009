import { Component, OnInit, Input } from '@angular/core';
import { AppointmentClientGender, BudgetRange, SalesRepAppointmentDto, AppointmentQualificationDto, FreezerStockAmount } from 'src/app/services/api.services';
import { TranslateService } from '@ngx-translate/core';
import { EnumListService } from 'src/app/services/enum-list.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent implements OnInit {
    @Input() details: SalesRepAppointmentDto | AppointmentQualificationDto;

    supermarkets: string[] = [];
    supermarketDisplay: string;

    purchaseLocations: string[] = [];
    purchaseLocationDisplay: string;

    budgetDisplay: string;

    freezerDisplay: string;
    freezerStockAmount: FreezerStockAmount;

    creditcards: string[] = [];
    creditcardDisplay: string;

    title: string;

    salesRepDisplay: string;

    petTypes: string;

    @Input() wrapTableWithHeader = true;

    // Dispatch confirm component doesn't need to show these because it displays them as inputs and can be edited
    @Input() hideComments = false;

    canViewAppointmentCallInfo: boolean;

    constructor(private _translateService: TranslateService, public permissionService: NgxPermissionsService, public enumListService: EnumListService) {
        this.canViewAppointmentCallInfo = this.permissionService.getPermission('ViewAppointmentCallInfo') !== undefined;
    }

    ngOnInit() {
        this._setupDisplayValues();
    }

    private _setupDisplayValues(): void {
        this.title =
            this.details.gender == AppointmentClientGender.Male
                ? 'APPOINTMENT.GENDER.MR'
                : this.details.gender == AppointmentClientGender.Female
                    ? 'APPOINTMENT.GENDER.MRS'
                    : 'APPOINTMENT.GENDER.PROMO';

        if (this.details.visitsCostco) this.supermarkets.push('Costco');
        if (this.details.visitsIga) this.supermarkets.push('IGA');
        if (this.details.visitsLoblaws) this.supermarkets.push('Loblaws');
        if (this.details.visitsMaxi) this.supermarkets.push('Maxi');
        if (this.details.visitsMetro) this.supermarkets.push('Metro');
        if (this.details.visitsSuperC) this.supermarkets.push('Super C');
        if (this.details.visitsWalmart) this.supermarkets.push('Walmart');
        if (this.details.visitsOtherSupermarket) {
            // this.details.supermarketsClientVisits.forEach((supermarket) => {
            //     this.supermarkets.push(supermarket.name);
            // });
        }
        this.supermarketDisplay = this.supermarkets.join(', ');

        if (this.details.buysMeatAtButcher) this.purchaseLocations.push(this._translateService.instant('APPOINTMENT.PURCHASELOCATION.BUTCHER'));
        if (this.details.buysMeatAtSupermarket) this.purchaseLocations.push(this._translateService.instant('APPOINTMENT.PURCHASELOCATION.SUPERMARKET'));
        this.purchaseLocationDisplay = this.purchaseLocations.join(', ');

        this.budgetDisplay =
            this.details.budgetRange == BudgetRange.Low
                ? 'APPOINTMENT.BUDGETRANGE.LOW'
                : this.details.budgetRange == BudgetRange.LowMed
                    ? 'APPOINTMENT.BUDGETRANGE.LOWMED'
                    : this.details.budgetRange == BudgetRange.Med
                        ? 'APPOINTMENT.BUDGETRANGE.MED'
                        : this.details.budgetRange == BudgetRange.MedHigh
                            ? 'APPOINTMENT.BUDGETRANGE.MEDHIGH'
                            : 'APPOINTMENT.BUDGETRANGE.HIGH';

        let freezerDisplayOne = this.details.hasFreezer
            ? this._translateService.instant('APPOINTMENTDETAILS.HASFREEZERMSG')
            : this._translateService.instant('APPOINTMENTDETAILS.HASNOFREEZERMSG');
        let freezerDisplayTwo = this.details.hasSpace
            ? this._translateService.instant('APPOINTMENTDETAILS.HASSPACEMSG')
            : this._translateService.instant('APPOINTMENTDETAILS.HASNOSPACEMSG');
        this.freezerDisplay = `${freezerDisplayOne} ${freezerDisplayTwo}`;
        this.freezerStockAmount = this.details.freezerStockAmount;

        if (this.details.visa) this.creditcards.push('Visa');
        if (this.details.mastercard) this.creditcards.push('Mastercard');
        if (this.details.americanExpress) this.creditcards.push('American Express');
        if (this.details.usesOtherCreditCard) this.creditcards.push(this.details.otherCreditCard);
        this.creditcardDisplay = this.creditcards.join(', ');

        this.salesRepDisplay = this.details.confirmedSalesRep?.name || this.details.originalSalesRep?.name || '';

        const petTypes = [];
        if (this.details.petTypeDog) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.DOG'));
        }
        if (this.details.petTypeCat) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.CAT'));
        }
        if (this.details.petTypeFish) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.FISH'));
        }
        if (this.details.petTypeBird) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.BIRD'));
        }
        if (this.details.petTypeRodent) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.RODENT'));
        }
        if (this.details.petTypeReptile) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.REPTILE'));
        }
        if (this.details.petTypeRabbit) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.RABBIT'));
        }
        if (this.details.petTypeOtherOrExotic) {
            petTypes.push(this._translateService.instant('APPOINTMENTDETAILS.PETS.OTHER'));
        }
        this.petTypes = petTypes.join(', ').toLowerCase();
    }
}
