import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientLeadNotesDialogComponent } from './client-lead-notes-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ClientLeadNotesDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(clientLeadId: number) {
        const dialogRef = this.dialog.open(ClientLeadNotesDialogComponent, {
            width: '1500px',
            data: {
                clientLeadId: clientLeadId,
            },
        });
        return dialogRef.afterClosed();
    }
}
