import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientLead, ClientLeadSourcesService, ClientLeadSourceTypeDto, ClientLeadsService, FederalDnclService } from '../../../../services/api.services';
import { ClientLeadSourcesListService, SourceWrap } from 'src/app/services/client-lead-sources-list-service';
import { AppValidators } from 'src/app/models/appValidators';
import { FormComponent, FormComponentSettings } from 'src/app/models/formComponent';
import { FormContainerService } from 'src/app/services/form-container.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-client-lead-details',
    templateUrl: './client-lead-details.component.html',
    styleUrls: ['./client-lead-details.component.scss'],
})
export class ClientLeadDetailsComponent extends FormComponent<number, ClientLead> implements OnInit, OnDestroy {
    clientLeadSources: ClientLeadSourceTypeDto[];
    languageEN: boolean;

    constructor(
        private apiService: ClientLeadsService,
        private translateService: TranslateService,
        private clientLeadSourcesService: ClientLeadSourcesService,
        private route: ActivatedRoute,
        formContainerService: FormContainerService,
        private federalDnclService: FederalDnclService
    ) {
        super(new FormComponentSettings(true, 'client-leads', 'Client Leads'), formContainerService);
        this.languageEN = this.translateService.currentLang == 'en';
        this.clientLeadSources = [];
    }

    editEntity(): Observable<ClientLead> {
        this._generateFromForm();
        return this.apiService.edit(this.model);
    }

    createEntity(): Observable<ClientLead> {
        this.routeBackOnSuccess = true;
        this._generateFromForm();
        return this.apiService.create(this.model);
    }

    _generateFromForm() {
        const entity = new ClientLead();
        const roughClientLead = this.formControl.value;

        entity.init(roughClientLead);

        this.model = Object.assign(this.model, entity);
    }

    ngOnInit() {
        this.model = this.route.parent.snapshot.data['clientLead'];

        this.clientLeadSourcesService.clientLeadSources().subscribe(
            (clientLeadSources) => {
                this.clientLeadSources = clientLeadSources;
            },
            (error) => {
                console.log(error);
            }
        );

        if (this.model) {
            this.id = this.model.id;
            this.routeBackOnSuccess = false;
        }

        this.translateService.onLangChange.subscribe(
            (languageChange) => {
                this.languageEN = this.translateService.currentLang == 'en';
            },
            (error) => {
                console.log(error);
            }
        );

        this._setupformControl();
    }

    _setupformControl() {
        if (!this.model) {
            this.model = ClientLead.fromJS({});
        }

        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, [Validators.required]],
            clientLeadSourceTypeId: [this.model.clientLeadSourceTypeId, [Validators.required]],
            email: [this.model.email, [Validators.email]],
            phoneNumberExtension: [this.model.phoneNumberExtension],
            phoneNumber: new FormControl(this.model.phoneNumber, AppValidators.phone, AppValidators.checkPhoneNumberInDncl(this.federalDnclService)),
        });
    }

    ngOnDestroy() {
        this.fixedFormSub.unsubscribe();
    }
}
