import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'yesNoPipe',
})
export class YesNoPipePipe implements PipeTransform {
    constructor(public translateService: TranslateService) {}

    transform(value: any, args?: any): any {
        let str = '';
        switch (value) {
            case true:
                str = 'YES';
                break;
            case false:
                str = 'NO';
                break;
            case null:
                str = 'NO';
                break;
        }
        return this.translateService.instant(str);
    }
}
