import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppointmentQualificationDto, AppointmentQualificationsService, UpdateQualificationCommand } from 'src/app/services/api.services';
import { CommonComponent } from 'src/app/models/formComponent';
import { NotifyService } from 'src/app/services/notify.service';
import { flatMap } from 'rxjs/operators';

@Component({
    selector: 'app-appointment-qualification-dialog',
    templateUrl: './appointment-qualification-dialog.component.html',
    styleUrls: ['./appointment-qualification-dialog.component.scss'],
})
export class AppointmentQualificationDialogComponent extends CommonComponent implements OnInit {
    isQualified: boolean;
    isPromo: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public details: AppointmentQualificationDto,
        private dialogRef: MatDialogRef<AppointmentQualificationDialogComponent>,
        private notifyService: NotifyService,
        private appointmentQualificationsService: AppointmentQualificationsService
    ) {
        super();

        this.isQualified = this.details.qualified != null ? this.details.qualified : true;

        this.isPromo = this.details.promo ? true : false;
    }

    ngOnInit() {}

    updateAppointmentQualifications() {
        const command = new UpdateQualificationCommand();
        command.callNo = this.details.callNo;
        command.isQualified = this.isQualified;
        command.isPromo = this.isPromo;

        this.appointmentQualificationsService
            .updateQualification(command)
            .pipe(flatMap((callNo) => this.appointmentQualificationsService.appointmentQualification(callNo)))
            .subscribe(
                (appointment) => {
                    this.notifyService.success('Appointment updated successfully', true);
                    this.dialogRef.close(appointment);
                },
                (err) => {
                    this.notifyService.fail('Appointment could not be updated', true);
                }
            );
    }

    updatePromoValue() {
        this.isPromo = !this.isQualified ? false : this.isPromo;
    }
}
