import { Component, OnInit, Input } from '@angular/core';
import { CalendarEventLoader } from 'src/app/models/interfaces/calendarEventLoader';
import * as moment from 'moment';
import { DialogResultActions } from 'src/app/models/dialogResult';
declare const $: any;

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
    @Input() calendarEventLoader: CalendarEventLoader;

    constructor() {}

    ngOnInit() {
        const $calendar = $('#fullCalendar');

        $calendar.fullCalendar({
            viewRender: function (view: any, element: any) {
                // We make sure that we activate the perfect scrollbar when the view isn't on Month
                if (view.name !== 'month') {
                    const $fc_scroller = $('.fc-scroller');
                    //   $fc_scroller.perfectScrollbar();
                }
            },
            header: {
                left: 'title',
                center: 'month, agendaWeek, agendaDay',
                right: 'prev, next, today',
            },
            defaultDate: moment(),
            selectable: true,
            selectHelper: true,
            views: {
                month: {
                    // name of view
                    titleFormat: 'MMMM YYYY',
                    // other view-specific options here
                },
                week: {
                    titleFormat: ' MMMM D YYYY',
                },
                day: {
                    titleFormat: 'D MMM, YYYY',
                },
            },

            eventClick: (calEvent, jsEvent, view) => {
                this.calendarEventLoader.editCalendarEvent(calEvent.id).subscribe((result) => {
                    if (!result) {
                        return;
                    }
                    if (result.action === DialogResultActions.Edit) {
                        calEvent.start = result.entity.start;
                        calEvent.end = result.entity.end;
                        calEvent.title = result.entity.title;
                        $calendar.fullCalendar('updateEvent', calEvent);
                    } else if (result.action === DialogResultActions.Delete) {
                        $calendar.fullCalendar('removeEvents', calEvent.id);
                    }
                });
            },
            select: (start: any, end: any) => {
                // console.log('here');
                // // on select we show the Sweet Alert modal with an input
                // this.calendarEventLoader.addCalendarEvent(start).subscribe(result => {
                //     if (!result) {
                //         return;
                //     }
                //     if (result.action === DialogResultActions.Create) {
                //         $calendar.fullCalendar('renderEvent', result.entity, true);
                //     }
                // });
            },
            editable: false,
            eventLimit: true, // allow "more" link when too many events

            events: (start, end, timezone, callback) => {
                const startOfMonth = moment(start).startOf('month');
                const endOfMonth = moment(end).endOf('month');
                this.calendarEventLoader.getCalendarEvents(startOfMonth, endOfMonth).subscribe((events) => {
                    callback(events);
                });
            },
        });
    }
}
