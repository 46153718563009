import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult } from 'src/app/models/dialogResult';
import { ServiceTicketLookupDto, ServiceTicketStatus } from 'src/app/services/api.services';
import { Observable } from 'rxjs';
import { ChangeServiceTicketStatusDialogComponent } from './change-service-ticket-status-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ChangeServiceTicketStatusDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(serviceTicket: ServiceTicketLookupDto): Observable<DialogResult<ServiceTicketStatus>> {
        const dialogRef = this.dialog.open(ChangeServiceTicketStatusDialogComponent, {
            width: '800px',
            data: serviceTicket,
        });

        return dialogRef.afterClosed();
    }
}
