import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
export class DataSharingService {
    
    public editDataDetails: any = [];
    public subject = new Subject<any>();
    private messageSource = new BehaviorSubject(this.editDataDetails);
    messages = this.messageSource.asObservable();

    raiseMessage(key: string, message: string) {
        const value = {
            key: key,
            message: message
        }
        this.messageSource.next(value)
    }
}