import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallbackNotesComponent } from '../callback-notes/callback-notes.component';

@Component({
  selector: 'app-callback-notes-dialog',
  templateUrl: './callback-notes-dialog.component.html',
  styleUrls: ['./callback-notes-dialog.component.scss']
})
export class CallbackNotesDialogComponent implements OnInit {
  @ViewChild(CallbackNotesComponent, {static: true}) callbackNotesList: CallbackNotesComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { callbackClientLeadId: number }
  ) { }

  ngOnInit(): void {
  }
}
