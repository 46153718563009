import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddToDncDialogSettings } from 'src/app/models/dialogs/addToDncDialogSettings';
import { AppointmentsService, AddToDoNotCallListCommand, SerializableError } from 'src/app/services/api.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonComponent } from 'src/app/models/formComponent';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-to-dnc-dialog',
    templateUrl: './add-to-dnc-dialog.component.html',
    styleUrls: ['./add-to-dnc-dialog.component.scss'],
})
export class AddToDncDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup;
    phoneNumber: string;
    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: AddToDncDialogSettings,
        private dialogRef: MatDialogRef<AddToDncDialogComponent>,
        private appointmentsService: AppointmentsService,
        private formBuilder: FormBuilder,
        private notifyService: NotifyService,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this._setupFormControl();
    }

    addToDncList() {
        if (this.formGroup.valid) {
            const command = new AddToDoNotCallListCommand();
            command.phoneNumber = this.formGroup.get('phoneNumber').value;
            this.loading = true;
            this.formGroup.disable();
            this.appointmentsService.addToDoNotCallList(command).subscribe(
                () => {
                    this.dialogRef.close(new DialogResult(DialogResultActions.Edit, null));
                    this.loading = false;
                },
                (err) => {
                    this.formGroup.enable();
                    if (err instanceof SerializableError) {
                        this._handleValidationError(err);
                    } else {
                        this.notifyService.fail('Error when creating do not call entry');
                    }
                    this.loading = false;
                    this.dialogRef.close();
                }
            );
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    private _setupFormControl() {
        this.formGroup = this.formBuilder.group({
            phoneNumber: [this.phoneNumber, [Validators.required]],
        });
    }

    private _handleValidationError(err: SerializableError) {
        if (err['DuplicatePhone']) {
            this.notifyService.fail(this.translateService.instant('DONOTCALL.DUPLICATEPHONEERROR'));
        } else if (err['MissingAdUsername']) {
            this.notifyService.fail(this.translateService.instant('DONOTCALL.MISSINGADUSERNAMEERROR'));
        } else {
            this.notifyService.fail(this.translateService.instant('ERROR.VALIDATION'));
        }
    }
}
