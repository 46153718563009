import { Injectable } from '@angular/core';
import { PaginationModel } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class PagingPersistService {

  configuration: any;
  storageKey = 'pagingRoot';
  constructor() {
    this.configuration = JSON.parse(localStorage.getItem(this.storageKey));

    if (!this.configuration) {
      this.configuration = {
      };
    }
  }

  setPagingInfo(key, value: PaginationModel) {
    this.configuration[key] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(this.configuration));
  } 

  getPagingValue(key) {
    return this.configuration[key];
  }

  hasValue(key) {
    return this.configuration.hasOwnProperty(key);
  }

}
