import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddToDncDialogComponent } from './add-to-dnc-dialog.component';
import { AddToDncDialogSettings } from 'src/app/models/dialogs/addToDncDialogSettings';
import { Observable } from 'rxjs';
import { DialogResult } from 'src/app/models/dialogResult';

@Injectable({
    providedIn: 'root',
})
export class AddToDncDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: AddToDncDialogSettings): Observable<DialogResult<string>> {
        const dialogRef = this.dialog.open(AddToDncDialogComponent, {
            width: '800px',
            data: settings,
        });

        return dialogRef.afterClosed();
    }
}
