import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AppointmentTypeDialogComponent } from 'src/app/shared/dialog/appointment-type-dialog/appointment-type-dialog.component';

@NgModule({
    declarations: [AppointmentTypeDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    exports: [AppointmentTypeDialogComponent],
})
export class AppointmentTypeDialogModule {}
