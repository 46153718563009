import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, observable, interval } from 'rxjs';
import { ProgressDialogComponent } from './progress-dialog.component';
import { BackgroundJobService } from 'src/app/services/api.services';
import { flatMap, map } from 'rxjs/operators';
import { ProgesDialogInfo } from './progress-dialog-info';

@Injectable({
    providedIn: 'root',
})
export class ProgressDialogService {
    constructor(private dialog: MatDialog, private backgroundJobService: BackgroundJobService) {}

    showDialog(updateObservable: Observable<ProgesDialogInfo>, title = 'PROGRESS'): Observable<boolean> {
        const entitySelectedObservable = new Subject<any>();
        const dialogRef = this.dialog.open(ProgressDialogComponent, {
            width: '400px',
        });
        dialogRef.componentInstance.title = title;

        dialogRef.afterClosed().subscribe((result) => {
            entitySelectedObservable.next(result);
        });

        return entitySelectedObservable;
    }

    showProgressOfBackgroundJob(jobId: string, title = 'PROGRESS') {
        const progressObservable = interval(1000).pipe(
            flatMap((value) => {
                return this.backgroundJobService.get(jobId).pipe(map((job) => ProgesDialogInfo.createFromBackgroundJobDto(job)));
            })
        );

        const dialogRef = this.dialog.open(ProgressDialogComponent, {
            width: '400px',
            data: {
                progress: progressObservable,
            },
        });
        dialogRef.componentInstance.title = title;
    }
}
