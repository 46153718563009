import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ImgCropperDialogComponent, ImageResult } from './img-cropper-dialog.component';
import { ImageMediaDto } from 'src/app/services/api.services';

@Injectable({
    providedIn: 'root',
})
export class ImgCropperDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(isUpload: boolean = false, imageWidth = 300, imageHeight = null): Observable<ImageResult> {
        const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
            width: `${imageWidth + 100}px`,
            data: { isUpload: isUpload, width: imageWidth, height: imageHeight === null ? imageWidth : imageHeight },
        });

        return dialogRef.afterClosed();
    }

    showEditDialog(imageWidth = 300, imageHeight = null): Observable<ImageResult> {
        const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
            width: `${imageWidth + 100}px`,
            data: { width: imageWidth, height: imageHeight === null ? imageWidth : imageHeight },
        });

        return dialogRef.afterClosed();
    }

    showUploadDialog(isUpload: boolean = true, imageWidth = 300, imageHeight = null, validation = null): Observable<ImageMediaDto> {
        const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
            width: `${imageWidth + 100}px`,
            data: { isUpload: isUpload, width: imageWidth, height: imageHeight === null ? imageWidth : imageHeight, validation: validation },
        });

        return dialogRef.afterClosed();
    }
}
