import { NgModule } from '@angular/core';
import { AppointmentQualificationDialogComponent } from './appointment-qualification-dialog.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AppointmentDetailsModule } from 'src/app/shared/appointment-details/appointment-details.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [AppointmentQualificationDialogComponent],
    imports: [CommonFormsModule, MaterialModule, PipesModule, TranslateModule.forChild(), AppointmentDetailsModule],
    exports: [AppointmentQualificationDialogComponent],
})
export class AppointmentQualificationDialogModule {}
