import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { FixedFormControlsModule } from '../fixed-form-controls/fixed-form-controls.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { TableContainerComponent } from './table-container.component';

@NgModule({
    imports: [CommonModule, MaterialModule, FixedFormControlsModule, PipesModule, TranslateModule],
    declarations: [TableContainerComponent],
    exports: [TableContainerComponent],
})
export class TableContainerModule {}
