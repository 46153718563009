import { Injectable } from '@angular/core';

export class SourceWrap {
    constructor(public id: number, public nameEn: string, public nameFr: string) { }
}

@Injectable()
export class ClientLeadSourcesListService {
    public WebClientLeadSourceType = new SourceWrap(1, 'Web', 'Web');
    public ImportClientLeadSourceType = new SourceWrap(2, 'Import', 'Import');
    public CbpClientLeadSourceType = new SourceWrap(3, 'Cbp', 'Cbp');
    public CarShowClientLeadSourceType = new SourceWrap(4, 'Car show', "Salon de l'automobile");
    public WebCampaignLeadSourceType = new SourceWrap(5, 'Web - Display', 'Web - Display');
    public WebDeliveryLeadSourceType = new SourceWrap(6, 'Web - Livraison', 'Web - Livraison');
    public SalonVrLeadSourceType = new SourceWrap(7, 'Salon', 'Salon');
    public CrmClientLeadSourceType = new SourceWrap(8, 'Crm', 'Crm');
    public SalonFemmeClientLeadSourceType = new SourceWrap(9, 'Salon-Femme', 'Salon-Femme');
    public ConcoursSalonHabitationClientLeadSourceType = new SourceWrap(10, "Concours Salon de l'habitation", "Concours Salon de l'habitation");

    constructor() { }
}
