import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { UserType, RolePoliciesMapping } from 'src/app/services/api.services';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppSettings } from 'src/app/app.settings';

@Injectable()
export class AccountManageService {
    public accountInfoSource = new BehaviorSubject<any>({});
    public assignedRolesSource = new BehaviorSubject<string[]>([]);

    public loggedInUser: any;
    accountInfo = this.accountInfoSource.asObservable();
    userName: string;
    isLoggedIn = false;

    passwordExpired: boolean;
    passwordAlmostExpired: boolean;
    daysToReset: number;

    userType: UserType;

    constructor(private http: HttpClient, private router: Router, private permissionService: NgxPermissionsService) {
        const rawUser = localStorage.getItem('user');

        if (rawUser !== null) {
            const user = JSON.parse(rawUser);
            this.loggedInUser = user;
            this.userName = user.userName;
            this.userType = user.userType;
            this.passwordExpired = user.passwordExpired;
            this.passwordAlmostExpired = user.passwordAlmostExpired;
            this.daysToReset = user.daysLeftToChangePassword;

            this.accountInfoSource.next(user);
            if (user.assignedPolicies) {
                this.permissionService.loadPermissions(user.assignedPolicies);
            }
            this.isLoggedIn = true;
        } else {
            console.log('User not logged in');
            this.router.navigate(['/login']);
            this.isLoggedIn = false;
        }
    }

    gotoLogin() {
        this.router.navigate(['/login']);
    }

    logout() {
        if (this.isLoggedIn) {
            localStorage.removeItem('user');
            this.accountInfoSource.complete();
            return this.http.post('/api/accounts/logout', {}).pipe(
                map(
                    (result) => {
                        console.log('Logout success');
                    },
                    (error) => {
                        console.log('logout on server failed', error);
                    }
                )
            );
        }
        return EMPTY;
    }

    refreshUser(user: any) {
        this.accountInfoSource.next(user);
        if (user.assignedPolicies) {
            this.permissionService.loadPermissions(user.assignedPolicies);
        }
        localStorage.setItem('user', JSON.stringify(user));
        this.loggedInUser = user;
        this.userType = user.userType;
        this.isLoggedIn = true;
        this.userName = user.name;
        this.passwordExpired = user.passwordExpired;
        this.passwordAlmostExpired = user.passwordAlmostExpired;
        this.daysToReset = user.daysLeftToChangePassword;
    }

    login(user: any) {
        this.refreshUser(user);
    }
}
