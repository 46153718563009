import { FormGroup, FormControl, FormArray, ValidationErrors, FormBuilder } from '@angular/forms';
import { MyErrorStateMatcher } from './myErrorStateMatcher';
import { Observable, Subscription, empty } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifyService } from '../services/notify.service';
import { FixedFormControlsService, ControlMode, FixedButton } from 'src/app/shared/fixed-form-controls/fixed-form-controls.service';
import { OnDestroy, HostListener, Directive } from '@angular/core';
import { FormContainerService } from 'src/app/services/form-container.service';
import { LanguageService } from 'src/app/services/language.service';
import { EnumListService } from 'src/app/services/enum-list.service';
import { ComponentCanDeactivate } from 'src/app/shared/guards/canDeactivateGuard';
import { SerializableError } from 'src/app/services/api.services';

export interface IEnterDialogMode { }

export abstract class CommonComponent {

    isFieldValidOnFormControl(formControl: FormGroup, field: string) {
        return !formControl.get(field).valid && formControl.get(field).touched && !formControl.get(field).disabled;
    }

    emailFieldHasError(type, formControl: FormGroup) {
        return formControl.hasError('email', type) && this.isFieldValidOnFormControl(formControl, type) && !formControl.hasError('required', type);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach((formArrayControl: any) => this.validateAllFormFields(formArrayControl));
            }
        });
    }

    getFormValidationErrors(formControl: FormGroup) {
        Object.keys(formControl.controls).forEach((key) => {
            const controlErrors: ValidationErrors = formControl.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    findInvalidControls(formGroup: FormGroup) {
        const invalid = [];
        const controls = formGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    getFormControlErrors(formGroup: FormGroup, key: string): ValidationErrors {
        return formGroup.get(key).errors;
    }

    stripCurrencyString(currenyString: string) {
        if (typeof currenyString !== 'string') {
            return currenyString;
        }
        return Number(currenyString.replace(/,/g, '').replace('$', '').replace('.00', ''));
    }

    stripHourString(hourString: string) {
        if (typeof hourString !== 'string') {
            return hourString;
        }
        return Number(hourString.replace(/,/g, '').replace('h', ''));
    }

    stripPercentString(percentString: string) {
        if (typeof percentString !== 'string') {
            return percentString;
        }
        return Number(percentString.replace(/,/g, '').replace('% ', ''));
    }

    compareById(f1: any, f2: any): boolean {
        return f1 && f2 && f1.id === f2.id;
    }

    getFormControlValue(formGroup: FormGroup, key: string) {
        return formGroup.get(key).value;
    }

    setFormControlValue(formGroup: FormGroup, key: string, value: any) {
        return formGroup.get(key).setValue(value);
    }
}

export class FormComponentSettings {
    constructor(public usedFixedForm: boolean, public returnPage: string, public entityName: string, public useLanguage: boolean = false, public routeBack = true) { }
}

@Directive()
export abstract class FormComponent<idType, Model> extends CommonComponent implements OnDestroy, ComponentCanDeactivate {
    id: idType;
    matcher = new MyErrorStateMatcher();

    model: Model;
    fixedFormSub: Subscription;
    routeBackOnSuccess = true;
    formControl: FormGroup;
    notifyService: NotifyService;
    router: Router;
    fixedFormControlsService: FixedFormControlsService;
    formBuilder: FormBuilder;
    languageService: LanguageService;
    enumListService: EnumListService;

    isRoutingBack = false;
    fixedSavedButton: FixedButton;

    constructor(public formComponentSettings: FormComponentSettings, public formContainerService: FormContainerService) {
        super();

        this.notifyService = formContainerService.notifyService;
        this.router = formContainerService.router;
        this.fixedFormControlsService = formContainerService.fixedFormControlsService;
        this.formBuilder = formContainerService.formBuilder;
        this.languageService = formContainerService.languageService;
        this.enumListService = formContainerService.enumListService;

        if (this.formContainerService.isDialogMode) {
            return;
        }

        if (formComponentSettings.useLanguage) {
            formContainerService.fixedFormControlsService.showLanguageButton(true);
        }

        if (formComponentSettings.usedFixedForm && this.formContainerService.fixedFormControlsService.setupSingleEditButton) {
            this.fixedSavedButton = this.formContainerService.fixedFormControlsService.setupSingleEditButton(
                undefined, undefined, 'primary', 'SAVE'
            );
            this.fixedFormSub = this.fixedSavedButton.buttonClickedObservable.subscribe(() => {
                this.onSubmit();
            });
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = window.confirm('You have unsaved changes, do you want to discard your changes?');
        }
    }

    canDeactivate(): boolean {
        if (this.isRoutingBack) {
            return true;
        }
        return !this.formControl.dirty || this.canDeactivateBase();
    }

    get isCreation(): boolean {
        return !this.isEdit;
    }

    get isEdit(): boolean {
        return this.id !== undefined;
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe();
        }
    }

    canDeactivateBase(): boolean {
        return true;
    }

    abstract editEntity(): Observable<Model>;
    abstract createEntity(): Observable<Model>;

    isFieldValid(field: string) {
        return this.isFieldValidOnFormControl(this.formControl, field);
    }

    onSubmit() {
        if (this.formControl.valid) {
            if (this.fixedSavedButton) {
                if (this.fixedSavedButton.isLoading) {
                    return;
                }
                this.fixedSavedButton.disable();
            }

            if (this.id) {
                this.editEntity().subscribe(
                    (result) => {
                        this._handleSuccess();
                    },
                    (err) => {
                        this._handleFailure(err);
                    }
                );
            } else {
                this.createEntity().subscribe(
                    (result) => {
                        this._handleSuccess();
                    },
                    (err) => {
                        this._handleFailure(err);
                    }
                );
            }
        } else {
            this.validateAllFormFields(this.formControl);
        }
    }

    private _handleFailure(err: any) {
        var msg = `Failed to save ${this.formComponentSettings.entityName}`;
        if (err && err instanceof SerializableError) {
            try {
                for (var fieldName in err) {
                    if (err.hasOwnProperty(fieldName)) {
                        msg = msg + `<br>Error in field ${fieldName}: ${err[fieldName]}`;
                    }
                }
            } catch { }
        }
        this.formContainerService.notifyService.fail(msg);
        this.fixedSavedButton.enable();
    }

    private _handleSuccess() {
        this.formContainerService.notifyService.success(`${this.formComponentSettings.entityName} info has been saved`);
        if (this.routeBackOnSuccess) {
            this.isRoutingBack = true;
            this.formContainerService.router.navigate([`/${this.formComponentSettings.returnPage}`]);
        }
        this.fixedSavedButton.enable();
    }

    emailFieldHasError(type) {
        return this.formControl.hasError('email', type) && this.isFieldValid(type) && !this.formControl.hasError('required', type);
    }
}

@Directive()
export abstract class FormComponentId<IdType, ModelType> extends CommonComponent implements OnDestroy, ComponentCanDeactivate {
    routeNo: IdType;
    matcher = new MyErrorStateMatcher();

    model: ModelType;
    fixedFormSub: Subscription;
    routeBackOnSuccess = true;
    formControl: FormGroup;
    notifyService: NotifyService;
    router: Router;
    fixedFormControlsService: FixedFormControlsService;
    formBuilder: FormBuilder;
    languageService: LanguageService;
    enumListService: EnumListService;

    isRoutingBack = false;
    fixedSavedButton: FixedButton;

    formSubmitAttempt: boolean = false;

    constructor(public formComponentSettings: FormComponentSettings, public formContainerService: FormContainerService) {
        super();

        this.notifyService = formContainerService.notifyService;
        this.router = formContainerService.router;
        this.fixedFormControlsService = formContainerService.fixedFormControlsService;
        this.formBuilder = formContainerService.formBuilder;
        this.languageService = formContainerService.languageService;
        this.enumListService = formContainerService.enumListService;

        if (this.formContainerService.isDialogMode) {
            return;
        }

        if (formComponentSettings.useLanguage) {
            formContainerService.fixedFormControlsService.showLanguageButton(true);
        }

        if (formComponentSettings.usedFixedForm) {
            this.fixedSavedButton = this.formContainerService.fixedFormControlsService.setupSingleEditButton();
            this.fixedFormSub = this.fixedSavedButton.buttonClickedObservable.subscribe(() => {
                this.onSubmit();
            });
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = window.confirm('You have unsaved changes, do you want to discard your changes?');
        }
    }

    canDeactivate(): boolean {
        if (this.isRoutingBack) {
            return true;
        }
        return !this.formControl.dirty || this.canDeactivateBase();
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe();
        }
    }

    canDeactivateBase(): boolean {
        return true;
    }

    abstract editEntity(): Observable<void>;
    abstract createEntity(): Observable<IdType>;

    isFieldValid(field: string) {
        return this.isFieldValidOnFormControl(this.formControl, field);
    }

    onSubmit() {
        if (this.formSubmitAttempt) return;

        this.formSubmitAttempt = true;
        this.formControl.updateValueAndValidity();
        if (this.formControl.valid) {
            this.fixedSavedButton.disable();
            if (this.routeNo) {
                this.editEntity().subscribe(
                    (result) => {
                        this.formSubmitAttempt = false;
                        this._handleSuccess();
                    },
                    (err) => {
                        this.formSubmitAttempt = false;
                        this._handleFailure(err);
                    }
                );
            } else {
                this.createEntity().subscribe(
                    (result) => {
                        this.formSubmitAttempt = false;
                        this._handleSuccess(result);
                    },
                    (err) => {
                        this.formSubmitAttempt = false;
                        this._handleFailure(err);
                    }
                );
            }
        } else {
            this.formSubmitAttempt = false;
            this.validateAllFormFields(this.formControl);
            this.notifyService.fail('FORMMISSINGFIELDS', true);
        }
    }

    emailFieldHasError(type) {
        return this.formControl.hasError('email', type) && this.isFieldValid(type) && !this.formControl.hasError('required', type);
    }

    private _handleFailure(err) {
        var msg = `Failed to save ${this.formComponentSettings.entityName}`;

        if (err && err() && err().message) {
            msg = err().message;
        }
        this.formContainerService.notifyService.fail(msg);
        console.log(err);
        this.fixedSavedButton.enable();
    }

    private _handleSuccess(result = null) {
        this.formContainerService.notifyService.success(`${this.formComponentSettings.entityName} info has been saved`);
        if (this.routeBackOnSuccess) {
            this.isRoutingBack = true;
            this.formContainerService.router.navigate([`/${this.formComponentSettings.returnPage}`]);
        } else if (result) {
            this.formContainerService.router.navigate([`/${this.formComponentSettings.returnPage}/edit/`, result]);
        }
        this.fixedSavedButton.enable();
    }
}

@Directive()
export abstract class DialogCapableFormComponent<idType, Model> extends FormComponent<idType, Model> {
    isInDialogMode = false;

    constructor(formComponentSettings: FormComponentSettings, formContainerService: FormContainerService) {
        super(formComponentSettings, formContainerService);
    }

    onSubmitFromDialog(): Observable<Model> {
        if (this.formControl.valid) {
            if (this.id) {
                return this.editEntity();
            } else {
                return this.createEntity();
            }
        } else {
            this.validateAllFormFields(this.formControl);
            return empty();
        }
    }
}
