import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryDateChoiceDialogComponent } from './delivery-date-choice-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [DeliveryDateChoiceDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [DeliveryDateChoiceDialogComponent]
})
export class DeliveryDateChoiceDialogModule { }
