
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderTranslationBy',
  pure: false
})
export class OrderTranslationByPipe implements PipeTransform {

  transform(records: Array<any>, args?: any): any {
    return records.sort(function (a, b) {
      if (a.selectedTranslation[args.property] < b.selectedTranslation[args.property]) {
        return -1 * args.direction;
      } else if (a.selectedTranslation[args.property] > b.selectedTranslation[args.property]) {
        return 1 * args.direction;
      } else {
        return 0;
      }
    });
  };
}