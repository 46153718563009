import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BlanketOrder, SalesOrderInventoryItem } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data';
import { DialogResult } from 'src/app/models/dialogResult';
import { BlanketOrderInitiateReturnDialogComponent, ReturnInformation } from './blanket-order-initiate-return-dialog.component';

@Injectable({ providedIn: 'root' })
export class BlanketOrderInitiateReturnService {
    constructor(private dialog: MatDialog) { }

    showDialog(
        blanketOrder: BlanketOrder,
        blanketLineItem: SalesOrderInventoryItem
    ): Observable<ReturnInformation> {
        const dialogRef = this.dialog.open(BlanketOrderInitiateReturnDialogComponent, {
            width: '60vw',
            maxHeight: '80vh',
            autoFocus: "false",
            data: {
                blanketOrder: blanketOrder,
                blanketLineItem: blanketLineItem,
            },
        });

        return dialogRef.afterClosed();
    }
}