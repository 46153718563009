// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.order-section {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

h2 {
  margin-top: 0;
}

h3 {
  margin: 10px 0 5px 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
}

li:last-child {
  border-bottom: none;
}

.added-items li {
  color: #28a745;
}

.removed-items li {
  color: #dc3545;
}

.modified-items li .before {
  color: #6c757d;
  text-decoration: line-through;
}

.order-section .increased {
  color: #28a745;
}

.order-section .decreased {
  color: #dc3545;
}

.modified-items .increased {
  color: #28a745;
}

.modified-items .decreased {
  color: #dc3545;
}

.dollar-difference {
  float: right;
}

.return-label {
  font-weight: bold;
  font-size: 12px;
  padding: 2px 8px;
  background-color: yellow;
  border-radius: 15px;
  width: -moz-fit-content;
  width: fit-content;
}

.replacement-label {
  font-weight: bold;
  font-size: 12px;
  padding: 2px 8px;
  background-color: lightgreen;
  border-radius: 15px;
  width: -moz-fit-content;
  width: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
