import { NgModule } from '@angular/core';
import { ImportReferencesDialogComponent } from './import-references-dialog.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { MaterialModule } from 'src/app/material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [ImportReferencesDialogComponent],
    imports: [CommonFormsModule, MaterialModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    exports: [ImportReferencesDialogComponent],
})
export class ImportReferencesDialogModule {}
