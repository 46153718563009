import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogResult } from '../../../models/dialogResult';
import { PushToOptimorouteDialogComponent } from './push-to-optimoroute-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class PushToOptimorouteDialogService {
    constructor(private dialog: MatDialog) { }

    showDialog(settings: any): Observable<DialogResult<string>> {
        const dialogRef = this.dialog.open(PushToOptimorouteDialogComponent, {
            width: '600px',
            data: settings,
        });

        return dialogRef.afterClosed();
    }
}
