// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-reset-notification {
  height: 80px;
  width: 100%;
  z-index: 99999;
  background-color: #DA291C;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.password-reset-notification p {
  color: white;
  text-align: center;
  margin: 0;
}
.password-reset-notification a {
  color: white;
  text-decoration: underline;
}

.modified-for-alert {
  margin-top: 80px;
}
.modified-for-alert .sidebar {
  top: 80px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
