import { Injectable } from '@angular/core';
import { CalendarEventLoader } from 'src/app/models/interfaces/calendarEventLoader';
import * as moment from 'moment';
import { Observable, pipe, empty } from 'rxjs';
import { CalendarEvent } from 'src/app/models/calendarEvent';
import { ClientLeadsService, GetAllClientLeadsNotesListQuery, ClientLeadNoteDto, LeadNoteType } from '../api.services';
import { map } from 'rxjs/operators';
import { NoteFormDialogService } from 'src/app/shared/dialog/note-form-dialog/note-form-dialog.service';
import { DialogResultActions, DialogResult } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
@Injectable({
    providedIn: 'root',
})
export class ClientLeadNoteEventLoaderService implements CalendarEventLoader {
    clientLeadNotes: ClientLeadNoteDto[];
    transformedCalendarEvents: CalendarEvent[];

    constructor(private clientLeadsService: ClientLeadsService, private noteFormDialogService: NoteFormDialogService) {}

    addCalendarEvent(start: moment.Moment): Observable<DialogResult<CalendarEvent>> {
        const settings = new NoteFormDialogSettings<ClientLeadNoteDto>();

        settings.allowNoteTypeSelection = false;
        settings.allowClientLeadIdSelection = true;
        settings.isEdit = false;
        settings.startOnDateTime = start;
        settings.requiredNoteTypeSelection = LeadNoteType.FollowUp;

        return this.noteFormDialogService.showDialog(settings).pipe(
            map((result) => {
                if (!result) {
                    return;
                }
                return new DialogResult(DialogResultActions.Create, this._convertToCalendarEvent(result.entity));
            })
        );
    }

    editCalendarEvent(id: any): Observable<DialogResult<CalendarEvent>> {
        const clientLeadNoteIndex = this.clientLeadNotes.findIndex((note) => note.id === id);
        const calendarEventIndex = this.transformedCalendarEvents.findIndex((event) => event.id === id);
        const calendarEvent = this.transformedCalendarEvents[calendarEventIndex];

        const settings = new NoteFormDialogSettings<ClientLeadNoteDto>();
        settings.model = this.clientLeadNotes[clientLeadNoteIndex];
        settings.allowNoteTypeSelection = false;
        settings.allowClientLeadIdSelection = false;
        settings.isEdit = true;
        settings.allowDelete = true;
        settings.showClientLead = true;

        return this.noteFormDialogService.showDialog(settings).pipe(
            map((result) => {
                if (!result) {
                    return;
                }
                if (result.action === DialogResultActions.Delete) {
                    this.clientLeadNotes.splice(clientLeadNoteIndex, 1);
                    this.transformedCalendarEvents.splice(calendarEventIndex, 1);
                    return new DialogResult(DialogResultActions.Delete, calendarEvent);
                } else if (result.action === DialogResultActions.Edit) {
                    this.clientLeadNotes[clientLeadNoteIndex] = result.entity;

                    if (result.entity.noteType === LeadNoteType.FollowUp) {
                        calendarEvent.start = result.entity.followUpDateTime;
                        calendarEvent.end = result.entity.followUpDateTime;
                    } else if (result.entity.noteType === LeadNoteType.Appointment) {
                        calendarEvent.start = result.entity.appointmentDateTime;
                        calendarEvent.end = result.entity.appointmentDateTime;
                    } else if (result.entity.noteType === LeadNoteType.Call) {
                        calendarEvent.start = result.entity.callOccuredDateTime;
                        calendarEvent.end = result.entity.callOccuredDateTime;
                    }

                    calendarEvent.title = result.entity.subject;

                    return new DialogResult(DialogResultActions.Edit, calendarEvent);
                }
            })
        );
    }

    getCalendarEvents(fromDateTime: moment.Moment, toDateTime: moment.Moment): Observable<CalendarEvent[]> {
        return this.clientLeadsService
            .allClientLeadNotes(
                GetAllClientLeadsNotesListQuery.fromJS({
                    fromDateTime,
                    toDateTime,
                })
            )
            .pipe(
                map((clientLeadNotes) => {
                    this.clientLeadNotes = clientLeadNotes;
                    this.transformedCalendarEvents = clientLeadNotes.map((clientLeadNote) => this._convertToCalendarEvent(clientLeadNote));
                    return this.transformedCalendarEvents;
                })
            );
    }

    private _convertToCalendarEvent(clientLeadNote: ClientLeadNoteDto): CalendarEvent {
        return new CalendarEvent(
            clientLeadNote.id,
            clientLeadNote.subject,
            clientLeadNote.noteType === LeadNoteType.Appointment
                ? clientLeadNote.appointmentDateTime
                : clientLeadNote.noteType === LeadNoteType.Call
                ? clientLeadNote.callOccuredDateTime
                : clientLeadNote.followUpDateTime,
            clientLeadNote.followUpDateTime,
            false,
            clientLeadNote.noteType === LeadNoteType.Appointment ? 'appointment-event' : clientLeadNote.noteType === LeadNoteType.Call ? 'call-event' : 'followup-event',
            ''
        );
    }
}
