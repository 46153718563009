import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BlanketOrder } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data';
import { DialogResult } from 'src/app/models/dialogResult';
import { DeliveryDateChoiceDialogComponent } from './delivery-date-choice-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DeliveryDateChoiceDialogService {
    constructor(private dialog: MatDialog) { }

    showDialog(): Observable<'recalculate' | 'setManually' | 'cancel'> {
        const dialogRef = this.dialog.open(DeliveryDateChoiceDialogComponent, {
            width: '60vw',
            maxHeight: '80vh',
            autoFocus: "false"
        });

        return dialogRef.afterClosed();
    }
}