import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ServiceTicketNoteFormDialogComponent } from './service-ticket-note-form-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    imports: [MatFormFieldModule, MatInputModule, CommonFormsModule, MaterialModule, OwlDateTimeModule, OwlNativeDateTimeModule, MatListModule, TranslateModule.forRoot()],
    declarations: [ServiceTicketNoteFormDialogComponent],
    exports: [ServiceTicketNoteFormDialogComponent],
})
export class ServiceTicketNoteFormDialogModule {}
