import { NgModule } from '@angular/core';
import { CategoryReorderDialogComponent } from './category-reorder-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [CategoryReorderDialogComponent],
    imports: [CommonFormsModule, MaterialModule, PipesModule, TranslateModule.forRoot()],
    exports: [CategoryReorderDialogComponent],
})
export class CategoryReorderDialogModule {}
