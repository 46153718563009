import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-appointment-location-type-icon',
  template: `
  <mat-icon matTooltip="{{'APPOINTMENT.VIRTUAL' | translate}}" *ngIf="isVirtual">call</mat-icon>
  <mat-icon matTooltip="{{'APPOINTMENT.PHYSICAL' | translate}}" *ngIf="isVirtual === false">home</mat-icon>
  <mat-icon matTooltip="{{'APPOINTMENT.UNKNOWNLOCATION' | translate}}" color="warn" *ngIf="showUndefinedIcon && (isVirtual === null || isVirtual === undefined)">help</mat-icon>
  `,
  styles: []
})
export class AppointmentLocationTypeIconComponent implements OnInit {

  @Input() isVirtual;
  @Input() showUndefinedIcon = true;

  constructor() { }

  ngOnInit() {
  }

}
