import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimpleListComponent } from 'src/app/models/listComponent';
import { NotifyService } from '../../../services/notify.service';
import { map } from 'rxjs/operators';
import { LocalSearchTableContainerDialog, SearchDialogResult } from '../models/searchTableContainer';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'app-local-search-table',
    templateUrl: './local-search-table.component.html',
    styleUrls: ['./local-search-table.component.scss'],
})
export class LocalSearchTableComponent<ModelType> extends SimpleListComponent<string, ModelType> implements OnInit {
    isBeingSaved: boolean;
    showActionColumns: boolean;
    title: string;

    private _result: SearchDialogResult<ModelType>;

    displayedColumns: string[];
    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: LocalSearchTableContainerDialog<ModelType>,
        public dialogRef: MatDialogRef<LocalSearchTableComponent<ModelType>>,
        confirmDialogService: ConfirmDialogService,
        notifyService: NotifyService
    ) {
        super('Clients', notifyService, confirmDialogService);

        this._result = new SearchDialogResult<ModelType>();
        this.displayedColumns = this.settings.displayProperties.map((displayProperty) =>
            displayProperty.isInTranslation ? `selectedTranslation.${displayProperty.propertyName}` : displayProperty.propertyName
        );

        if (settings.showActionColumns) {
            this.displayedColumns.push('action');
        }

        this.title = settings.title;
        this.showActionColumns = settings.showActionColumns;
    }

    deleteEntity(): Observable<void> {
        throw new Error('Method not implemented.');
    }

    loadEntities(): Observable<any[]> {
        if (this.settings.excludeList && this.settings.excludeList.length > 0) {
            return this.settings.entities.pipe(
                map((entities) => {
                    return entities.filter((entity) => this.settings.excludeList.findIndex((excludeEntity) => excludeEntity.id === entity.id) === -1);
                })
            );
        } else {
            return this.settings.entities;
        }
    }

    ngOnInit() {
        this.load();
    }

    select(entity: any) {
        this._result.isSelectedClicked = true;
        this._result.selectedEntities.push(entity);
        if (this.settings.closeOnSelect) {
            this.settings.entitySelectedObservable.next(this._result);
            this.settings.entitySelectedObservable.complete();
            this.dialogRef.close();
        }
    }

    edit(entity: any) {
        this._result.isEditClicked = true;
        this._result.entityToEdit = entity;
        this.settings.entitySelectedObservable.next(this._result);
        this.dialogRef.close();
    }

    addAndCloseDialog() {
        this._result.isAddClicked = true;
        this.settings.entitySelectedObservable.next(this._result);
        this.settings.entitySelectedObservable.complete();
        this.dialogRef.close();
    }

    done() {
        this.settings.entitySelectedObservable.next(this._result);
        this.settings.entitySelectedObservable.complete();
        this.dialogRef.close();
    }
}
