import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import {
    AppointmentsService,
    ClientsService,
    AppointmentManagementDto,
    SalesRepDto,
    AppointmentStatus,
    EditLegacyAppointmentCommand,
    AppointmentDispatchType,
    ManagementAppointmentsService,
    PutUpForReviewCommand,
    AppointmentQualificationsService,
} from 'src/app/services/api.services';
import { ActivatedRoute } from '@angular/router';
import { FormContainerService } from 'src/app/services/form-container.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BaseAppointmentFormComponent } from 'src/app/models/baseAppointmentForm';
import { FormComponentSettings } from 'src/app/models/formComponent';
import { Observable } from 'rxjs';
import { EnumListService } from 'src/app/services/enum-list.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { AppointmentDispatchService } from '../appointment-dispatch/appointment-dispatch.service';
import { SalesRepManagerService } from 'src/app/services/sales-rep-manager-service.service';
import { FixedButton, FixedFormControlsService } from 'src/app/shared/fixed-form-controls/fixed-form-controls.service';
import * as moment from 'moment';
@Component({
    selector: 'app-appointment-management-form',
    templateUrl: './appointment-management-form.component.html',
    styleUrls: ['./appointment-management-form.component.scss'],
})
export class AppointmentManagementFormComponent extends BaseAppointmentFormComponent<AppointmentManagementDto> implements OnInit, OnDestroy {
    appointmentStatuses = this.enumListService.appointmentStatuses;
    group: FormGroup;
    currentAppointmentDate: Moment;
    dispatchSalesReps: SalesRepDto[];
    doesSelectedRepHaveAccessToTablet = false;

    dateChangeConfirmed: boolean = false;
    repChangeConfirmed: boolean = false;

    fixedPutUpForReviewButton: FixedButton;

    _hasRDVModificationRole: boolean;

    constructor(
        private appointmentManagementService: ManagementAppointmentsService,
        private appointmentDispatchService: AppointmentDispatchService,
        appointmentsService: AppointmentsService,
        public route: ActivatedRoute,
        formContainerService: FormContainerService,
        public permissionService: NgxPermissionsService,
        clientsService: ClientsService,
        public enumListService: EnumListService,
        public salesRepManagerService: SalesRepManagerService,
        private appointmentQualificationsService: AppointmentQualificationsService,
        fixedFormControlService: FixedFormControlsService
    ) {
        super(
            new FormComponentSettings(true, 'appointment-management', 'Appointment'),
            appointmentsService,
            route,
            formContainerService,
            permissionService,
            clientsService,
            salesRepManagerService
        );

        if (this.permissionService.getPermission('CreateAppointmentQualification') !== undefined) {
            this.fixedPutUpForReviewButton = fixedFormControlService.setupSingleEditButton('visibility');
            this.fixedFormSub = this.fixedPutUpForReviewButton.buttonClickedObservable.subscribe(() => this.putAppointmentUpForReview());
        }
        this._hasRDVModificationRole = this.permissionService.getPermission('RDVModification') !== undefined;
    }

    @ViewChild('dispatchForm')
    public dispatchForm: TemplateRef<any>;

    ngOnInit() {
        const appointment = this.route.snapshot.data['appointment'];

        if (appointment) {
            this.currentAppointmentDate = appointment.appointmentDateTime;
        }

        this.commonInit();

        this.fixedSavedButton.disable();
        this.fixedSavedButton.isHidden = true;

        this.salesRepManagerService.salesRepList(true).subscribe((reps) => {
            this.dispatchSalesReps = reps;

            if (this.model.confirmedSalesRep) {
                this._updateDoesSelectedRepHaveAccessToTablet(this.model.confirmedSalesRep.id);
            }

            this.fixedSavedButton.enable();
            this.fixedSavedButton.isHidden = false;
        });

        this._setReadOnlyControls();

        this._addAppointmentManagementSpecificControls();

        this.formControl.get('appointmentStatus').valueChanges.subscribe((appointmentStatus: AppointmentStatus) => {
            switch (appointmentStatus) {
                case AppointmentStatus.OnStandby:
                case AppointmentStatus.Callback:
                    this.formControl.get('appointmentDateTime').enable();
                    this.formControl.get('appointmentDateTime').setValidators(null);
                    this.formControl.get('appointmentDateTime').updateValueAndValidity();
                    break;
                case AppointmentStatus.Postponed:
                    this.model.postPonedDate = this.currentAppointmentDate;
                    this.formControl.get('appointmentDateTime').enable();
                    this.formControl.get('appointmentDateTime').setValidators([Validators.required]);
                    this.formControl.get('appointmentDateTime').updateValueAndValidity();
                    break;
                case AppointmentStatus.Confirmed:
                case AppointmentStatus.Sold:
                case AppointmentStatus.Cancelled:
                case AppointmentStatus.Delivered:
                case AppointmentStatus.ShownNotSold:
                case AppointmentStatus.ShownToBeFinalized:
                case AppointmentStatus.NotShown:
                case AppointmentStatus.Refused:
                default:
                    this.formControl.get('appointmentDateTime').disable();
                    this.formControl.get('appointmentDateTime').setValidators(null);
                    this.formControl.get('appointmentDateTime').updateValueAndValidity();
                    break;
            }
        });

        this.formControl.get('appointmentDateTime').setValue(
            new Date(this.formControl.get('appointmentDateTime').value)
        )
    }

    private _setReadOnlyControls() {
        this.formControl.get('originalSalesRepId').disable();
        this.formControl.get('referredById').disable();
    }

    private _addAppointmentManagementSpecificControls() {
        this.formControl.addControl('appointmentStatus', new FormControl(this.model.appointmentStatus));
        this.formControl.addControl('cartValue', new FormControl({ value: this.model.cartValue, disabled: !this._hasRDVModificationRole }));
        this.formControl.addControl('numberOfReferences', new FormControl({ value: this.model.numberOfReferences, disabled: !this._hasRDVModificationRole }));
        this.formControl.addControl('confirmedSalesRepId', new FormControl(this.model.confirmedSalesRep ? this.model.confirmedSalesRep.id : null));
        this.formControl.get('originalSalesRepId').setValidators(null);
        this.formControl.addControl('confirmed', new FormControl(this.model.confirmed));
        this.formControl.addControl('distributorComments', new FormControl(this.model.distributorComments));
        this.formControl.addControl('statusComments', new FormControl(this.model.statusComments));

        this.formControl.get('confirmedSalesRepId').valueChanges.subscribe((salesRepId) => {
            this._updateDoesSelectedRepHaveAccessToTablet(salesRepId);
        });
    }

    private _updateDoesSelectedRepHaveAccessToTablet(salesRepId: number) {
        const selectedSalesRep = this.dispatchSalesReps.find((rep) => rep.id == salesRepId);
        if (selectedSalesRep) {
            this.doesSelectedRepHaveAccessToTablet = selectedSalesRep.tabletAccess;
        }
    }

    createNewModel() {
        return new AppointmentManagementDto();
    }

    editAppointment(command: EditLegacyAppointmentCommand): Observable<void> {
        const roughAppointmentManagement = this.formControl.value;
        command.appointment.cartValue = this.stripCurrencyString(roughAppointmentManagement.cartValue);

        if (this.formControl.get('confirmedSalesRepId').value) {
            command.appointment.confirmedSalesRep = new SalesRepDto();
            command.appointment.confirmedSalesRep.init({
                id: this.formControl.get('confirmedSalesRepId').value,
            });
        }

        command.appointment.appointmentDateTime = this.formControl.get('appointmentDateTime').value;

        if (!this._hasRDVModificationRole) {
            command.appointment.numberOfReferences = this.formControl.get('numberOfReferences').value;
            command.appointment.cartValue = this.formControl.get('cartValue').value;
        }

        return this.appointmentManagementService.edit(command);
    }

    createAppointment(command: any): Observable<string> {
        throw new Error('Method not implemented.');
    }

    onDispatchUpdateClick(event: { appointmentDispatchType: AppointmentDispatchType; isRemoteOrder: boolean }) {
        this.appointmentDispatchService.handleDispatchAppointment(this.model.callNo, event.appointmentDispatchType, event.isRemoteOrder, this.editEntity()).subscribe(
            (update) => {
                this.model.isAppointmentDispatched = update.isDispatched;
                this.model.appointmentDispatchedRecords = [update.appointmentDispatchedRecord].concat(this.model.appointmentDispatchedRecords);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    putAppointmentUpForReview() {
        var command = new PutUpForReviewCommand();
        command.callNo = this.model.callNo;
        this.appointmentQualificationsService.putUpForReview(command).subscribe(
            () => {
                this.notifyService.success('APPOINTMENTREVIEW.SUCCESS', true);
            },
            (err) => {
                this.notifyService.fail('APPOINTMENTREVIEW.FAIL', true);
            }
        );
    }
}
