import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderItemsServerSearchTableComponent } from './order-items-server-search-table.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ServerPagingTableContainerModule } from '../../server-pagination-table-container/server-paging-table-container.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [CommonModule, FormsModule, MaterialModule, ServerPagingTableContainerModule, TranslateModule],
    declarations: [OrderItemsServerSearchTableComponent],
    exports: [OrderItemsServerSearchTableComponent],
})
export class OrderItemsServerSearchTableModule {}
