import { Router } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { NgZone, OnInit, ViewChild, Directive, OnDestroy } from '@angular/core';
import { NotifyService } from '../services/notify.service';
import { FixedButton } from '../shared/fixed-form-controls/fixed-form-controls.service';
import { PaginationModel } from 'src/app/services/api.services';
import { ServerPagingTableContainerComponent } from 'src/app/shared/server-pagination-table-container/server-paging-table-container.component';
import { PagingDataSource } from './interfaces/dataSourceInterface';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { mergeMap, map } from 'rxjs/operators';

@Directive()
export abstract class ServerPaginationListComponent<IdType, ModelType, QueryType> implements OnInit, OnDestroy {
    @ViewChild('tableContainer') tableContainer: ServerPagingTableContainerComponent<ModelType>;

    dataSource: PagingDataSource<ModelType, QueryType>;
    fixedFormSub: Subscription;
    subscriptions: Subscription = new Subscription();
    fixedAddButton: FixedButton;
    fixedDownloadButton: FixedButton;
    isLoaded: boolean;

    query: any;

    public ngZone: NgZone;

    constructor(public router: Router, public entityName: string, public notifyService: NotifyService, public confirmDialogService: ConfirmDialogService) { }

    abstract loadDataSource(): PagingDataSource<ModelType, QueryType>;
    abstract loadEntities(paginationModel: PaginationModel);
    abstract deleteEntity(id: IdType): Observable<void>;

    load() {
        this.dataSource = this.loadDataSource();

        this.subscriptions.add(this.dataSource.loading.subscribe((isLoading) => {
            this.isLoaded = !isLoading;
        }));

        this.subscriptions.add(this.dataSource.loadingFailure.subscribe((error) => {
            this.tableContainer.isFailed = true;
            this.notifyService.fail('Failed to load list');
        }));

        this.subscriptions.add(this.dataSource.paginationObservable().subscribe((paginationModel) => {
            if (paginationModel) {
                if (this.tableContainer.length !== paginationModel.count) {
                    this.tableContainer.length = paginationModel.count;
                    this.tableContainer.paginationModel.page = paginationModel.page;
                }
            }
        }));

        this.tableContainer.dataSource = this.dataSource;
        this.loadEntities(this.tableContainer.generatePaginationModel());

        this.subscriptions.add(this.tableContainer.retryReload.subscribe(() => {
            this.tableContainer.isFailed = false;
            this.loadEntities(this.tableContainer.generatePaginationModel());
        }));
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    create() {
        this.router.navigate([this.entityName + '/create']);
    }

    clone(id: IdType) {
        this.router.navigate([this.entityName + '/clone/', id]);
    }

    edit(id: IdType) {
        this.router.navigate([this.entityName + '/edit/', id]);
    }

    details(id: IdType) {
        this.router.navigate([this.entityName + '/details/', id]);
    }

    delete(id: IdType) {
        this.subscriptions.add(this.confirmDialogService
            .showDialog('You will not be able to revert this')
            .pipe(
                mergeMap((result) => {
                    if (result) {
                        return this.deleteEntity(id).pipe(map(() => true));
                    }
                    return of(false);
                })
            )
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }
                    this.load();
                    this.notifyService.success(`${this.entityName} has been deleted`);
                },
                (err) => {
                    this.notifyService.fail(`Failed to delete ${this.entityName}`);
                }
            )
        );
    }
}
