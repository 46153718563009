import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { FileUploadDialogComponent } from './file-upload-dialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    imports: [CommonFormsModule, MaterialModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    declarations: [FileUploadDialogComponent],
    exports: [FileUploadDialogComponent],
})
export class FileUploadDialogModule {}
