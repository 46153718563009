import { Component, Input, TemplateRef } from '@angular/core';
import { CommonComponent } from 'src/app/models/formComponent';
import { AppointmentDto, AppointmentClientGender, BudgetRange, FreezerSize, FreezerStockAmount, LanguageType, LivingArrangement, SalesRepDto, AgeRange, ClientLeadSourceTypeDto } from 'src/app/services/api.services';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormGroup } from '@angular/forms';
import { EnumListService } from 'src/app/services/enum-list.service';

@Component({
    selector: 'app-common-appointment-form',
    templateUrl: './common-appointment-form.component.html',
    styleUrls: ['./common-appointment-form.component.scss'],
})
export class CommonAppointmentFormComponent extends CommonComponent {
    readonly ratings = [0, 5, 10, 15, 20];

    gender = AppointmentClientGender;
    ageRange = AgeRange;
    budgetRange = BudgetRange;
    freezerSize = FreezerSize;
    freezerStockAmount = FreezerStockAmount;
    languageType = LanguageType;
    livingArrangement = LivingArrangement;
    appointmentTypes = this.enumListService.appointmentTypes;

    @Input() clientLeadSource: ClientLeadSourceTypeDto;
    @Input() Lang: boolean;

    @Input() disableImportButton: boolean;
    @Input() disableForm: boolean;
    @Input() formGroup: FormGroup;
    @Input() model: AppointmentDto;
    @Input() salesReps: SalesRepDto[];
    @Input() rating: number;
    @Input() isManagement: boolean;
    @Input() isNew: boolean;

    @Input() notInterestedModeOn: boolean;

    constructor(
        protected permissionService: NgxPermissionsService,
        public enumListService: EnumListService
    ) {
        super();

        const rawUser = localStorage.getItem('user');

        if (rawUser !== null) {
            const user = JSON.parse(rawUser);
            if (user.assignedRoles) {
                this.permissionService.loadPermissions(user.assignedPolicies);
            }
        }
    }

    getAppointmentDisplayName() {
        return this.enumListService.findDisplayName(this.enumListService.appointmentTypes, this.model.appointmentType);
    }

    petTypeSelectionRequired(formControl: FormGroup) {
        const mustSelectPetType = formControl.value['numberOfPets'] > 0 &&
            !Object.keys(formControl.value)
                .filter(key => key.startsWith('petType'))
                .some(key => formControl.value[key]);

        return mustSelectPetType;
    }

    petTypesExceededNumberOfPets(formControl: FormGroup) {
        const numberOfPetTypesSelected = Object.keys(formControl.value)
            .filter(key => key.startsWith('petType') && formControl.value[key]).length;

        return numberOfPetTypesSelected > formControl.value['numberOfPets'];
    }
}
