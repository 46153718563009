import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'orderBy',
    pure: false,
})
export class OrderByPipe implements PipeTransform {
    transform(records: Array<any>, args?: any): any {
        if (!records || records.length == 0) return records;
        
        return records.sort(function (a, b) {
            if (args.nestedProperty) {
                if (a[args.property][args.nestedProperty].toLowerCase() < b[args.property][args.nestedProperty].toLowerCase()) {
                    return -1 * args.direction;
                } else if (a[args.property][args.nestedProperty].toLowerCase() > b[args.property][args.nestedProperty].toLowerCase()) {
                    return 1 * args.direction;
                } else {
                    return 0;
                }
            } else {
                if (a[args.property] < b[args.property]) {
                    return -1 * args.direction;
                } else if (a[args.property] > b[args.property]) {
                    return 1 * args.direction;
                } else {
                    return 0;
                }
            }
        });
    }
}
