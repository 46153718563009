import { NgModule } from '@angular/core';
import { ClientLeadNotesDialogComponent } from './client-lead-notes-dialog.component';
import { ClientLeadFormModule } from '../client-lead-form/client-lead-form.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [ClientLeadNotesDialogComponent],
    imports: [CommonFormsModule, ClientLeadFormModule],
    exports: [ClientLeadNotesDialogComponent],
})
export class ClientLeadNotesDialogModule {}
