import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { TableContainerModule } from 'src/app/shared/table-container/table-container.module';
import { LocalSearchTableComponent } from './local-search-table.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MaterialModule, TableContainerModule, TranslateModule.forRoot()],
    declarations: [LocalSearchTableComponent],
    exports: [LocalSearchTableComponent],
})
export class LocalSearchTableModule {}
