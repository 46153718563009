import { Component, OnInit, Input } from '@angular/core';
import { MyErrorStateMatcher } from '../../../models/myErrorStateMatcher';
import { FormGroup } from '../../../../../node_modules/@angular/forms';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss']
})
export class QuantityComponent implements OnInit {

  mask: string;

  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() disabled = false;
  @Input() min: number = 0;
  @Input() max: number = 999999;
  @Input() allowDecimal = true;

  matcher = new MyErrorStateMatcher();

  constructor() { }

  ngOnInit() {
    this.mask = this.allowDecimal ? '0*.######' : '0*';

    if (this.disabled) {
      this.group.get(this.controlName).disable();
    }
  }

}
