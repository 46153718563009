import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceTicketPriority } from 'src/app/services/api.services';

@Pipe({
    name: 'serviceTicketPriorityDescriptionPipe',
    pure: false,
})
export class ServiceTicketPriorityDescriptionPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: any, args?: any): any {
        let key = '';
        switch (value) {
            case ServiceTicketPriority.Urgent:
                key = `(${this.translateService.instant('SERVICETICKETPRIORITY.URGENTDESCRIPTION')})`;
                break;
            case ServiceTicketPriority.High:
                key = `(${this.translateService.instant('SERVICETICKETPRIORITY.HIGHDESCRIPTION')})`;
                break;
            case ServiceTicketPriority.Medium:
                key = `(${this.translateService.instant('SERVICETICKETPRIORITY.MEDIUMDESCRIPTION')})`;
                break;
            case ServiceTicketPriority.Low:
                key = `(${this.translateService.instant('SERVICETICKETPRIORITY.LOWDESCRIPTION')})`;
                break;
            case null:
                key = '';
                break;
        }
        return key;
    }
}
