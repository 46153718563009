import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { CategorySelectorDialogComponent } from './category-selector-dialog.component';

@NgModule({
    declarations: [CategorySelectorDialogComponent],
    imports: [CommonFormsModule, MaterialModule, PipesModule, TranslateModule.forRoot()],
    exports: [CategorySelectorDialogComponent],
})
export class CategorySelectorDialogModule {}
