import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { EditServiceTicketCommand, ServiceTicketCategoryDto, ServiceTicketDto, ServiceTicketsService } from 'src/app/services/api.services';
import { EnumListService, EnumWrap } from 'src/app/services/enum-list.service';
import { NotifyService } from 'src/app/services/notify.service';
import { CommonComponent } from 'src/app/models/formComponent';

@Component({
    selector: 'app-service-ticket-form-dialog',
    templateUrl: './service-ticket-form-dialog.component.html',
    styleUrls: ['./service-ticket-form-dialog.component.scss'],
})
export class ServiceTicketFormDialogComponent extends CommonComponent implements OnInit {
    statuses: EnumWrap[];
    formGroup: FormGroup;
    isLoading: boolean = false;

    categories: ServiceTicketCategoryDto[];
    priorities: EnumWrap[];
    public minDate = new Date();

    constructor(
        private dialogRef: MatDialogRef<ServiceTicketFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServiceTicketDto,
        private enumListService: EnumListService,
        private formBuilder: FormBuilder,
        public serviceTicketsService: ServiceTicketsService,
        private notifyService: NotifyService
    ) {
        super();
    }

    ngOnInit(): void {
        this.serviceTicketsService.serviceTicketCategories().subscribe((categories) => {
            this.categories = categories;
        });
        this.statuses = this.enumListService.serviceTicketStatuses;
        this.priorities = this.enumListService.serviceTicketPriorities;
        this.setupFormControl();
    }

    setupFormControl() {
        this.formGroup = this.formBuilder.group({
            id: [this.data.id],
            subject: [this.data.subject, [Validators.required, Validators.maxLength(80)]],
            description: [this.data.description, [Validators.required, Validators.maxLength(1024)]],
            categoryId: [this.data.categoryId, [Validators.required]],
            priority: [this.data.priority, [Validators.required]],
            status: [this.data.status, [Validators.required]],
            timeEstimate: [this.data.timeEstimate, [Validators.required]],
            deadline: [this.data.deadline?.toDate(), [Validators.required]],
            note: [''],
        });
    }
    private _validate() {
        const isPriorityChanged = this.getFormControlValue(this.formGroup, 'priority') != this.data.priority;
        const isStatusChanged = this.getFormControlValue(this.formGroup, 'status') != this.data.status;

        var noteControl = this.formGroup.get('note');
        if (isPriorityChanged || isStatusChanged) {
            noteControl.setValidators(Validators.required);
        } else {
            noteControl.clearValidators();
        }

        noteControl.updateValueAndValidity();
    }

    onSubmit() {
        this._validate();
        this.isLoading = true;
        if (this.formGroup.invalid) {
            this.isLoading = false;
            this.validateAllFormFields(this.formGroup);
            return;
        }

        const command = new EditServiceTicketCommand();
        command.init(this.formGroup.value);
        this.serviceTicketsService.edit(this.data.id, command).subscribe(
            () => {
                this.notifyService.success('Service ticket has been updated');
                const categoryName = this.categories.find((x) => x.id == command.categoryId)?.name;
                this.dialogRef.close(new DialogResult(DialogResultActions.Edit, { ...command, categoryName }));
            },
            () => {
                this.notifyService.fail('Something went wrong while updating service ticket');
                this.dialogRef.close();
            },
            () => (this.isLoading = false)
        );
    }
}
