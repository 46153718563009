import { NgModule } from '@angular/core';
import { FindOrderReferencesDialogComponent } from './find-order-references-dialog.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    declarations: [FindOrderReferencesDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    exports: [FindOrderReferencesDialogComponent],
})
export class FindOrderReferencesDialogModule {}
