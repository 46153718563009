import { Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { FederalDnclDto, FederalDnclService } from '../services/api.services';

export class AppValidators {
    static phone: ValidatorFn = Validators.pattern('^[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{4}$');

    static checkPhoneNumberInDncl(federalDnclService: FederalDnclService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value) return of(null);
            return federalDnclService.federalDncl(control.value).pipe(
                debounceTime(200),
                distinctUntilChanged(),
                take(1),
                map((federalDncl: FederalDnclDto) => {
                    return federalDncl ? { existInDncl: true } : null;
                })
            );
        };
    }
}
