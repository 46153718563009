import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-client-lead-form',
    templateUrl: './client-lead-form.component.html',
    styleUrls: ['./client-lead-form.component.scss']
})
export class ClientLeadFormComponent implements OnInit, OnDestroy {

    isEdit: boolean;

    constructor(private router: Router,  private route: ActivatedRoute,) {}
    ngOnInit(): void {
        const clientLead = this.route.snapshot.data['clientLead'];
        if (clientLead) {
            this.isEdit = true;
        }
    }   
        
    ngOnDestroy(): void {
    }
   
}
