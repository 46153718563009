import { NgModule } from '@angular/core';
import { ButtonListDialogComponent } from './button-list-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ButtonListDialogComponent],
    imports: [CommonFormsModule, MaterialModule, TranslateModule],
    exports: [ButtonListDialogComponent],
})
export class ButtonListDialogModule {}
