import { CategoryDto, CategoriesService } from "./api.services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CategoriesCacheService {
    private categories: Observable<CategoryDto[]>;

    constructor(private categoriesService: CategoriesService) { }

    categoriesList(): Observable<CategoryDto[]> {
        if (!this.categories) {
            this.categories = this.loadCategories().pipe(
                shareReplay(1)
            );
        }

        return this.categories;
    }

    private loadCategories(): Observable<CategoryDto[]> {
        return this.categoriesService.allCategories();
    }
}