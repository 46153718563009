import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Address } from '../../services/api.services';
import { CountriesService } from '../../services/countries.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../../models/myErrorStateMatcher';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  @Input() parentGroup: FormGroup;
  @Input() address: Address;

  group: FormGroup;

  countries: string[];

  constructor(private formBuilder: FormBuilder, private countriesService: CountriesService) {
    this.countries = countriesService.Countries;
  }

  ngOnInit() {
    if (this.address === null) {
      this.address = new Address();
      this.address.id = 0;
      this.address.province = "Quebec";
      this.address.country = "Canada";
    }

    this.group = this.formBuilder.group({
      id: [this.address.id],
      street: [this.address.street, []],
      city: [this.address.city, []],
      postalCode: [this.address.postalCode, [Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)]],
      province: [this.address.province, []],
      country: [this.address.country, []]
    });

    this.parentGroup.addControl('address', this.group);
  }
  toUpperCase(conformedValue: string): string {
    return conformedValue.toUpperCase();
  }

  isFieldValid(field: string) {
    return !this.group.get(field).valid && this.group.get(field).touched;
  }
}
