import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogResult } from 'src/app/models/dialogResult';
import { FileUploadDialogComponent } from './file-upload-dialog.component';
import { FileUploadSetting } from './file-upload-setting';

@Injectable({
    providedIn: 'root',
})
export class FileUploadDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: FileUploadSetting): Observable<DialogResult<any>> {
        const dialogRef = this.dialog.open(FileUploadDialogComponent, {
            width: '800px',
            data: settings,
            disableClose: true,
        });

        return dialogRef.afterClosed();
    }
}
