// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  color: #f44336;
  font-size: 11px !important;
}

:host mat-radio-button {
  margin-right: 10px;
}

:host mat-checkbox {
  margin-right: 10px;
}

#clientNoAppointment input[type=number]::-webkit-inner-spin-button,
#clientNoAppointment input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
