import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogResult } from './confirm-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) { }

  showDialog(message: string, title = 'CONFIRM', yesButtonText = 'YES', noButtonText = 'NO', showActions = true): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.yesButtonText = yesButtonText;
    dialogRef.componentInstance.noButtonText = noButtonText;
    dialogRef.componentInstance.showActions = showActions;

    return dialogRef.afterClosed();
  }


  showDialogWithLoader(message: string, observableLoaded: Observable<any>, title = 'CONFIRM', yesButtonText = 'YES', noButtonText = 'NO'): Observable<ConfirmDialogResult> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true 
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.yesButtonText = yesButtonText;
    dialogRef.componentInstance.noButtonText = noButtonText;
    dialogRef.componentInstance.displayLoadingUntilLoaded = true;
    dialogRef.componentInstance.observableLoad = observableLoaded;


    return dialogRef.afterClosed();


  }
}
