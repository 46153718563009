import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { PushToOptimorouteDialogComponent } from './push-to-optimoroute-dialog.component';

@NgModule({
    declarations: [PushToOptimorouteDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    exports: [PushToOptimorouteDialogComponent],
})
export class PushToOptimorouteDialogModule { }
