import { Injectable } from "@angular/core";


export interface ComponentCanDeactivate {
    canDeactivate: () => boolean;
  }
  
  @Injectable()
  export class PendingChangesGuard  {
    canDeactivate(component: ComponentCanDeactivate): boolean {
      if (!component.canDeactivate()) {
        return window.confirm('You have unsaved changes, do you want to discard your changes?'); 
      } 
      return true;
    };
  }