import { Component, OnInit, Inject } from '@angular/core';
import {
    SalesRepDto,
    ConfirmAppointmentCommand,
    ManagementAppointmentsService,
    AppointmentManagementDto,
    AppointmentDispatchType,
    AppointmentQualificationsService,
    PutUpForReviewCommand,
    AppointmentStatus,
} from 'src/app/services/api.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumListService } from 'src/app/services/enum-list.service';
import { NotifyService } from 'src/app/services/notify.service';
import { mergeMap } from 'rxjs/operators';
import { CommonComponent } from 'src/app/models/formComponent';
import { AppointmentDispatchService } from 'src/app/admin-pages/appointment-management/appointment-dispatch/appointment-dispatch.service';
import { SalesRepManagerService } from 'src/app/services/sales-rep-manager-service.service';
import { PastAppointmentsDisplayDialogService } from '../past-appointments-display-dialog/past-appointments-display-dialog.service';
import { NgxPermissionsService } from 'ngx-permissions';
import * as moment from 'moment';

export enum DialogAction {
    ConfirmDispatchAction,
    DisqualifyAction,
}

@Component({
    selector: 'app-appointment-dispatch-confirm-dialog',
    templateUrl: './appointment-dispatch-confirm-dialog.component.html',
    styleUrls: ['./appointment-dispatch-confirm-dialog.component.scss'],
})
export class AppointmentDispatchConfirmDialogComponent extends CommonComponent implements OnInit {
    salesReps: SalesRepDto[];
    doesSelectedRepHaveAccessToTablet = false;
    appointmentStatuses = this.enumListService.appointmentStatuses;

    private _appointmentLocation: string;
    _hasRDVModificationRole: boolean;

    get appointmentLocation(): string {
        return this._appointmentLocation;
    }

    set appointmentLocation(value: string) {
        this._appointmentLocation = value;
        this.model.isVirtual = value === 'virtual';
    }

    model: ConfirmAppointmentCommand;

    isSaving: boolean;

    salesRepsLoading: boolean = true;

    appointmentDateTime: Date = new Date();

    constructor(
        @Inject(MAT_DIALOG_DATA) public details: AppointmentManagementDto,
        private dialogRef: MatDialogRef<AppointmentDispatchConfirmDialogComponent>,
        private salesRepManagerService: SalesRepManagerService,
        public enumListService: EnumListService,
        private appointmentDispatchService: AppointmentDispatchService,
        private managementAppointmentsService: ManagementAppointmentsService,
        private notifyService: NotifyService,
        private pastAppointmentsDisplayDialogService: PastAppointmentsDisplayDialogService,
        private appointmentQualificationsService: AppointmentQualificationsService,
        public permissionService: NgxPermissionsService
    ) {
        super();
        this._hasRDVModificationRole = this.permissionService.getPermission('RDVModification') !== undefined;

        let confirmedSalesRep = details.confirmedSalesRep;
        this.salesRepManagerService.salesRepList(true).subscribe((reps) => {
            this.salesReps = reps;

            if (confirmedSalesRep && confirmedSalesRep.id) {
                this.updateDoesSelectedRepHaveAccessToTablet(confirmedSalesRep);
            }
            this.salesRepsLoading = false;
        });

        this.model = new ConfirmAppointmentCommand();
        this.model.callNo = details.callNo;
        this.model.appointmentStatus = details.appointmentStatus;
        this.model.postPonedDate = details.appointmentDateTime;
        this.model.appointmentDateTime = details.appointmentDateTime;
        this.model.confirmedSalesRep = confirmedSalesRep;
        this.model.statusComments = details.statusComments;
        this.model.distributorComments = details.distributorComments;
        this.model.comments = details.comments;
        this.appointmentLocation = details.isVirtual ? 'virtual' : 'physical';

        this.appointmentDateTime = details.appointmentDateTime.toDate();
    }

    ngOnInit() { }

    saveAppointment() {
        this.model.appointmentDateTime = moment(this.appointmentDateTime);

        return this.managementAppointmentsService.confirm(this.model);
    }

    confirmAppointment() {
        this.isSaving = true;
        this.saveAppointment()
            .pipe(
                mergeMap((callNo) => {
                    return this.managementAppointmentsService.legacyAppointmentManagement(callNo);
                })
            )
            .subscribe(
                (appointment) => {
                    this.notifyService.success('DISPATCHCONFIRM.SUCCESS', true);
                    this.dialogRef.close({ action: DialogAction.ConfirmDispatchAction, appointment: appointment });
                    this.isSaving = false;
                },
                (err) => {
                    this.notifyService.fail('DISPATCHCONFIRM.FAIL', true);
                    this.isSaving = false;
                }
            );
    }

    onDispatchUpdate(event: { appointmentDispatchType: AppointmentDispatchType; isRemoteOrder: boolean }) {
        this.appointmentDispatchService.handleDispatchAppointment(this.model.callNo, event.appointmentDispatchType, event.isRemoteOrder, this.saveAppointment()).subscribe(
            (update) => {
                this.details.isAppointmentDispatched = update.isDispatched;
                this.details.appointmentDispatchedRecords = [update.appointmentDispatchedRecord].concat(this.details.appointmentDispatchedRecords);
            },
            (error) => console.log(error)
        );
    }

    updateDoesSelectedRepHaveAccessToTablet(salesRep: SalesRepDto) {
        const selectedSalesRep = this.salesReps.find((rep) => rep.id == salesRep.id);
        if (selectedSalesRep) {
            this.doesSelectedRepHaveAccessToTablet = selectedSalesRep.tabletAccess;
        }
    }

    viewPastAppointments(clientNo: number) {
        this.managementAppointmentsService
            .pastAppointmentsList(clientNo)
            .pipe(mergeMap((pastAppointments) => this.pastAppointmentsDisplayDialogService.showDialog(pastAppointments)))
            .subscribe();
    }

    putAppointmentUpForReview(callNo: number) {
        var command = new PutUpForReviewCommand();
        command.callNo = callNo;
        this.appointmentQualificationsService.putUpForReview(command).subscribe(
            () => {
                this.notifyService.success('APPOINTMENTREVIEW.SUCCESS', true);
                this.dialogRef.close({ action: DialogAction.DisqualifyAction, appointment: null });
            },
            (err) => {
                this.notifyService.fail('APPOINTMENTREVIEW.FAIL', true);
                this.dialogRef.close();
            }
        );
    }
}
