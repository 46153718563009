import { Injectable } from '@angular/core';
import { AccountManageService } from './accountManage.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  constructor(private accountManageService: AccountManageService, private languageService: LanguageService) { }

  handleInvalidRequest() {
    this.accountManageService.logout().subscribe(() => {
      this.accountManageService.gotoLogin();
    });
  }

  selectAnyTranslations(item: any) {
    this.languageService.selectAnyTranslations(item);
  }
}
