import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonComponent } from 'src/app/models/formComponent';

@Component({
  selector: 'app-circuit-order-batch-move-dialog',
  templateUrl: './circuit-order-batch-move-dialog.component.html',
  styleUrls: ['./circuit-order-batch-move-dialog.component.scss']
})
export class CircuitOrderBatchMoveDialogComponent extends CommonComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CircuitOrderBatchMoveDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      dropLocation: []
    });
  }

  onSubmit() {
    this.validateAllFormFields(this.formGroup);
    if (!this.formGroup.valid) return;

    this.dialogRef.close({
      dropLocation: this.formGroup.get('dropLocation').value,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

}
