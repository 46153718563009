import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FixedFormControlsService, ControlMode } from 'src/app/shared/fixed-form-controls/fixed-form-controls.service';
import { NoteFormDialogService } from 'src/app/shared/dialog/note-form-dialog/note-form-dialog.service';
import { ClientLeadNoteDto, LeadNoteType, ClientLead, ClientLeadsService } from 'src/app/services/api.services';
import { ActivatedRoute } from '@angular/router';
import { NotifyService } from 'src/app/services/notify.service';
import { DialogResultActions } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { flatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

export class NoteWrapper {
    constructor(public icon: string, public color: string, public position: string) {}
}

@Component({
    selector: 'app-client-lead-notes',
    templateUrl: './client-lead-notes.component.html',
    styleUrls: ['./client-lead-notes.component.scss'],
})
export class ClientLeadNotesComponent implements OnInit, OnDestroy {
    fixedFormSub: any;
    clientLeadNotes: ClientLeadNoteDto[];

    isLoading = true;

    ratings = [1, 2, 3, 4, 5];

    noteWrapperTypes = {};

    @Input() clientLeadId: number;
    @Input() hideFixedButton: boolean;
    @Input() allowModify: boolean = true;

    constructor(
        private fixedFormControlsService: FixedFormControlsService,
        private noteFormDialogService: NoteFormDialogService,
        private route: ActivatedRoute,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private clientLeadsService: ClientLeadsService
    ) {}

    ngOnInit() {
        if (!this.hideFixedButton) {
            const fixedAddButton = this.fixedFormControlsService.setupSingleAddButton();
            this.fixedFormSub = fixedAddButton.buttonClickedObservable.subscribe(() => {
                this.addNote();
            });
        }

        if (!this.clientLeadId) {
            this.clientLeadId = this.route.parent.snapshot.data['clientLead'].id;
        }

        this.noteWrapperTypes[LeadNoteType.Comment] = new NoteWrapper('comment', 'success', 'left');
        this.noteWrapperTypes[LeadNoteType.FollowUp] = new NoteWrapper('alarm_on', 'danger', 'right');
        this.noteWrapperTypes[LeadNoteType.Appointment] = new NoteWrapper('insert_invitation', 'warning', 'right');
        this.noteWrapperTypes[LeadNoteType.Call] = new NoteWrapper('phone', 'primary', 'right');
        this.noteWrapperTypes[LeadNoteType.Other] = new NoteWrapper('help', 'warning', 'left');
        this.noteWrapperTypes[LeadNoteType.NotInterested] = new NoteWrapper('not_interested', 'warning', 'left');
        this.noteWrapperTypes[LeadNoteType.Unbookable] = new NoteWrapper('unbookable', 'danger', 'left');

        this.clientLeadsService.clientLeadNotes(this.clientLeadId).subscribe(
            (clientLeadNotes) => {
                this.clientLeadNotes = clientLeadNotes;
                this.isLoading = false;
            },
            (error) => {
                console.log(error);
                this.isLoading = false;
            }
        );
    }

    addNote() {
        const dialogSettings = new NoteFormDialogSettings<ClientLeadNoteDto>();
        dialogSettings.entityId = this.clientLeadId;
        this.noteFormDialogService.showDialog(dialogSettings).subscribe(
            (clientLeadNote) => {
                if (!clientLeadNote) {
                    return;
                }

                if (clientLeadNote.action === DialogResultActions.Create) {
                    this.clientLeadNotes.unshift(clientLeadNote.entity);
                }
            },
            (error) => {
                this.notifyService.fail('Failed to add note to this lead');
                console.log(error);
            }
        );
    }

    editNote(clientLeadNoteViewModel: ClientLeadNoteDto) {
        const dialogSettings = new NoteFormDialogSettings<ClientLeadNoteDto>();
        dialogSettings.entityId = this.clientLeadId;
        dialogSettings.isEdit = true;
        dialogSettings.model = ClientLeadNoteDto.fromJS(clientLeadNoteViewModel);
        dialogSettings.allowNoteTypeSelection = false;
        this.noteFormDialogService.showDialog(dialogSettings).subscribe(
            (dialogResult) => {
                if (!dialogResult) {
                    return;
                }
                const clientLeadNoteIndex = this.clientLeadNotes.findIndex((t) => t.id === clientLeadNoteViewModel.id);
                if (dialogResult.action === DialogResultActions.Edit) {
                    this.clientLeadNotes[clientLeadNoteIndex] = dialogResult.entity;
                } else if (dialogResult.action === DialogResultActions.Delete) {
                    this.clientLeadNotes.splice(clientLeadNoteIndex, 1);
                }
            },
            (error) => {
                this.notifyService.fail('Failed to edit note');
                console.log(error);
            }
        );
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe();
        }
    }

    deleteNote(clientLeadNoteViewModel: ClientLeadNoteDto) {
        this.confirmDialogService
            .showDialog('CONFIRMDELETE', 'DELETE')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        return this.clientLeadsService.deleteClientLeadNote(clientLeadNoteViewModel.id).pipe(
                            map(
                                (p) => {
                                    this.clientLeadNotes.splice(
                                        this.clientLeadNotes.findIndex((c) => c.id === clientLeadNoteViewModel.id),
                                        1
                                    );
                                    this.notifyService.success('Note has been deleted from client lead');
                                },
                                (error) => {
                                    this.notifyService.fail('Failed to delete note from client lead');
                                }
                            )
                        );
                    } else {
                        return of();
                    }
                })
            )
            .subscribe(() => {});
    }
}
