import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductReorderDto } from 'src/app/services/api.services';
import { ProductReorderDialogComponent } from './product-reorder-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ProductReorderDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(productReorderData: ProductReorderDto) {
        const dialogRef = this.dialogRef.open(ProductReorderDialogComponent, {
            width: '800px',
            data: productReorderData,
        });

        return dialogRef.afterClosed();
    }
}
