import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult } from 'src/app/models/dialogResult';
import { ServiceTicketDto, ServiceTicketStatus } from 'src/app/services/api.services';
import { Observable } from 'rxjs';
import { ServiceTicketFormDialogComponent } from './service-ticket-form-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ServiceTicketFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(serviceTicket: ServiceTicketDto): Observable<DialogResult<ServiceTicketStatus>> {
        const dialogRef = this.dialog.open(ServiceTicketFormDialogComponent, {
            width: '900px',
            data: serviceTicket,
            disableClose: true,
        });

        return dialogRef.afterClosed();
    }
}
