import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentTypeDialogComponent } from './appointment-type-dialog.component';
import { Observable } from 'rxjs';
import { DialogResult } from './../../../models/dialogResult';
import { AppointmentDto } from 'src/app/services/api.services';

@Injectable({
    providedIn: 'root',
})
export class AppointmentTypeDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: AppointmentDto): Observable<DialogResult<string>> {
        const dialogRef = this.dialog.open(AppointmentTypeDialogComponent, {
            width: '450px',
            data: settings,
        });

        return dialogRef.afterClosed();
    }
}
