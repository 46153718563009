import { Directive, ElementRef, OnInit, EventEmitter, Output } from '@angular/core';
import { AddressDto } from 'src/app/services/api.services';

@Directive({
    selector: '[appGooglePlaces]',
})
export class GooglePlacesDirective implements OnInit {
    @Output() onSelect: EventEmitter<AddressDto> = new EventEmitter();
    private element: HTMLInputElement;

    constructor(private elRef: ElementRef) {
        //elRef will get a reference to the element where

        //the directive is placed
        this.element = elRef.nativeElement;
    }

    getAddress(place): AddressDto {
        const address = new AddressDto();

        address.street = '';
        //@params: place - Google Autocomplete place object
        //@returns: location_obj - An address object in human readable format
        let location_obj = {};
        for (let i in place.address_components) {
            let item = place.address_components[i];

            console.log(item);

            location_obj['formatted_address'] = place.formatted_address;
            if (item['types'].indexOf('locality') > -1) {
                address.city = item['long_name'];
            } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
                address.province = item['long_name'];
            } else if (item['types'].indexOf('street_number') > -1) {
                address.street = item['short_name'];
            } else if (item['types'].indexOf('route') > -1) {
                address.street += ` ${item['short_name']}`;
            } else if (item['types'].indexOf('country') > -1) {
                address.country = item['long_name'];
            } else if (item['types'].indexOf('postal_code') > -1) {
                address.postalCode = item['short_name'];
            }
        }
        return address;
    }

    ngOnInit() {
        var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(44.200993, -79.242677), new google.maps.LatLng(50.606184, -60.560718));

        var options = {
            bounds: defaultBounds,
            componentRestrictions: { country: 'ca' },
        };
        const autocomplete = new google.maps.places.Autocomplete(this.element, options);

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            //Emit the new address object for the updated place
            this.onSelect.emit(this.getAddress(autocomplete.getPlace()));
        });
    }
}
