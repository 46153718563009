import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-date-choice-dialog',
  templateUrl: './delivery-date-choice-dialog.component.html',
  styleUrl: './delivery-date-choice-dialog.component.scss'
})
export class DeliveryDateChoiceDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeliveryDateChoiceDialogComponent>
  ) { }

  onRecalculate(): void {
    this.dialogRef.close('recalculate');
  }

  onSetManually(): void {
    this.dialogRef.close('setManually');
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }
}
