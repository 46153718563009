import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryReorderDto } from 'src/app/services/api.services';
import { CategoryReorderDialogComponent } from './category-reorder-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class CategoryReorderDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(categoryReorderData: Array<CategoryReorderDto>) {
        const dialogRef = this.dialogRef.open(CategoryReorderDialogComponent, {
            width: '800px',
            data: categoryReorderData,
        });

        return dialogRef.afterClosed();
    }
}
