import { NgModule } from '@angular/core';
import { ProgressDialogComponent } from './progress-dialog.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';

@NgModule({
    declarations: [ProgressDialogComponent],
    imports: [CommonFormsModule],
    exports: [ProgressDialogComponent],
})
export class ProgressDialogModule {}
