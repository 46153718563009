import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientLeadNotesComponent } from '../client-lead-form/client-lead-notes/client-lead-notes.component';

@Component({
    selector: 'app-client-lead-notes-dialog',
    templateUrl: './client-lead-notes-dialog.component.html',
    styleUrls: ['./client-lead-notes-dialog.component.scss'],
})
export class ClientLeadNotesDialogComponent implements OnInit {
    @ViewChild(ClientLeadNotesComponent, {static: true}) clientLeadNoteList: ClientLeadNotesComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { clientLeadId: number }
    ) {}

    ngOnInit() {}
}
