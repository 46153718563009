import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { ClientNextDeliveryTaskNoteDto } from 'src/app/services/api.services';
import { Observable } from 'rxjs';
import { ClientNextDeliveryNoteFormDialogComponent } from './client-next-delivery-note-form-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ClientNextDeliveryNoteFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: NoteFormDialogSettings<ClientNextDeliveryTaskNoteDto>): Observable<DialogResult<ClientNextDeliveryTaskNoteDto>> {
        const dialogRef = this.dialog.open(ClientNextDeliveryNoteFormDialogComponent, {
            width: '800px',
            data: setting,
        });

        return dialogRef.afterClosed();
    }
}
