import { Injectable } from '@angular/core';
import { Language } from '../models/language';
import { ReplaySubject } from 'rxjs';

export interface ITranslation {
  culture;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageObservable: ReplaySubject<Language> = new ReplaySubject<Language>();
  selectedLanguage: Language;
  supportedLanguages = [
    new Language('en-CA', 'English', 'EN'),
    new Language('fr-CA', 'French', 'FR')
  ];
  userSelectedLanguage = this.supportedLanguages[0];

  constructor() {
    this.selectedLanguage = this.supportedLanguages[0];
   }

  changeLanguage(language: Language) {
    this.selectedLanguage = language;
    this.languageObservable.next(language);
  }

  toggleLanguage() {
    if (this.supportedLanguages[0].culture === this.selectedLanguage.culture) {
      this.changeLanguage(this.supportedLanguages[1]);
    } else {
      this.changeLanguage(this.supportedLanguages[0]);
    }
  }

  lookByCulture(culture: string): Language {
    return this.supportedLanguages.find(language => language.culture === culture);
  }

  initTranslations<T>(translations: ITranslation[], createTran: () => ITranslation) {
    for (let index = 0; index < this.supportedLanguages.length; index++) {
      const translationToUse = this.supportedLanguages[index];
      const translation = createTran();
      translation.culture = translationToUse.culture;
      translations.push(translation);
    }

  }

  selectAnyTranslations(item: any) {
    if (!item || item === null) {
      return;
    }

    if (item.constructor === Array) {
      for (const arrItem of item) {
              this.selectAnyTranslations(arrItem);       
      }
    } else {
      for (const property in item) {
        if (property === 'translations') {
          item.selectedTranslation = item.translations.find(translation => 
            translation.culture === this.userSelectedLanguage.culture);  
        } else if (typeof item[property] === 'object') {
          this.selectAnyTranslations(item[property]);
        }
      }
    }
  }
}
