import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddPhoneNumbersDialogComponent } from './add-phone-numbers-dialog.component';


@Injectable({
    providedIn: 'root'
})
export class AddPhoneNumbersDialogService {

    constructor(private dialog: MatDialog) { }

    showDialog(phoneNumbers: string[]) {
        const dialogRef = this.dialog.open(AddPhoneNumbersDialogComponent, {
            width: '400px',
            data: { phoneNumbers }
        });
        return dialogRef.afterClosed();
    }
}