import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout.component';
import { SidebarModule } from 'src/app/sidebar/sidebar.module';
import { NavbarModule } from 'src/app/shared/navbar/navbar.module';
import { RouterModule } from '@angular/router';
import { FixedFormControlsModule } from 'src/app/shared/fixed-form-controls/fixed-form-controls.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, SidebarModule, NavbarModule, RouterModule, FixedFormControlsModule, TranslateModule.forRoot()],
    declarations: [AdminLayoutComponent],
    exports: [AdminLayoutComponent],
})
export class AdminModule {}
