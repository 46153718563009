import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizeFirstPipePipe } from './capitalize-first-pipe.pipe';
import { OrderByPipe } from './order-by.pipe';
import { OrderTranslationByPipe } from './order-translation-by.pipe';
import { PhonePipe } from './phone.pipe';
import { FilterPipe } from './filter.pipe';
import { YesNoPipePipe } from './yes-no.pipe';
import { ServiceTicketPriorityDescriptionPipe } from 'src/app/admin-pages/service-tickets/common/service-ticket-priority.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [CapitalizeFirstPipePipe, OrderByPipe, OrderTranslationByPipe, PhonePipe, FilterPipe, YesNoPipePipe, ServiceTicketPriorityDescriptionPipe],
    exports: [CapitalizeFirstPipePipe, OrderByPipe, OrderTranslationByPipe, FilterPipe, YesNoPipePipe, ServiceTicketPriorityDescriptionPipe],
})
export class PipesModule {}
