import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentManagementDto } from 'src/app/services/api.services';
import { AppointmentDispatchConfirmDialogComponent } from './appointment-dispatch-confirm-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class AppointmentDispatchConfirmDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(details: AppointmentManagementDto) {
        const dialogRef = this.dialogRef.open(AppointmentDispatchConfirmDialogComponent, {
            width: '1600px',
            data: details,
        });

        return dialogRef.afterClosed();
    }
}
