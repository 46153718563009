import { Injectable } from '@angular/core';
import { AppointmentDispatchType, DispatchedAppointmentCommand, DispatchedAppointmentsService, AppointmentDispatchedRecordDto } from 'src/app/services/api.services';
import { flatMap, map } from 'rxjs/operators';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { NotifyService } from 'src/app/services/notify.service';
import { Observable, empty, of } from 'rxjs';
import { AppointmentDispatchModule } from './appointment-dispatch.module';

export class DispatchUpdateResult {
    constructor(public isDispatched: boolean, public appointmentDispatchedRecord: AppointmentDispatchedRecordDto) {}
}

@Injectable({
    providedIn: AppointmentDispatchModule,
})
export class AppointmentDispatchService {
    constructor(private confirmDialogService: ConfirmDialogService, private notifyService: NotifyService, private dispatchedAppointmentsService: DispatchedAppointmentsService) {}

    handleDispatchAppointment(
        callNo: number,
        appointmentDispatchType: AppointmentDispatchType,
        isRemoteOrder: boolean,
        preDispatchAction: Observable<any>
    ): Observable<DispatchUpdateResult> {
        switch (appointmentDispatchType) {
            case AppointmentDispatchType.Dispatch:
                return this._sendDispatchCommand(
                    callNo,
                    AppointmentDispatchType.Dispatch,
                    isRemoteOrder,
                    preDispatchAction,
                    'APPOINTMENTMANAGEMENT.DISPATCHMESSAGES.CONFIRMDISPATCH',
                    true
                );
            case AppointmentDispatchType.RecallDispatched:
                return this._sendDispatchCommand(
                    callNo,
                    AppointmentDispatchType.RecallDispatched,
                    isRemoteOrder,
                    preDispatchAction,
                    'APPOINTMENTMANAGEMENT.DISPATCHMESSAGES.CONFIRMRECALL',
                    false
                );
            case AppointmentDispatchType.UpdateDispatched:
                return this._sendDispatchCommand(
                    callNo,
                    AppointmentDispatchType.UpdateDispatched,
                    isRemoteOrder,
                    preDispatchAction,
                    'APPOINTMENTMANAGEMENT.DISPATCHMESSAGES.CONFIRMUPDATE',
                    true
                );
        }
    }

    private _sendDispatchCommand(
        callNo: number,
        dispatchType: AppointmentDispatchType,
        isRemoteOrder: boolean,
        preDispatchAction: Observable<any>,
        confirmMessage: string,
        isDispatched: boolean
    ): Observable<DispatchUpdateResult> {
        const command = new DispatchedAppointmentCommand();
        command.callNo = callNo;
        command.dispatchType = dispatchType;
        command.isRemoteOrder = isRemoteOrder;

        return this.confirmDialogService.showDialogWithLoader(confirmMessage, preDispatchAction.pipe(flatMap(() => this.dispatchedAppointmentsService.send(command)))).pipe(
            map((confirmResult) => {
                if (confirmResult && confirmResult.success) {
                    this.notifyService.success('APPOINTMENTMANAGEMENT.DISPATCHMESSAGES.SUCCESS', true);
                    return new DispatchUpdateResult(isDispatched, confirmResult.result);
                }
            })
        );
    }
}
