import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, observable } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

export class ConfirmDialogResult {
    result: any;
    success: boolean;

    constructor(result: boolean, success: boolean) {
        this.result = result;
        this.success = success;
    }
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    @Input('title') title: string;
    @Input('yesButtonText') yesButtonText: string;
    @Input('noButtonText') noButtonText: string;
    @Input('message') message: string;
    @Input() displayLoadingUntilLoaded: boolean = false;
    @Input() showActions: boolean = true;

    @Input() observableLoad: Observable<any>;
    loading: boolean = false;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, private notifyService: NotifyService, private translateService: TranslateService) { }

    ngOnInit() { }

    callLoad() {
        this.loading = true;
        this.observableLoad.subscribe(
            (result) => {
                this.loading = false;
                return this.dialogRef.close(new ConfirmDialogResult(result, true));
            },
            (error) => {
                console.log(error);
                this.loading = false;
                this.notifyService.fail('OPERATIONFAILED', true);
            }
        );
    }
}
