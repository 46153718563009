import { NgModule } from '@angular/core';
import { CallbackNotesDialogComponent } from './callback-notes-dialog.component';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { CallbackNotesComponent } from '../callback-notes/callback-notes.component';

@NgModule({
    declarations: [CallbackNotesDialogComponent, CallbackNotesComponent],
    imports: [CommonFormsModule],
    exports: [CallbackNotesDialogComponent],
})
export class CallbackNotesDialogModule {}
