import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { CommonComponent } from 'src/app/models/formComponent';
import {
    ClientLeadsService,
    ClientLeadNoteDto,
    AddClientLeadNoteCommand,
    EditClientLeadNoteCommand,
    LeadNoteType,
    EditCallbackClientLeadNoteCommand,
    CallbackClientLeadNoteDto,
    AddCallbackClientLeadNoteCommand,
    CallbackService,
    UnbookableReason,
} from 'src/app/services/api.services';
import { NotifyService } from 'src/app/services/notify.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumListService } from 'src/app/services/enum-list.service';
import { DialogResult, DialogResultActions } from 'src/app/models/dialogResult';
import { NoteFormDialogSettings } from 'src/app/models/dialogs/noteFormDialogSettings';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { map, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-callback-note-form-dialog',
    templateUrl: './callback-note-form-dialog.component.html',
    styleUrls: ['./callback-note-form-dialog.component.scss'],
})
export class CallbackNoteFormDialogComponent extends CommonComponent implements OnInit, AfterViewInit {
    formGroup: FormGroup;
    model: CallbackClientLeadNoteDto;

    clientLeadNoteType = LeadNoteType;

    ratings = [1, 2, 3, 4, 5];

    isBeingSaved = false;

    @ViewChild('dt') dt: OwlDateTimeComponent<Moment>;

    showDescription = true;
    showRating = true;
    showCallWith = true;

    canSeeBadNumberReason: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: NoteFormDialogSettings<CallbackClientLeadNoteDto>,
        private dialogRef: MatDialogRef<CallbackNoteFormDialogComponent>,
        private clientLeadService: ClientLeadsService,
        public enumListService: EnumListService,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private formBuilder: FormBuilder,
        private router: Router,
        private callbackService: CallbackService,
        private permissionService: NgxPermissionsService
    ) {
        super();

        if (this.permissionService.getPermission('AssignEmployeeToClientLead')) {
            this.canSeeBadNumberReason = true;
        }
    }

    ngOnInit() {
        this._setupformControl();
    }

    private _createNewModel() {
        this.model = new CallbackClientLeadNoteDto();
    }

    ngAfterViewInit(): void {
        if (!this.model.id) {
            return;
        }
        setTimeout(() => {
            let dateToSet;
            switch (this.model.noteType) {
                case LeadNoteType.Appointment:
                    dateToSet = this.model.appointmentDateTime;
                    break;
                case LeadNoteType.FollowUp:
                    dateToSet = this.model.followUpDateTime;
                    break;
                default:
                    break;
            }
        });
    }

    changeRating(rating) {
        const callRatingControl = this.formGroup.get('callRating');
        if (rating === 1 && this.formGroup.get('callRating').value === 1) {
            callRatingControl.setValue(0);
        } else {
            callRatingControl.setValue(rating);
        }
    }

    onSubmitClick(): void {
        this._generateFromForm();

        if (this.formGroup.valid) {
            this.isBeingSaved = true;

            if (this.settings.isCallback && this.settings.isEdit) {
                this.callbackService
                    .editCallbackClientLeadNote(
                        EditCallbackClientLeadNoteCommand.fromJS({
                            callbackClientLeadNote: this.model,
                        })
                    )
                    .subscribe(
                        (entity) => {
                            this.dialogRef.close(new DialogResult(DialogResultActions.Edit, entity));
                        },
                        (error) => {
                            this._handleFailure(error);
                        }
                    );
            } else if (this.settings.isCallback && !this.settings.isEdit) {
                this.callbackService
                    .addCallbackClientLeadNote(
                        AddCallbackClientLeadNoteCommand.fromJS({
                            callbackClientLeadNote: this.model,
                        })
                    )
                    .subscribe(
                        (entity) => {
                            this.dialogRef.close(new DialogResult(DialogResultActions.Create, entity));
                        },
                        (error) => {
                            this._handleFailure(error);
                        }
                    );
            } else if (!this.settings.isCallback && this.settings.isEdit) {
                this.clientLeadService
                    .editClientLeadNote(
                        EditClientLeadNoteCommand.fromJS({
                            clientLeadNote: this.model,
                        })
                    )
                    .subscribe(
                        (entity) => {
                            this.dialogRef.close(new DialogResult(DialogResultActions.Edit, entity));
                        },
                        (error) => {
                            this._handleFailure(error);
                        }
                    );
            } else {
                this.clientLeadService
                    .addClientLeadNote(
                        AddClientLeadNoteCommand.fromJS({
                            clientLeadNote: this.model,
                        })
                    )
                    .subscribe(
                        (entity) => {
                            this.dialogRef.close(new DialogResult(DialogResultActions.Create, entity));
                        },
                        (error) => {
                            this._handleFailure(error);
                        }
                    );
            }
        } else {
            this.validateAllFormFields(this.formGroup);
        }
    }

    private _handleFailure(error: any) {
        console.log(error);
        this.isBeingSaved = false;
        this.notifyService.fail('OPERATIONFAILED', true);
    }

    onDeleteClick() {
        this.confirmDialogService
            .showDialog('Are you sure you want to delete this?', 'Delete')
            .pipe(
                flatMap((isConfirmed) => {
                    if (isConfirmed) {
                        return this.clientLeadService.deleteClientLeadNote(this.model.id).pipe(map((result) => true));
                    } else {
                        return of(false);
                    }
                })
            )
            .subscribe(
                (isDeleted) => {
                    if (!isDeleted) {
                        return;
                    }
                    return this.dialogRef.close(new DialogResult(DialogResultActions.Delete, this.model));
                },
                (error) => {
                    this.notifyService.fail('Failed to delete note', error);
                }
            );
    }

    private _generateFromForm() {
        const oldNoteType = this.model.noteType;
        if (this.settings.isCallback) {
            const callbackClientLeadNote = new CallbackClientLeadNoteDto();
            callbackClientLeadNote.init(this.formGroup.value);
            this.model = Object.assign(this.model, callbackClientLeadNote);
        } else {
            const clientLeadNote = new ClientLeadNoteDto();
            clientLeadNote.init(this.formGroup.value);
            this.model = Object.assign(this.model, clientLeadNote);
        }

        if (!this.settings.allowClientLeadIdSelection) {
            this.model.callbackClientLeadId = this.settings.entityId;
        }
        if (!this.settings.allowNoteTypeSelection) {
            this.model.noteType = this.settings.requiredNoteTypeSelection as LeadNoteType;
        }
        if (this.model.id) {
            this.model.noteType = oldNoteType;
        }
    }

    private _setupformControl() {
        if (!this.settings.isEdit) {
            // this.model = new ClientLeadNoteDto();
            this._createNewModel();
            this.model.noteType = LeadNoteType.Comment;
        } else {
            this.model = this.settings.model;
        }

        this.formGroup = this.formBuilder.group({
            id: [this.model.id],
            subject: [this.model.subject, [Validators.required]],
            description: [this.model.description],
            callee: [this.model.callee],
            propertyId: [this.model.propertyId],
            callRating: [this.model.callRating],
            noteType: [
                this.settings.allowNoteTypeSelection
                    ? this.model.noteType
                    : {
                          value: this.settings.isEdit ? this.model.noteType : this.settings.requiredNoteTypeSelection,
                          disabled: true,
                      },
                [Validators.required],
            ],
            callbackUnbookableReason: [this.model.callbackUnbookableReason],
            notInterestedReason: [this.model.notInterestedReason],
        });

        this.formGroup.get('noteType').valueChanges.subscribe((note) => {
            this._addSpecificFormControlsForType();
        });

        this._addSpecificFormControlsForType();
    }

    private _addSpecificFormControlsForType() {
        const type = this.formGroup.get('noteType').value;

        if (type === LeadNoteType.Unbookable) {
            this.formGroup.get('callbackUnbookableReason').setValidators([Validators.required]);
            this.formGroup.get('subject').setValidators(null);
        } else if (type === LeadNoteType.NotInterested) {
            this.formGroup.get('notInterestedReason').setValidators([Validators.required]);
            this.formGroup.get('subject').setValidators(null);
        } else {
            this.formGroup.get('subject').setValidators([Validators.required]);
            this.formGroup.get('callbackUnbookableReason').setValidators(null);
            this.formGroup.get('notInterestedReason').setValidators(null);
        }

        if (type === LeadNoteType.NotInterested || type === LeadNoteType.Unbookable) {
            this.showDescription = false;
            this.showRating = false;
            this.showCallWith = false;
            this.formGroup.get('description').disable();
            this.formGroup.get('callRating').disable();
        } else {
            this.showDescription = true;
            this.showRating = true;
            this.showCallWith = true;
            this.formGroup.get('description').enable();
            this.formGroup.get('callRating').enable();
        }
        if (type != LeadNoteType.FollowUp) {
            this.formGroup.removeControl('followUpDateTime');
        }

        switch (type) {
            case LeadNoteType.Comment:
                break;
            case LeadNoteType.FollowUp:
                this.formGroup.addControl('followUpDateTime', this.formBuilder.control([this.model.followUpDateTime, [Validators.required]]));
                break;
        }
    }
}
