import { NgModule } from '@angular/core';
import { CommonFormsModule } from 'src/common-forms/common-forms.module';
import { AddressDtoFormModule } from '../address-dto-form/address-dto-form.module';
import { PipesModule } from '../pipes/pipes.module';
import { InputsModule } from '../inputs/inputs.module';
import { CommonAppointmentFormComponent } from './common-appointment-form.component';
import { AlphaNumericModule } from '../directives/alphanumeric/alphanumeric.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [CommonAppointmentFormComponent],
    imports: [CommonFormsModule, AddressDtoFormModule, PipesModule, InputsModule, AlphaNumericModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    exports: [CommonAppointmentFormComponent],
})
export class CommonAppointmentFormModule {}
