import {
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatButton } from '@angular/material/button'
import { MatProgressSpinner } from '@angular/material/progress-spinner'

@Directive({
    selector: `button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading],
               button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading],
               button[mat-flat-button][loading]`,
})
export class MatButtonLoadingDirective implements OnChanges {
    private spinner: ComponentRef<MatProgressSpinner>;

    @Input()
    loading: boolean;

    @Input()
    disabled: boolean;

    @Input()
    spinnerColor: ThemePalette;

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.loading) {
            return;
        }

        if (changes.loading.currentValue) {
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-loading');
            this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
            this.createSpinner();
        } else if (!changes.loading.firstChange) {
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-loading');
            this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', this.disabled);
            this.destroySpinner();
        }
    }

    private createSpinner(): void {
        if (!this.spinner) {
            this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
            this.spinner.instance.color = this.spinnerColor;
            this.spinner.instance.diameter = 20;
            this.spinner.instance.mode = 'indeterminate';
            this.renderer.appendChild(
                this.elementRef.nativeElement,
                this.spinner.instance._elementRef.nativeElement
            );
        }
    }

    private destroySpinner(): void {
        if (this.spinner) {
            this.spinner.destroy();
            this.spinner = null;
        }
    }
}
