import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CommonComponent } from '../../models/formComponent';

@Component({
    selector: 'app-simple-entity-create-form-dialog',
    templateUrl: './simple-entity-create-form-dialog.component.html',
    styleUrls: ['./simple-entity-create-form-dialog.component.scss'],
})
export class SimpleEntityCreateFormDialogComponent extends CommonComponent implements OnInit {
    title: string;
    formGroup: FormGroup;
    formControlName: string;

    createEntity: (any) => Observable<any>;

    constructor(
        private dialogRef: MatDialogRef<SimpleEntityCreateFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            formGroup: FormGroup;
            title: string;
            formControlName: string;
            createEntity: (any) => Observable<any>;
        }
    ) {
        super();
        this.title = data.title;
        this.formControlName = data.formControlName;
        this.formGroup = data.formGroup;
        this.createEntity = data.createEntity;
    }

    ngOnInit() {}

    add() {
        return this.createEntity(this.formGroup.value).subscribe((result) => {
            this.dialogRef.close(result);
        });
    }
}
